import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../../App";
import { LoteSaida } from "../../../models/gado/gadoLoteSaida/gadoLoteSaida";

export const EncerrarLoteSaida = async (lote: LoteSaida): Promise<boolean> => {
  let success : boolean = false;

  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }
  const valKilo = Number(lote.gcolotesaida_valKilo!.toString().replace(/[^0-9]+/g, "")) / 100;
  const comprador = lote.gcolotesaida_comprador;
  const datasaida = lote.gcolotesaida_datasaida;
  const rendimento = Number(lote.gcolotesaida_rendimento!.toString().replace(/[^0-9]+/g, "")) / 100;
  const frete = Number(lote.gcolotesaida_frete!.toString().replace(/[^0-9]+/g, "")) / 100;

  await db.open().then(async () => {
    let sqlcmd: string = `UPDATE LoteSaida SET is_finished = 1, 
    gcolotesaida_valKilo = '${valKilo}', 
    gcolotesaida_comprador = '${comprador}', 
    gcolotesaida_datasaida = '${datasaida}' , 
    gcolotesaida_rendimento = '${rendimento}' , 
    gcolotesaida_frete = '${frete}' 
    WHERE id = '${lote.id}';`;
    await db.execute(sqlcmd, false).then(async (object) => {
      if( lote.id === object.changes?.lastId!)
      {
        success = true;
      }
      db.close();
    });
  });
  return success;
};
