import { createStore } from "redux";

const INITIAL_STATE = {
  hideBg: false,
  showError: false,
  errorMessage: "",
  showAlert: false,
  alertMessage: "",
  showSuccess: false,
  successMessage: "",
  showLoading: false,
  modalManejo: false,
  bastaoConnected: false,
  bastaoConnectedAddress: "",
  bastaoConnectedName: "",
  bastaoValue: "",
  balancaConnected: false,
  balancaConnectedAddress: "",
  balancaConnectedName: "",
  balancaValue: 0,
  balancaStable: "",
  balancaSignal: "",
  data: [],
};

const courses = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_HIDEBG":
      return { ...state, hideBg: action.hideBg };
    case "SET_SHOWERRORMESSAGE":
      return { ...state, showError: action.showError };
    case "SET_ERRORMESSAGE":
      return { ...state, errorMessage: action.errorMessage };
    case "SET_SHOWALERTMESSAGE":
      return { ...state, showAlert: action.showAlert };
    case "SET_ALERTMESSAGE":
      return { ...state, alertMessage: action.alertMessage };
    case "SET_SHOWSUCCESSMESSAGE":
      return { ...state, showSuccess: action.showSuccess };
    case "SET_SUCCESSMESSAGE":
      return { ...state, successMessage: action.successMessage };
    case "SET_LOADING":
      return { ...state, showLoading: action.showLoading };



    //#################################
    //########### MODAL APP ###########
    //#################################
    case "SET_MODAL_MANEJO":
      return { ...state, modalManejo: action.modalManejo };



    //#################################
    //########### BLUETOOTH ###########
    //#################################
    case "SET_BASTAO_STATE":
      return { ...state, bastaoConnected: action.bastaoConnected };
    case "SET_BASTAO_ADDRESS":
      return {...state,bastaoConnectedAddress: action.bastaoConnectedAddress };
    case "SET_BASTAO_NAME":
      return { ...state, bastaoConnectedName: action.bastaoConnectedName };
    case "SET_BASTAO_VALUE":
      return { ...state, bastaoValue: action.bastaoValue };
    case "SET_BALANCA_STATE":
      return { ...state, balancaConnected: action.balancaConnected };
    case "SET_BALANCA_ADDRESS":
      return {...state,balancaConnectedAddress: action.balancaConnectedAddress };
    case "SET_BALANCA_NAME":
      return { ...state, balancaConnectedName: action.balancaConnectedName };
    case "SET_BALANCA_VALUE":
      return { ...state, balancaValue: action.balancaValue };
    case "SET_BALANCA_STABLE":
      return { ...state, balancaStable: action.balancaStable };
    case "SET_BALANCA_SIGNAL":
      return { ...state, balancaSignal: action.balancaSignal };



      case "SET_SYNC_MORTE_NEED":
        return { ...state, syncMorteNeed: action.syncMorteNeed };


    default:
      return state;
  }
};

export default createStore(courses);
