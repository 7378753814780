import { IonCard, IonCardHeader, IonCardTitle, IonBadge, IonIcon, IonCardContent, IonGrid, IonRow, IonCol, IonContent, IonModal, IonText, IonButton } from "@ionic/react";
import { alertSharp, addSharp, medkitSharp } from "ionicons/icons";
import moment from "moment";
import 'moment/locale/pt-br'
import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../App";
import { CadGadConfinamento, CadGadConfinamentoManejoSanitario } from "../../models/databaseModels";

interface CardAnimalProps {
  index?: any;
  animalExato?: boolean;
  item: CadGadConfinamento;
  loteSaida?: boolean;
  loteSaidaHandle?: any;
}

const CardAnimal: React.FC<CardAnimalProps> = ({ index, animalExato, item, loteSaida, loteSaidaHandle }) => {
  const dispatch = useDispatch();
  const balancaValue = useSelector((state: RootStateOrAny) => state.balancaValue);
  const [listProcedimentos, setListProcedimentos] = useState<CadGadConfinamentoManejoSanitario[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalProcedimentos, setShowModalProcedimentos] = useState<boolean>(false);
  const [modalData, setModalData] = useState<CadGadConfinamento>();

  const loadModal = (value : CadGadConfinamento) => {
    setShowModal(true);
    setModalData(value);
  };

  const loadModalProcedimentos = async (value) => {
    setListProcedimentos([]);
    let db: SQLiteDBConnection;
    if ((await sqlite.isConnection("trackBov")).result) {
      db = await sqlite.retrieveConnection("trackBov");
    } else {
      db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
    }
    dispatch({ type: "SET_LOADING", showLoading: true });
    await db
      .query("SELECT * FROM CadGadConfinamentoManejoSanitario WHERE gco_id=" + value + " order by id desc;")
      .then((response) => {
        const responseSelect: CadGadConfinamentoManejoSanitario[] = response.values ? response.values : [];
        setListProcedimentos([...responseSelect]);
        dispatch({ type: "SET_LOADING", showLoading: false });
      })
      .then(() => {
        db.close();
      });

    setShowModalProcedimentos(true);
  };
  return (
    <>
      <IonCard key={index}>
        <IonCardHeader>
          <IonCardTitle className="display_flex card_animal_header_text">
            {!animalExato && (
              <IonBadge className="badge_warning_match" color="warning">
                <IonIcon className="icon_font_16" src={alertSharp} />{" "}
              </IonBadge>
            )}
            {item.gco_brinco_bastao}
          </IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <IonGrid>
            <IonRow className="card_animal_regular_text">{item.faz_nome}</IonRow>
            <IonRow className="card_animal_regular_text">{item.con_nome}</IonRow>
            <IonRow className="ion-align-items-center card_animal_regular_text">
              <IonCol size="6">{item.gco_marca}</IonCol>
              <IonCol size="6">
                Dias:
                {moment().diff(moment(item.gco_data_ent, "YYYY-MM-DD"), "days")}
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol className="ion-text-right" size="2">
                <IonIcon
                  onClick={() => {
                    loadModal(item);
                  }}
                  className="iconCardBig"
                  icon={addSharp}
                />
              </IonCol>
              <IonCol className="ion-text-right" size="2">
                <IonIcon
                  onClick={() => {
                    loadModalProcedimentos(item.gco_id);
                  }}
                  className="iconCardBig"
                  icon={medkitSharp}
                />
              </IonCol>
            </IonRow>
            {loteSaida && (
              <IonRow className="ion-justify-content-center">
                <IonButton color="warning" onClick={loteSaidaHandle}>
                  Adicionar ao Lote de Saída
                </IonButton>
              </IonRow>
            )}
          </IonGrid>
        </IonCardContent>
      </IonCard>
      <IonModal
        isOpen={showModal}
        cssClass="bluetooth-modal"
        onDidDismiss={() => {
          setShowModal(false);
          setModalData({});
        }}
      >
        <IonContent>
          <IonGrid className="modal_grid_95">
            <IonRow className="ion-justify-content-center ion-align-items-center card_animal_regular_text">
              <IonText className="ion-text-center">
                <p>Informações Adicionais</p>
              </IonText>
            </IonRow>
            <IonText className="ion-text-left">
              {modalData?.gco_marca && (
                <IonRow>
                  Marca:<b className="bold_margin_left">{modalData?.gco_marca}</b>
                </IonRow>
              )}
              {modalData?.gco_data_ent && (
                <IonRow>
                  Entrada: <b className="bold_margin_left">{moment(new Date(modalData?.gco_data_ent)).locale("pt-br").format("ll")}</b>
                </IonRow>
              )}
              {modalData?.gcolote_vendedor && (
                <IonRow>
                  Origem: <b className="bold_margin_left">{modalData?.gcolote_vendedor}</b>
                </IonRow>
              )}
              {modalData?.gco_raca && (
                <IonRow>
                  Raça: <b className="bold_margin_left">{modalData?.gco_raca}</b>
                </IonRow>
              )}
              {modalData?.gco_tipo && (
                <IonRow>
                  Categoria: <b className="bold_margin_left">{modalData?.gco_tipo}</b>
                </IonRow>
              )}

              {modalData?.gco_peso_balanca && (
                <IonRow>
                  Peso de Entrada: <b className="bold_margin_left">{modalData?.gco_peso_balanca} Kg</b>
                </IonRow>
              )}

              <IonRow>
                Peso Balança: <b className="bold_margin_left">{balancaValue} Kg</b>
              </IonRow>

              <IonRow>
                Ganho de Peso: <b className="bold_margin_left">{balancaValue - modalData?.gco_peso_balanca! > 0 ? (balancaValue - modalData?.gco_peso_balanca! +  "Kg") : "-"}</b>
              </IonRow>
            </IonText>
          </IonGrid>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showModalProcedimentos}
        cssClass="bluetooth-modal"
        onDidDismiss={() => {
          setShowModalProcedimentos(false);
        }}
      >
        <IonContent>
          <IonGrid className="modal_grid_95">
            <IonRow className="ion-justify-content-center  card_animal_regular_text">
              <IonText className="ion-text-center">
                <p>Manejos Sanitários</p>
              </IonText>
            </IonRow>

            {listProcedimentos.length === 0 ? (
              <small>Nenhum procedimento realizado neste animal</small>
            ) : (
              <>
                {listProcedimentos.map((item, index) => {
                  return (
                    <IonCard key={index} className="modal_grid_padding_sm">
                      <IonRow>
                        Data: <IonText className="card_animal_bold bold_margin_left line_margin_bottom_sm">{moment(new Date(item.pront_data_procedimento)).locale("pt-br").format("ll")}</IonText>
                      </IonRow>
                      <IonRow>
                        Profissional: <IonText className="card_animal_bold bold_margin_left line_margin_bottom_sm">{item.user_fullname}</IonText>
                      </IonRow>
                      <IonRow>
                        Procedimento: <IonText className="card_animal_bold bold_margin_left line_margin_bottom_sm">{item.pront_texto}</IonText>
                      </IonRow>
                    </IonCard>
                  );
                })}
              </>
            )}
          </IonGrid>
        </IonContent>
      </IonModal>
    </>
  );
};

export default CardAnimal;
