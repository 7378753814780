import { IonPage } from "@ionic/react";

const Register: React.FC = (routing: any) => {
  return (
    <IonPage>
      <h1>Register</h1>
    </IonPage>
  );
};

export default Register;
