    export const createTablesNoEncryption: string = `
    
        CREATE TABLE IF NOT EXISTS ControleManejosApp (
        id INTEGER PRIMARY KEY NOT NULL,
        brinco TEXT, 
        peso TEXT, 
        data TEXT
        );
    
        CREATE TABLE IF NOT EXISTS CadGadConfinamento (
        id INTEGER PRIMARY KEY NOT NULL,
        gco_id INTEGER,
        con_nome TEXT, 
        con_id_fk INTEGER, 
        faz_nome TEXT, 
        gco_brinco_bastao TEXT, 
        gco_marca TEXT,
        gco_data_ent TEXT,
        gcolote_vendedor TEXT,
        gco_peso_balanca INTEGER,
        qtd_dias_entrada INTEGER,
        gco_con_id_fk INTEGER,
        gco_raca TEXT,
        gco_tipo TEXT,
        gco_tipo_id INTEGER,
        macho INTEGER
        );

        CREATE TABLE IF NOT EXISTS CadGadConfinamentoManejoSanitario (
        id INTEGER PRIMARY KEY NOT NULL,
        gco_id INTEGER,
        pront_data_procedimento TEXT,
        pront_data_registro TEXT,
        pront_id INTEGER,
        pront_texto TEXT,
        user_fullname TEXT,
        user_user TEXT
        );
    
        CREATE TABLE IF NOT EXISTS InformarMorte (
        id INTEGER PRIMARY KEY NOT NULL,
        is_synced INTEGER,
        server_id INTEGER,
        gco_brinco_bastao TEXT, 
        gco_brinco_manual TEXT, 
        motivo TEXT
        );
    
        CREATE TABLE IF NOT EXISTS InformarMorteImagens (
        id INTEGER PRIMARY KEY NOT NULL,
        registro_id INTEGER,
        is_synced INTEGER,
        server_id INTEGER,
        nome TEXT, 
        conteudo TEXT,
        FOREIGN KEY (registro_id) REFERENCES InformarMorte(id)
        );
    
    
        CREATE TABLE IF NOT EXISTS ManejoSanitario (
        id INTEGER PRIMARY KEY NOT NULL,
        is_synced INTEGER,
        server_id INTEGER,
        gco_brinco_bastao TEXT, 
        gco_brinco_manual TEXT, 
        prontuario_texto TEXT, 
        pront_data_procedimento TEXT
        );
    
    
        



        CREATE TABLE IF NOT EXISTS LoteSaida (
        id INTEGER PRIMARY KEY NOT NULL,
        is_finished INTEGER,
        is_synced INTEGER,
        server_id INTEGER,
        gcolotesaida_valKilo REAL, 
        gcolotesaida_comprador TEXT,
        gcolotesaida_datasaida TEXT,
        gcolotesaida_rendimento REAL, 
        gcolotesaida_faz_id_fk INTEGER,
        gcolotesaida_qtdcabeca INTEGER,
        gcolotesaida_frete REAL
        );


        CREATE TABLE IF NOT EXISTS LoteSaidaAnimais (
        id INTEGER PRIMARY KEY NOT NULL,
        lote_id INTEGER,
        gco_categoria INTEGER, 
        gco_brinco_bastao TEXT, 
        gco_brinco_manual TEXT, 
        gco_peso_balanca REAL, 
        gco_peso_manual REAL,
        gco_con_id_fk INTEGER, 
        gco_raca TEXT,
        gco_obs TEXT,
        gco_marca TEXT,
        con_nome TEXT,
        faz_nome TEXT,
        FOREIGN KEY (lote_id) REFERENCES LoteSaida(id)
        );
    
    
    
    
    
    
    
    
    
    
    
    
        CREATE TABLE IF NOT EXISTS LoteEntrada (
        id INTEGER PRIMARY KEY NOT NULL,
        is_finished INTEGER,
        is_synced INTEGER,
        server_id INTEGER,
        gcolote_picareta TEXT,
        gcolote_vendedor TEXT,
        gcolote_qtd_cabecas INTEGER,
        gcolote_peso_gado REAL,
        gcolote_categoria INTEGER,
        gcolote_raca INTEGER,
        gcolote_frete REAL, 
        gcolote_valor_peso REAL, 
        gcolote_valor_peso_up REAL, 
        gcolote_comissao REAL, 
        gcolote_data_compra TEXT, 
        gcolote_origem TEXT,
        gcolote_data_chegada TEXT,
        gcolote_con_id_fk INTEGER,
        gcolote_faz_id_fk INTEGER,
        gcolote_tipo_compra INTEGER,
        gcolote_custos_extra REAL,
        gcolote_gta TEXT,
        gcolote_nota TEXT
        );
    
        CREATE TABLE IF NOT EXISTS LoteEntradaAnimais (
        id INTEGER PRIMARY KEY NOT NULL,
        server_id INTEGER,
        lote_id INTEGER,
        gco_marca TEXT,
        gco_raca TEXT,
        gco_tipo TEXT, 
        gco_brinco_bastao TEXT, 
        gco_brinco_manual TEXT, 
        gco_peso_balanca REAL, 
        gco_peso_manual REAL,
        gco_obs TEXT,
        gco_con_id_fk INTEGER,
        FOREIGN KEY (lote_id) REFERENCES LoteEntrada(id)
        );
        
        CREATE TABLE IF NOT EXISTS CustoConfinamentos (
        id INTEGER PRIMARY KEY NOT NULL,
        is_synced INTEGER,
        server_id INTEGER,
        con_id INTEGER,
        data TEXT,
        prod_id_fk INTEGER, 
        quantidade INTEGER, 
        leitura_cocho REAL
        );
    `;
    