import { isPlatform } from "@ionic/react";

export const IsMobile = (): boolean => {
    return !(
        isPlatform('mobileweb') ||
        isPlatform('pwa') ||
        isPlatform('desktop')
    );
};

export const capitalize = (s: string) => {
    if (typeof s !== 'string') return '';

    return s.charAt(0).toUpperCase() + s.slice(1);
};

export function addFieldRequiredError(info: any, errors: any, field: string) {
    if (!info[capitalize(field)]) {
        errors[field] = {
            message: 'Campo Obrigatório',
        };
    }
}

export function hasProperties(obj: any) {
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return true;
        }
    }
    return false;
}