import { capSQLiteSet, SQLiteDBConnection } from "@capacitor-community/sqlite";
import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import { setStorageValue } from "../../StorageServices";
import { sqlite } from "../../../App";
import { ApiConnection } from "../../../settings/constants/apiConnection";
import { DbConnection } from "../../databaseServices/DbConnection";
import { FormConsumoConfinamento } from "../../../pages/gado/gadoConsumoConfinamento/novoRegistro/NovoRegistroConsumoConfinamento";

export async function syncConsumo(): Promise<boolean> {
  let success: boolean = false;
  try {
    let db = await DbConnection();

    await db.open().then(async () => {
      let res: any = await db.query("SELECT * FROM CustoConfinamentos WHERE is_synced = 0");

      let responseLotes: FormConsumoConfinamento[] = res.values ? res.values : [];

      const uri = ApiConnection.baseUrl + ApiConnection.syncCusConfinamento;

      await axios.post(uri, responseLotes).then(async (response) => {
        if (response.status === 200) {
          console.log(response);
          const data: Array<capSQLiteSet> = [];
          const responseData = response.data.retorno.map(async (element) => {
            if (element.sucesso) {
              const loteCliente = element.id;
              const loteServidor = element.cus_con_id;
              data.push({
                statement: "UPDATE CustoConfinamentos SET is_synced = 1, server_id = ? WHERE id = ?;",
                values: [loteServidor, loteCliente],
              });
            }
          });

          const updateDbData = await Promise.all(responseData).then(() => {
            return data;
          });

          if (updateDbData.length > 0) {
            await db.executeSet(updateDbData);
            await db.close();
            await sqlite.closeConnection("trackBov");
          }
        } else {
          success = false;
        }
      });
    });
    return success;
  } catch (err) {
    success = false;
    console.log(err)
    return success;
  }
}
