import { capSQLiteSet, SQLiteDBConnection } from "@capacitor-community/sqlite";
import axios from "axios";
import { sqlite } from "../../../App";
import { LoteSaida, LoteSaidaAnimais } from "../../../models/gado/gadoLoteSaida/gadoLoteSaida";
import { ApiConnection } from "../../../settings/constants/apiConnection";

export const SyncLoteSaida = async (): Promise<any> => {
  let responseLotes: LoteSaida[] = [];
  try {
    let db: SQLiteDBConnection;
    if ((await sqlite.isConnection("trackBov")).result) {
      db = await sqlite.retrieveConnection("trackBov");
    } else {
      db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
    }
    await db.open();
    
    let res: any = await db.query("SELECT * FROM LoteSaida WHERE is_synced = 0 and is_finished = 1");
    responseLotes = res.values ? res.values : [];

    const requests = responseLotes.map(async (element) => {
      let resAnimais: any = await db.query(`SELECT * FROM LoteSaidaAnimais where lote_id = ${element.id};`);
      let responseAnimais: LoteSaidaAnimais[] = resAnimais.values ? resAnimais.values : [];
      element.animaisLote = [];
      element.animaisLote.push(...responseAnimais);
    });

    const mapRes = await Promise.all(requests).then(() => {
      return responseLotes;
    });

    if (mapRes.length > 0) {
      const uri = ApiConnection.baseUrl + ApiConnection.syncLoteSaida;

      await axios.post(uri, responseLotes).then(async (response) => {
        if (response.status === 200) {
          const data: Array<capSQLiteSet> = [];
          const responseData = response.data.retorno.map(async (element) => {
            if (element.LoteCriado) {
              const loteCliente = element.loteCliente;
              const loteServidor = element.loteId;
              data.push({
                statement: "UPDATE LoteSaida SET is_synced = 1, server_id = ? WHERE id = ?;",
                values: [loteServidor, loteCliente],
              });
            }
          });

          const updateDbData = await Promise.all(responseData).then(() => {
            return data;
          });
          if (updateDbData.length > 0) {
            await db.executeSet(updateDbData);
            await db.close();
            await sqlite.closeConnection("trackBov");
          }
        }
      });
    } else {
      await db.close();
      await sqlite.closeConnection("trackBov");
    }
  } catch (err) {
    //console.log(`Error: ${err}`);
  }
};
