import { Network } from "@capacitor/network";
import { IonBadge, IonButton, IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonModal, IonRow, IonText, useIonAlert, useIonViewDidEnter } from "@ionic/react";
import { chevronBackOutline, syncSharp } from "ionicons/icons";
import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import LoggedPage, { RightButtonsLoggedPage } from "../../../../components/LoggedPage/LoggedPage";
import { CadGadConfinamento } from "../../../../models/databaseModels";
import { LoteSaida, LoteSaidaAnimais } from "../../../../models/gado/gadoLoteSaida/gadoLoteSaida";
import { AdicionarAnimalLoteSaida } from "../../../../services/databaseServices/loteSaida/AdicionarAnimalLoteSaida";
import { CarregarAnimaisLoteSaida } from "../../../../services/databaseServices/loteSaida/CarregarAnimaisLoteSaida";
import { RemoverAnimalLoteSaida } from "../../../../services/databaseServices/loteSaida/RemoverAnimalLoteSaida";
import { VerificarAnimalLoteSaída } from "../../../../services/databaseServices/loteSaida/VerificarAnimalLoteSaida";
import { getStorageValue } from "../../../../services/StorageServices";
import { syncAnimais } from "../../../../services/syncServices/syncAnimais/SyncAnimais";
import "./PreencherLoteSaida.css";
import { SearchAnimal } from "../../../../components/searchAnimal/SearchAnimal";
import { useHistory } from "react-router";
import AnimaisLoteSaida from "../../../../components/loteSaida/AnimaisLoteSaida";
import { CarregarLoteSaida } from "../../../../services/databaseServices/loteSaida/CarregarLotesSaida";

const PreencherLoteSaida: React.FC = (routing: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [present] = useIonAlert();
  const {
    match: {
      params: { id },
    },
  } = routing;
  const balancaValue = useSelector((state: RootStateOrAny) => state.balancaValue);
  const balancaStable = useSelector((state: RootStateOrAny) => state.balancaStable);
  const [btnAnimated, setBtnAnimated] = useState<boolean>(false);
  const [lastAnimalSyncData, setLastAnimalSyncData] = useState<string | null>("");
  const [listSaida, setListSaida] = useState<LoteSaidaAnimais[]>([]);
  const [saida, setSaida] = useState<LoteSaida>({});
  const [pesoManual, setPesoManual] = useState<number>(0);
  const [loadingAnimals, setLoadingAnimals] = useState<boolean>(true);
  const [pesoManualErro, setPesoManualErro] = useState<boolean>(false);
  const [showPesoModal, setShowPesoModal] = useState<boolean>(false);
  const [animalAux, setAnimalAux] = useState<CadGadConfinamento>({});
  const [resetForm, setResetForm] = useState(0);

  useIonViewDidEnter(async () => {
    dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: "" });
    await getStorageValue("lastAnimalSync").then(async (data) => {
      setLastAnimalSyncData(data);
    });
    await CarregarLoteSaida(id).then((data) => {
      setSaida(data[0])
    })
    await CarregarAnimaisLoteSaida(id)
      .then((data) => {
        setListSaida(data);
        setLoadingAnimals(false);
      })
      .catch((error) => {
        dispatch({
          type: "SET_ERRORMESSAGE",
          errorMessage: error.message,
        });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
        dispatch({ type: "SET_LOADING", showLoading: false });
      });
  }, []);

  const refreshAnimalDatabase = async (e) => {
    await Network.getStatus().then(async (network) => {
      if (network.connected) {
        setBtnAnimated(true);
        dispatch({ type: "SET_LOADING", showLoading: true });
        await syncAnimais().then(async (response) => {
          await getStorageValue("lastAnimalSync").then(async (data) => {
            setLastAnimalSyncData(data);
          });
          setBtnAnimated(false);
          dispatch({ type: "SET_LOADING", showLoading: false });
        });
      } else {
        dispatch({
          type: "SET_ERRORMESSAGE",
          errorMessage: "Não foi possível sincronizar os animais. Você não possui conexão com a internet. Tente Novamente",
        });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
      }
    });
  };

  const menuRightBtns: RightButtonsLoggedPage[] = [
    {
      text: "Sincronizar Animais",
      rightBtnIcon: syncSharp,
      click: refreshAnimalDatabase,
      rightBtnIconAnimated: btnAnimated,
    },
    {
      text: "Voltar",
      rightBtnIcon: chevronBackOutline,
      click: () => {
        history.replace("/gadoSaida");
      },
      rightBtnIconAnimated: btnAnimated,
    },
  ];

  const adicionarLoteSaida = async (item: CadGadConfinamento) => {
    await VerificarAnimalLoteSaída(id, item.gco_brinco_bastao!).then(async (response) => {
      if (response) {
        present({
          header: "Erro",
          message: "Já existe um animal com este número de brinco neste lote",
          buttons: ["OK"],
        });
      } else {
        if (parseInt(balancaValue) === 0 && pesoManual === 0) {
          setShowPesoModal(true);
          setAnimalAux(item);
        } else {
          const pesoAnimal = parseInt(balancaValue) > 0 ? parseFloat(balancaValue) : pesoManual;
          setResetForm((prev) => prev + 1);
          const newRecord: LoteSaidaAnimais = {
            lote_id: id,
            gco_brinco_bastao: item.gco_brinco_bastao,
            gco_brinco_manual: item.gco_brinco_bastao,
            gco_peso_balanca: pesoAnimal,
            gco_peso_manual: pesoAnimal,
            gco_con_id_fk: item.con_id_fk,
            gco_marca: item.gco_marca,
            con_nome: item.con_nome,
            faz_nome: item.faz_nome,
            gco_raca: item.gco_raca,
            gco_categoria: item.gco_tipo_id
          };

          await AdicionarAnimalLoteSaida(newRecord)
            .then((response) => {
              newRecord.id = response;
              setListSaida([...listSaida, newRecord]);
              setPesoManual(0);
            })
            .catch((error) => {
              dispatch({
                type: "SET_ERRORMESSAGE",
                errorMessage: error.message,
              });
              dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
              dispatch({ type: "SET_LOADING", showLoading: false });
            });
          // present({
          //   header: "Atenção",
          //   message: "Tem certeza que deseja adicionar o animal: " + item.gco_brinco_bastao + " ao lote de saída?",
          //   buttons: [
          //     "Cancelar",
          //     {
          //       text: "Adicionar",
          //       handler: async (d) => {

          //       },
          //     },
          //   ],
          // });
        }
      }
    });
  };

  const removerLoteSaida = (item: LoteSaidaAnimais) => {
    present({
      header: "Atenção",
      message: "Tem certeza que deseja remover o animal: " + item.gco_brinco_bastao + " do lote de saída?",
      buttons: [
        "Cancelar",
        {
          text: "Remover",
          handler: async (d) => {
            await RemoverAnimalLoteSaida(item)
              .then(() => {
                setListSaida(listSaida.filter((animal) => animal.id !== item.id));
              })
              .catch((error) => {
                dispatch({
                  type: "SET_ERRORMESSAGE",
                  errorMessage: error.message,
                });
                dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
                dispatch({ type: "SET_LOADING", showLoading: false });
              });
          },
        },
      ],
    });
  };

  return (
    <LoggedPage title="Preencher Lote de Saída" buttons={menuRightBtns}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <small className="extra-small-text">Última Sincronização dos Animais: {lastAnimalSyncData}</small>
          </IonCol>
        </IonRow>
        <IonRow className="row-animais-lote-saida">
          <IonGrid>
            <IonRow>
              <IonCol size="12" className="ion-text-center">
                <IonText className="font-20-b">Animais Lote: {saida?.gcolotesaida_comprador}</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
               <AnimaisLoteSaida
                  remove={true}
                  removeHandle={(item) => {
                    removerLoteSaida(item);
                  }}
                  listSaida={listSaida}
                  loading={loadingAnimals}
                />
            </IonRow>
            <IonRow className="font-20-b">Resumo</IonRow>
            <IonRow>
              <IonCol size="3" className="m0p0">
                <IonGrid>
                  <IonRow className="ion-text-center">
                    <IonCol>Qtd</IonCol>
                  </IonRow>
                  <IonRow className="ion-text-center">
                    <IonCol> {listSaida.length}</IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol size="5" className="m0p0">
                <IonGrid>
                  <IonRow className="ion-text-center">
                    <IonCol>Peso</IonCol>
                  </IonRow>
                  <IonRow className="ion-text-center">
                    <IonCol>
                      {listSaida
                        .map((item) => item.gco_peso_balanca)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0)
                        .toFixed(2)}
                      Kg
                    </IonCol>
                  </IonRow>
                </IonGrid>{" "}
              </IonCol>
              <IonCol size="4" className="m0p0">
                <IonGrid>
                  <IonRow className="ion-text-center">
                    <IonCol>Média</IonCol>
                  </IonRow>
                  <IonRow className="ion-text-center">
                    <IonCol>
                      {(listSaida.map((item) => item.gco_peso_balanca).reduce((prev, curr) => Number(prev) + Number(curr), 0) / (listSaida.length > 0 ? listSaida.length : 1)).toFixed(2)} Kg
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonRow>
        <IonRow className="ion-justify-content-start text-row extra_margin_row">
          Peso Balança: <b className="ml-10 mr-10">{balancaValue}</b> KG
          {balancaStable === "E" ? (
            <IonBadge className="weightBadge" color="success">
              E
            </IonBadge>
          ) : (
            <IonBadge className="weightBadge" color="warning">
              !
            </IonBadge>
          )}
        </IonRow>

        <SearchAnimal
          loteSaida={true}
          loteSaidaHandle={(item) => {
            adicionarLoteSaida(item);
          }}
          resetForm={resetForm}
        />

        <IonRow className="ion-justify-content-center">
          <IonButton
            color="secondary"
            onClick={() => {
              history.replace("/gadoSaida");
            }}
          >
            Finalizar Edição
          </IonButton>
        </IonRow>
      </IonGrid>

      <IonModal
        isOpen={showPesoModal}
        cssClass="bluetooth-modal"
        onDidDismiss={() => {
          setShowPesoModal(false);
          setPesoManual(0);
        }}
      >
        <IonContent>
          <IonGrid className="modal_grid_95">
            <IonRow className="ion-justify-content-center ion-align-items-center card_animal_regular_text">
              <IonText className="ion-text-center">
                <p>O peso da balança está zerado. Entre com o peso do animal:</p>
              </IonText>
            </IonRow>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="stacked">Peso</IonLabel>
                    <IonInput type="number" onIonChange={(e) => setPesoManual(parseInt(e.detail.value!, 10))} />
                  </IonItem>
                </IonCol>
              </IonRow>
              {pesoManualErro && (
                <IonRow>
                  <IonCol>
                    <IonText color="danger">O peso deve ser um número</IonText>
                  </IonCol>
                </IonRow>
              )}
              <IonRow>
                <IonCol>
                  <IonButton
                    expand="full"
                    onClick={() => {
                      if (!isNaN(+pesoManual)) {
                        setShowPesoModal(false);
                        adicionarLoteSaida(animalAux);
                        setPesoManualErro(false);
                      } else {
                        setPesoManualErro(true);
                      }
                    }}
                  >
                    Inlcuir Peso
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonGrid>
        </IonContent>
      </IonModal>
    </LoggedPage>
  );
};

export default PreencherLoteSaida;
