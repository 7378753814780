import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../App";

export const DbConnection = async (): Promise<SQLiteDBConnection> => {
  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }
  return db;
};
