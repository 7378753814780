import { capSQLiteSet } from "@capacitor-community/sqlite";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../../App";
import { LoteSaidaAnimais } from "../../../models/gado/gadoLoteSaida/gadoLoteSaida";
import { AtualizarCabecasLoteSaida } from "./AtualizarCabecasLoteSaida";

export const RemoverAnimalLoteSaida = async (animal: LoteSaidaAnimais): Promise<any> => {
  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }
  await db.open().then(async () => {
    const data: Array<capSQLiteSet> = [
      {
        statement: "DELETE FROM LoteSaidaAnimais where id= ?;",
        values: [animal.id],
      },
    ];

    // Add Data to DataBASE
    await db.executeSet(data).then((object) => {
      db.close();
    });
  });

  await AtualizarCabecasLoteSaida(animal.lote_id!)
};
