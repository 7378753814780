import { Network } from "@capacitor/network";
import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonText,
  useIonViewWillEnter,
} from "@ionic/react";
import { addSharp, informationCircleSharp, listSharp, syncSharp } from "ionicons/icons";
import moment from "moment";
import "moment/locale/pt-br";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { AppDispatch } from "../../../App";
import Form, { InputField } from "../../../components/Forms/Form";
import LoggedPage, { RightButtonsLoggedPage } from "../../../components/LoggedPage/LoggedPage";
import { ListCategorias } from "../../../models/app/listaCategorias";
import { LoteSaida, LoteSaidaAnimais } from "../../../models/gado/gadoLoteSaida/gadoLoteSaida";
import { CarregarAnimaisLoteSaida } from "../../../services/databaseServices/loteSaida/CarregarAnimaisLoteSaida";
import { CarregarLoteSaida, CarregarLotesSaida, CarregarMaisLotesSaida } from "../../../services/databaseServices/loteSaida/CarregarLotesSaida";
import { EncerrarLoteSaida } from "../../../services/databaseServices/loteSaida/EncerrarLoteSaida";
import { getStorageValue } from "../../../services/StorageServices";
import { syncAnimais } from "../../../services/syncServices/syncAnimais/SyncAnimais";
import { SyncLoteSaida } from "../../../services/syncServices/syncLoteSaida/SyncLoteSaida";
import { addFieldRequiredError, hasProperties } from "../../../utils";

const GadoLoteSaida: React.FC = (routing: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [btnAnimated, setBtnAnimated] = useState<boolean>(false);
  const [listRecords, setListRecords] = useState<LoteSaida[]>([]);
  const [showModalEncerrarLote, setShowModalEncerrarLote] = useState<boolean>(false);
  const [modalEncerrarLote, setModalEncerrarLote] = useState<LoteSaida>({});
  const [errors, setErrors] = useState<any>(null);
  const [propList, setPropList] = useState<any>([]);
  const [saida, setSaida] = useState<LoteSaida>({});
  const [listSaida, setListSaida] = useState<LoteSaidaAnimais[]>([]);
  const [showModalLoteSaida, setShowModalLoteSaida] = useState<boolean>(false);
  const [showModalVisualizarSaida, setShowModalVisualizarSaida] = useState<boolean>(false);

  const [listaCategorias, setListaCategorias] = useState<ListCategorias[]>([]);

  useIonViewWillEnter(async() => {
    const marString = await getStorageValue("ListaCategorias");
    const marList = JSON.parse(marString ? marString : "[]");
    setListaCategorias(marList)
    await getStorageValue("ListaPropriedades").then((response) => {
      const resp = response ? JSON.parse(response) : [];
      setPropList(resp)
    })
  },[])
  
  const initialInputFields: InputField[] = [
    {
      name: "arroba",
      label: "Valor Arroba",
      type: "tel",
      required: true,
      onIonBlur: (e: any) => {
        const value = parseFloat(e.target.value.replace(",", ".").match(/[\d]+/));
        if (!isNaN(value)) {
          const valueKilo = parseFloat((value / 30).toFixed(2));
          const nextValArroba = value.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
          const nextValKilo = valueKilo.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
          const curValArroba = inputFields[inputFields.findIndex((obj) => obj.name === "arroba")].value;

          if (curValArroba !== nextValArroba) {
            inputFields[inputFields.findIndex((obj) => obj.name === "arroba")].value = nextValArroba;
            inputFields[inputFields.findIndex((obj) => obj.name === "gcolotesaida_valKilo")].value = nextValKilo;
            setInputFields([...inputFields]);
          }
        } else {
          e.target.value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "arroba")].value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolotesaida_valKilo")].value = "";
          setInputFields([...inputFields]);
        }
      },
    },
    {
      name: "gcolotesaida_valKilo",
      label: "Valor Kilo",
      type: "tel",
      required: true,
      onIonBlur: (e: any) => {
        const value = parseFloat(e.target.value.replace(",", ".").match(/[\d]+/));
        if (!isNaN(value)) {
          const valueArroba = parseFloat((value * 30).toFixed(2));
          const nextValArroba = valueArroba.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
          const nextValKilo = value.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
          const curValKilo = inputFields[inputFields.findIndex((obj) => obj.name === "gcolotesaida_valKilo")].value;

          if (curValKilo !== nextValKilo) {
            inputFields[inputFields.findIndex((obj) => obj.name === "arroba")].value = nextValArroba;
            inputFields[inputFields.findIndex((obj) => obj.name === "gcolotesaida_valKilo")].value = nextValKilo;
            setInputFields([...inputFields]);
          }
        } else {
          e.target.value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "arroba")].value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolotesaida_valKilo")].value = "";
          setInputFields([...inputFields]);
        }
      },
    },
    {
      name: "gcolotesaida_rendimento",
      label: "Rendimento (%)",
      type: "tel",
      required: true,
      onIonBlur: (e: any) => {
        const value = parseFloat(e.target.value.replace(",", ".").match(/[\d]+/));
        if (!isNaN(value)) {
          const valueLocalized = (value * 0.01).toLocaleString("pt-BR", { style: "percent", minimumFractionDigits: 2 });
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolotesaida_rendimento")].value = valueLocalized;
          setInputFields([...inputFields]);
        } else {
          e.target.value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolotesaida_rendimento")].value = "";
          setInputFields([...inputFields]);
        }
      },
    },
    {
      name: "gcolotesaida_comprador",
      label: "Comprador",
      type: "text",
      required: true,
    },
    {
      name: "gcolotesaida_frete",
      label: "Frete TOTAL",
      type: "tel",
      required: true,
      onIonBlur: (e: any) => {
        const value = parseFloat(e.target.value.replace(",", ".").match(/[\d]+/));
        if (!isNaN(value)) {
          const valueLocalized = value.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolotesaida_frete")].value = valueLocalized;
          setInputFields([...inputFields]);
        } else {
          e.target.value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolotesaida_frete")].value = "";
          setInputFields([...inputFields]);
        }
      },
    },
    {
      name: "gcolotesaida_datasaida",
      label: "Data de Saída",
      required: true,
      isDateTimerPicker: true,
    },
  ];

  const [inputFields, setInputFields] = useState<InputField[]>(initialInputFields);

  const setFieldValue = (value: any, field: string) => {
    modalEncerrarLote[field] = value;
  };

  const getErrors = () => {
    let mutableErrors: any = {};
    inputFields &&
      inputFields.forEach((field) => {
        if (field.required) addFieldRequiredError(modalEncerrarLote, mutableErrors, field.name);
      });

    return mutableErrors;
  };

  useIonViewWillEnter(async () => {
    await setRecords();
  }, []);

  const setRecords = async () => {
    await CarregarLotesSaida().then((response) => {
      setListRecords([...response]);
    });
  };

  const loadMoreRecords = async ($event: CustomEvent<void>) => {
    await CarregarMaisLotesSaida(listRecords.length).then((response) => {
      if (response.length === 0)
      {
        setDisableInfiniteScroll(true);
      }
      else
      {
        setListRecords([...listRecords, ...response]);
      }
    });
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  };

  const syncAllData = async (e) => {
    await Network.getStatus().then(async (network) => {
      if (network.connected) {
        setBtnAnimated(true);
        dispatch({ type: "SET_LOADING", showLoading: true });
        await SyncLoteSaida();
        await setRecords().then(() => {
          dispatch({
            type: "SET_SUCCESSMESSAGE",
            successMessage: "Lotes de Saída sincronizados com SUCESSO.",
          });
          dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: true });
          setBtnAnimated(false);
          dispatch({ type: "SET_LOADING", showLoading: false });
        });
      } else {
        dispatch({
          type: "SET_ERRORMESSAGE",
          errorMessage: "Não foi possível sincronizar os lotes de saída. Você não possui conexão com a internet. Tente Novamente",
        });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
      }
    });
  };

  const handleContinue = (idLote) => {
    history.push("/preencherLoteSaida/" + idLote);
  };

  const handlePreEncerrarLote = (lote: LoteSaida) => {
    if (lote.gcolotesaida_qtdcabeca! <= 0) {
      history.push("/preencherLoteSaida/" + lote.id);
    } else {
      inputFields.map((field) => {
        return field.value = lote[field.name];
      });
      console.log(inputFields)
      setModalEncerrarLote(lote);
      setShowModalEncerrarLote(true);
    }
  };

  const handleEncerrarLote = async (lote: LoteSaida) => {
    dispatch({ type: "SET_LOADING", showLoading: true });
    setErrors([]);
    const loginErrors = getErrors();

    if (!hasProperties(loginErrors)) {
      await EncerrarLoteSaida(lote).then(async () => {
        await Network.getStatus().then(async (network) => {
          if (network.connected) {
            await SyncLoteSaida();
            await syncAnimais();
            await setRecords().then(() => {
              dispatch({ type: "SET_LOADING", showLoading: false });
              setShowModalEncerrarLote(false);
            });
          } else {
            await setRecords().then(() => {
              dispatch({ type: "SET_LOADING", showLoading: false });
              setShowModalEncerrarLote(false);
            });
          }
        });
      });
    } else {
      dispatch({ type: "SET_LOADING", showLoading: false });
      setErrors(loginErrors);
    }
  };

  const menuRightBtns: RightButtonsLoggedPage[] = [
    {
      text: "Novo Lote",
      rightBtnIcon: addSharp,
      click: () => {
        history.push("/gadoSaidaNovo");
      },
    },
    {
      text: "Sincronizar Lotes",
      rightBtnIcon: syncSharp,
      click: syncAllData,
      rightBtnIconAnimated: btnAnimated,
    },
  ];

  return (
    <LoggedPage title="Lotes de Saída" headerSubText="Lista de Lotes de Saída criados pelo APP" buttons={menuRightBtns}>
      <IonGrid>
        <IonRow className="ion-align-items-center">
          <IonCol size="12" className="ion-text-center">
            <IonButton
              onClick={(e) => {
                history.push("/gadoSaidaNovo");
              }}
              color="primary"
            >
              <IonIcon src={addSharp} slot="end" />
              Novo Lote
            </IonButton>
          </IonCol>
        </IonRow>
        {listRecords.length > 0 ? (
          listRecords.map((item, index) => {
            return (
              <IonCard key={index}>
                <IonCardHeader>
                  <IonCardTitle className="display_flex card_animal_header_text">
                    Id do Lote:
                    {item.server_id === 0 ? (
                      <IonText className="texto_vermelho bold_margin_left">Não Sincronizado</IonText>
                    ) : (
                      <IonText className="texto_verde bold_margin_left">
                        <b>{item.server_id}</b>
                      </IonText>
                    )}
                  </IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  <IonGrid>
                    <IonRow className="card_animal_regular_text">
                      Comprador: <b className="ml-10">{item.gcolotesaida_comprador}</b>
                    </IonRow>
                    <IonRow className="card_animal_regular_text">
                      Propriedade: <b className="ml-10">{propList.filter((val) => val.value === item.gcolotesaida_faz_id_fk)[0].label}</b>
                    </IonRow>
                    <IonRow className="card_animal_regular_text">
                      Data: <b className="ml-10">{moment(item.gcolotesaida_datasaida).locale("pt-br").format("ll")}</b>
                    </IonRow>
                    <IonRow className="card_animal_regular_text">
                      Cabeças: <b className="ml-10">{item.gcolotesaida_qtdcabeca === 0 ? <>-</> : item.gcolotesaida_qtdcabeca}</b>
                    </IonRow>
                    <IonRow className="card_animal_regular_text">
                      Valor @:
                      <b className="ml-10">
                        {item.gcolotesaida_valKilo ? (item.gcolotesaida_valKilo * 30).toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" }) : <>-</>}
                      </b>
                    </IonRow>
                    {!!item.is_finished && (
                      <IonRow className="card_animal_regular_text">
                        Rendimento:
                        <b className="ml-10">{item.gcolotesaida_rendimento ? (item.gcolotesaida_rendimento * 0.01).toLocaleString("pt-BR", { style: "percent", minimumFractionDigits: 2 }) : <>-</>}</b>
                      </IonRow>
                    )}
                    <IonRow className="card_animal_regular_text">
                      Frete:
                      <b className="ml-10">{item.gcolotesaida_frete ? item.gcolotesaida_frete.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" }) : <>-</>}</b>
                    </IonRow>
                    {!!item.is_finished && (
                      <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center font-35" size="6">
                          <IonIcon
                            icon={informationCircleSharp}
                            onClick={async () => {
                              await CarregarLoteSaida(item.id!).then((data) => {
                                setSaida(data[0]);
                              });
                              await CarregarAnimaisLoteSaida(item.id!).then((data) => {
                                setListSaida(data);
                              });
                              setShowModalLoteSaida(true);
                            }}
                          />
                        </IonCol>
                        <IonCol className="ion-text-center font-35" size="6">
                          <IonIcon
                            icon={listSharp}
                            onClick={async () => {
                              await CarregarLoteSaida(item.id!).then((data) => {
                                setSaida(data[0]);
                              });
                              await CarregarAnimaisLoteSaida(item.id!).then((data) => {
                                setListSaida(data);
                              });
                              setShowModalVisualizarSaida(true);
                            }}
                          />
                        </IonCol>
                      </IonRow>
                    )}
                    {item.is_finished ? (
                      <IonRow className="ion-justify-content-center">
                        <IonBadge color="danger">Lote Encerrado</IonBadge>
                      </IonRow>
                    ) : (
                      <>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="12" className="ion-text-center">
                            <IonButton
                              onClick={(e) => {
                                handleContinue(item.id);
                              }}
                            >
                              Incluir Animais
                            </IonButton>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="12" className="ion-text-center">
                            <IonButton
                              onClick={(e) => {
                                handlePreEncerrarLote(item);
                              }}
                              color="secondary"
                            >
                              Enviar Lote
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </>
                    )}
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            );
          })
        ) : (
          <IonRow>
            <IonCol>
              <IonText color="danger">Nenhum Lote Criado</IonText>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>

      <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => loadMoreRecords(e)}>
        <IonInfiniteScrollContent loadingText="Carregando mais dados"></IonInfiniteScrollContent>
      </IonInfiniteScroll>
      <IonModal
        isOpen={showModalEncerrarLote}
        onDidDismiss={() => {
          setModalEncerrarLote({});
          setShowModalEncerrarLote(false);
        }}
      >
        <IonContent>
          <IonGrid className="modal_grid_95 ">
            <IonRow className="card_animal_regular_text">
              <IonText className="ion-text-center">
                <p>Preencha os dados Abaixo para encerrar este lote de saída:</p>
              </IonText>
            </IonRow>

            <Form items={inputFields} errors={errors} setFieldValue={setFieldValue} />
            <IonRow className="ion-text-center">
              <IonCol>
                <IonButton
                  color="primary"
                  onClick={() => {
                    handleEncerrarLote(modalEncerrarLote);
                  }}
                >
                  Enviar
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol>
                <IonButton
                  color="danger"
                  onClick={() => {
                    setModalEncerrarLote({});
                    setShowModalEncerrarLote(false);
                  }}
                >
                  Cancelar
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showModalLoteSaida}
        cssClass="modal-80"
        onDidDismiss={() => {
          setSaida({});
          setListSaida([]);
          setShowModalLoteSaida(false);
        }}
      >
        <IonContent>
          <IonGrid className="modal_grid_95">
            <IonRow className="ion-justify-content-center my-10">
              <IonText className="ion-text-center">
                Lote:
                <b className="ml-10">{saida.server_id}</b>
              </IonText>
            </IonRow>
            <IonRow className="ion-text-center mb-10">
              <b className="ml-10">{saida.gcolotesaida_comprador}</b>
            </IonRow>

            <IonRow>
              Data:
              <b className="ml-10">{moment(saida.gcolotesaida_datasaida).locale("pt-br").format("ll")}</b>
            </IonRow>

            {/* <IonRow>
              Categorias:
              <b className="ml-10">
                {listSaida[0] &&
                  listSaida[0].gco_categoria &&
                  listSaida.map((item) => listaCategorias && listaCategorias.length > 0 && listaCategorias.filter((val) => val.value == item.gco_categoria)[0].label).filter((v,i,a)=>a.indexOf(v)==i).join(", ")}
              </b>
            </IonRow>
            <IonRow>
              Raças:
              <b className="ml-10">{listSaida[0] && listSaida[0].gco_raca && listSaida.map((item) => item.gco_raca).filter((v,i,a)=>a.indexOf(v)==i).join(", ")}</b>
            </IonRow> */}

            <IonRow>
              Quantidade:
              <b className="ml-10">{listSaida.length}</b>
            </IonRow>
            <IonRow>
              Rendimento:
              <b className="ml-10">{saida.gcolotesaida_rendimento && (saida.gcolotesaida_rendimento * 0.01).toLocaleString("pt-BR", { style: "percent", minimumFractionDigits: 2 })}</b>
            </IonRow>
            <IonRow>
              Peso Total:
              <b className="ml-10">
                {listSaida
                  .map((item) => item.gco_peso_balanca)
                  .reduce((prev, curr) => Number(prev) + Number(curr), 0)
                  .toFixed(2)}{" "}
                Kg
              </b>
            </IonRow>
            <IonRow>
              Média de Peso:
              <b className="ml-10">
                {(listSaida.map((item) => item.gco_peso_balanca).reduce((prev, curr) => Number(prev) + Number(curr), 0) / (listSaida.length > 0 ? listSaida.length : 1)).toFixed(2)}
                Kg
              </b>
            </IonRow>
            <IonRow>
              Média de Peso @:
              <b className="ml-10">
                {(
                  ((listSaida.map((item) => item.gco_peso_balanca).reduce((prev, curr) => Number(prev) + Number(curr), 0) / (listSaida.length > 0 ? listSaida.length : 1)) *
                    (saida.gcolotesaida_rendimento! / 100)) /
                  15
                ).toFixed(2)}
              </b>
            </IonRow>

            <IonRow>
              Valor @:
              <b className="ml-10">
                {saida.gcolotesaida_valKilo ? (saida.gcolotesaida_valKilo * 30).toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" }) : <>-</>}
              </b>
            </IonRow>

            <IonRow>
              Valor CAB:
              <b className="ml-10">
                {(
                  (((listSaida.map((item) => item.gco_peso_balanca).reduce((prev, curr) => Number(prev) + Number(curr), 0) / (listSaida.length > 0 ? listSaida.length : 1)) *
                    (saida.gcolotesaida_rendimento! / 100)) /
                    15) *
                  (saida.gcolotesaida_valKilo ? saida.gcolotesaida_valKilo * 30 : 0)
                ).toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" })}
              </b>
            </IonRow>

            <IonRow>
              Valor LOTE:
              <b className="ml-10">
                {(
                  (((listSaida.map((item) => item.gco_peso_balanca).reduce((prev, curr) => Number(prev) + Number(curr), 0) / (listSaida.length > 0 ? listSaida.length : 1)) *
                    (saida.gcolotesaida_rendimento! / 100)) /
                    15) *
                  (saida.gcolotesaida_valKilo ? saida.gcolotesaida_valKilo * 30 : 0) *
                  listSaida.length
                ).toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" })}
              </b>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showModalVisualizarSaida}
        cssClass="modal-80"
        onDidDismiss={() => {
          setSaida({});
          setListSaida([]);
          setShowModalVisualizarSaida(false);
        }}
      >
        <IonContent>
          <IonGrid className="modal_grid_95">
            <IonRow className="ion-justify-content-center my-10">
              <IonText className="ion-text-center">
                Lote:
                <b className="ml-10">{saida.server_id}</b>
              </IonText>
            </IonRow>
            <IonRow className="ion-text-center mb-10">
              <b className="ml-10">{saida.gcolotesaida_comprador}</b>
            </IonRow>

            <IonRow>
              Data:
              <b className="ml-10">{moment(saida.gcolotesaida_datasaida).locale("pt-br").format("ll")}</b>
            </IonRow>

            <IonList lines="full">
              {listSaida.map((item, index) => {
                return (
                  <IonItem key={index}>
                    <IonLabel className="ion-text-wrap">
                      <IonRow>
                        <IonCol size="2">#{index+1}</IonCol>
                        <IonCol size="10">{item.gco_brinco_bastao}</IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="6">Peso: {item.gco_peso_balanca}{" "}Kg</IonCol>
                        <IonCol size="6">Marca: {item.gco_marca}</IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol size="6">{item.gco_raca}</IonCol>
                        <IonCol size="6">{listSaida[0] &&
                  listSaida[0].gco_categoria && listaCategorias && listaCategorias.length > 0 && listaCategorias.filter((val) => val.value === item.gco_categoria)[0].label}</IonCol>
                      </IonRow>
                    </IonLabel>
                  </IonItem>
                );
              })}
            </IonList>
          </IonGrid>
        </IonContent>
      </IonModal>
    </LoggedPage>
  );
};

export default GadoLoteSaida;
