export const Marcas1 = [
  { value: 1, label: "J7" },
  { value: 2, label: "P7" },
];
export const RacasList = [
  { value: 1, label: "Nelore" },
  { value: 2, label: "Angus" },
  { value: 3, label: "Cruzado" },
  { value: 4, label: "Hereford" },
  { value: 5, label: "Braford" },
  { value: 6, label: "Red Angus" },
];

export const CategoriasList = [
  { value: 1, label: "Novilha" },
  { value: 2, label: "Vaca" },
  { value: 3, label: "Bezerro" },
  { value: 4, label: "Garrote" },
  { value: 5, label: "Boi" },
  { value: 6, label: "Touro" },
  { value: 7, label: "Bezerra" },
];

export const TipoCompra = [
  { value: 1, label: "Peso" },
  { value: 2, label: "Cabeça" },
  // { value: 3, label: "Transferência" }
];