import { IonItem, IonLabel, IonInput, IonIcon, IonTextarea, IonText, IonGrid, IonRow, IonButton, IonCol, IonFab, IonFabButton, IonImg, IonDatetime } from "@ionic/react";
import React from "react";
import { cameraSharp, closeSharp } from "ionicons/icons";
import "./StickScaleForm.css";
import { RootStateOrAny, useSelector } from "react-redux";
import GeneralError, { FormErrorProps } from "../GeneralError/GeneralError";
import { isEmpty } from "lodash";

interface StickScaleFormProps {
  brincoBastao?: boolean;
  brincoManual?: boolean;
  brincoManualValue?: string;
  balanca?: boolean;
  balancaManual?: boolean;
  balancaManualValue?: string;
  capturarImagens?: boolean;
  imagensDisplay?: ImageProps[];
  capturarImagemFunction?: Function;
  removerImagem?: Function;
  items?: InputField[];
  errors?: FormErrorProps[];
  setFieldValue?: Function;
  isDateTimerPicker?: boolean;
}

export interface InputField {
  name: string;
  rows?: number;
  label: string;
  type?: "email" | "password" | "text" | "number" | "textArea";
  value?: string;
  disabled?: boolean;
  debounce?: number;
  onIonBlur?: any;
  required?: boolean;
  isDateTimerPicker?: any;
}

export interface ImageProps {
  nome: string;
  conteudo: string;
}

const StickScaleForm: React.FC<StickScaleFormProps> = ({
  brincoBastao,
  brincoManual,
  brincoManualValue,
  balanca,
  balancaManual,
  balancaManualValue,
  capturarImagens,
  imagensDisplay,
  capturarImagemFunction,
  removerImagem,
  items,
  errors,
  setFieldValue,
  isDateTimerPicker,
}) => {
  const bastaoValue = useSelector((state: RootStateOrAny) => state.bastaoValue);
  const balancaValue = useSelector((state: RootStateOrAny) => state.balancaValue);

  // const startScan = async () => {
  //   const status = await BarcodeScanner.checkPermission({ force: true });

  //   if (status.granted) {
  //     BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] });
  //     dispatch({ type: "SET_HIDEBG", hideBg: true });

  //     BarcodeScanner.hideBackground(); // make background of WebView transparent

  //     const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

  //     // if the result has content
  //     if (result.hasContent) {
  //       dispatch({ type: "SET_HIDEBG", hideBg: false });
  //       dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: result.content });
  //     }
  //   } else {
  //     BarcodeScanner.openAppSettings();
  //   }
  // };

  // const stopScan = async () => {
  //   dispatch({ type: "SET_HIDEBG", hideBg: false });
  //   await BarcodeScanner.stopScan();
  // };

  return (
    <>
      <IonGrid>
        {!!brincoBastao ? (
          <>
            <IonItem>
              <IonLabel position="stacked">Brinco Bastão</IonLabel>
              <IonRow className="rowFullWidth">
                <IonCol size="12">
                  <IonInput
                    value={bastaoValue}
                    readonly={true}
                    onIonChange={(e) => {
                      setFieldValue && setFieldValue(e.detail.value, "gco_brinco_bastao");
                    }}
                  ></IonInput>
                </IonCol>
                {/* <IonCol className="ion-align-items-end" size="1">
                <IonIcon className="qrCodeBtnForm" icon={qrCodeSharp} onClick={startScan} />
              </IonCol> */}
              </IonRow>
              {errors && errors.find((x) => x.rowName === "gco_brinco_bastao")?.hasError ? GeneralError(errors.find((x) => x.rowName === "gco_brinco_bastao")!) : <></>}
            </IonItem>
          </>
        ) : (
          <></>
        )}

        {!!brincoManual ? (
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">Brinco Manual</IonLabel>
                <IonInput
                  value={brincoManualValue}
                  type="number"
                  onIonChange={(e) => {
                    setFieldValue && setFieldValue(e.detail.value, "gco_brinco_manual");
                  }}
                ></IonInput>
                {errors && errors.find((x) => x.rowName === "gco_brinco_manual")?.hasError ? GeneralError(errors.find((x) => x.rowName === "gco_brinco_manual")!) : <></>}
              </IonItem>
            </IonCol>
          </IonRow>
        ) : (
          <></>
        )}

        {!!balanca ? (
          <IonItem>
            <IonLabel position="stacked">Peso Balança Bluetooth</IonLabel>
            <IonInput
              value={balancaValue}
              onIonChange={(e) => {
                setFieldValue && setFieldValue(e.detail.value, "gco_peso_balanca");
              }}
            ></IonInput>
            {errors && errors.find((x) => x.rowName === "gco_peso_balanca")?.hasError ? GeneralError(errors.find((x) => x.rowName === "gco_peso_balanca")!) : <></>}
          </IonItem>
        ) : (
          <></>
        )}
        {!!balancaManual ? (
          <IonItem>
            <IonLabel position="stacked">Peso Balança</IonLabel>
            <IonInput
              value={balancaManualValue}
              onIonChange={(e) => {
                setFieldValue && setFieldValue(e.detail.value, "gco_peso_balanca_manual");
              }}
            ></IonInput>
            {errors && errors.find((x) => x.rowName === "gco_peso_balanca_manual")?.hasError ? GeneralError(errors.find((x) => x.rowName === "gco_peso_balanca_manual")!) : <></>}
          </IonItem>
        ) : (
          <></>
        )}

        {items &&
          items.map((item, index) => {
            const inputProperties = {};
            if (!!item.value) {
              inputProperties["value"] = item.value;
            }
            if (!!item.disabled) {
              inputProperties["disabled"] = item.disabled;
            }

            if (!!item.debounce) {
              inputProperties["debounce"] = item.debounce;
            }

            if (!!item.onIonBlur) {
              inputProperties["onIonBlur"] = item.onIonBlur;
            }

            return (
              <IonItem key={`item${index}`}>
                <IonLabel position="stacked">{item.label}</IonLabel>
                {item.isDateTimerPicker ? (
                  <IonDatetime
                    displayFormat="DD MMM YYYY"
                    onIonChange={(e) => {
                      setFieldValue && setFieldValue(e.detail.value, item.name);
                    }}
                  ></IonDatetime>
                ) : item.type === "textArea" ? (
                  isEmpty(item.value) ? (
                    <IonTextarea
                      clearOnEdit
                      rows={item.rows}
                      onIonChange={(e) => {
                        setFieldValue && setFieldValue(e.detail.value, item.name);
                      }}
                    ></IonTextarea>
                  ) : (
                    <IonTextarea
                      clearOnEdit
                      value={item.value}
                      rows={item.rows}
                      onIonChange={(e) => {
                        setFieldValue && setFieldValue(e.detail.value, item.name);
                      }}
                    ></IonTextarea>
                  )
                ) : isEmpty(item.value) ? (
                  <IonInput
                    onIonChange={(e) => {
                      setFieldValue && setFieldValue(e.detail.value, item.name);
                    }}
                  ></IonInput>
                ) : (
                  <IonInput
                    value={item.value}
                    onIonChange={(e) => {
                      setFieldValue && setFieldValue(e.detail.value, item.name);
                    }}
                  ></IonInput>
                )}

                {errors && errors.find((x) => x.rowName === item.name)?.hasError ? GeneralError(errors.find((x) => x.rowName === item.name)!) : <></>}
              </IonItem>
            );
          })}

        {capturarImagens ? (
          <IonGrid>
            <IonRow className="rowPadding">
              <IonText>Imagens</IonText>
            </IonRow>
            <IonRow className="rowPadding">{errors && errors.find((x) => x.rowName === "imagem")?.hasError ? GeneralError(errors.find((x) => x.rowName === "imagem")!) : <></>}</IonRow>
            <IonRow className="rowPadding">
              <IonButton
                onClick={(e) => {
                  capturarImagemFunction && capturarImagemFunction();
                }}
              >
                <IonIcon icon={cameraSharp} />
              </IonButton>
            </IonRow>

            <IonRow className="rowPadding">
              {imagensDisplay?.map((photo, index) => (
                <IonCol size="6" key={`col${index}`}>
                  <IonFab
                    className="imageFabPosition"
                    key={`fab${index}`}
                    onClick={() => {
                      removerImagem && removerImagem(photo);
                    }}
                  >
                    <IonFabButton key={`fabbtn${index}`} color="danger" class="imageFabSize">
                      <IonIcon key={`fabicon${index}`} icon={closeSharp} />
                    </IonFabButton>
                  </IonFab>

                  <IonImg key={`img${index}`} src={photo.conteudo} />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        ) : (
          <></>
        )}
      </IonGrid>
    </>
  );
};

export default StickScaleForm;
