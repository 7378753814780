import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../../App";
import { LoteEntrada, LoteEntradaAnimais } from "../../../models/gado/gadoLoteEntrada/gadoLoteEntrada";
import { DbConnection } from "../DbConnection";

export const CarregarLoteEntrada = async (lote: number): Promise<LoteEntrada> => {  
  let db = await DbConnection();
  await db.open();
  const records = await db.query(`SELECT * FROM LoteEntrada WHERE id = '${lote}';`);
  let responseSelect: any = records.values ? records.values : [];
  return responseSelect;
};

export const CarregarLotesEntrada = async (): Promise<LoteEntrada[]> => {
  let db = await DbConnection();
  await db.open();
  const records = await db.query("SELECT * FROM LoteEntrada order by id desc limit 20;");
  db.close();
  return records.values ? records.values : [];
};

export const CarregarMaisLotesEntrada = async (offset: number): Promise<LoteEntrada[]> => {
  let db = await DbConnection();
  await db.open();
  const records = await db.query("SELECT * FROM LoteEntrada order by id desc LIMIT 20 OFFSET " + offset + ";");
  db.close();
  return records.values ? records.values : [];
};
