import { IonLoading, IonToast } from "@ionic/react";
import React from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import './SuccessMessage.css'

const SuccessMessage = () => {
  const dispatch = useDispatch();

  const showSuccess = useSelector((state: RootStateOrAny) => state.showSuccess);
  const successMessage = useSelector((state: RootStateOrAny) => state.successMessage);

  return ( 
    <IonToast
      isOpen={showSuccess}
      message={successMessage}
      cssClass="toastSuccessMessage"
      color="success"
      duration={3000}
      onDidDismiss={() =>
        dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: false })
      }
      buttons={[
        {
          text: '✖',
          role: 'cancel',
          cssClass: "success-toast"
        }
      ]}
    />
  );
};

export default SuccessMessage;
