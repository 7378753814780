import { IonButton, IonCol, IonGrid, IonRow, IonText, useIonAlert, useIonViewWillEnter } from "@ionic/react";
import { chevronBackOutline, syncSharp } from "ionicons/icons";
import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import LoggedPage, { RightButtonsLoggedPage } from "../../../../components/LoggedPage/LoggedPage";
import { useHistory } from "react-router";
import Form, { InputField } from "../../../../components/Forms/Form";
import StickScaleForm from "../../../../components/Forms/StickScaleForm/StickScaleForm";
import { CarregarAnimaisLoteEntrada } from "../../../../services/databaseServices/loteEntrada/CarregarAnimaisLoteEntrada";
import { LoteEntrada, LoteEntradaAnimais } from "../../../../models/gado/gadoLoteEntrada/gadoLoteEntrada";
import { VerificarAnimalLoteEntrada } from "../../../../services/databaseServices/loteEntrada/VerificarAnimalLoteEntrada";
import { ScaleWeight } from "../../../../components/scaleWeight/ScaleWeight";
import { WeightModal } from "../../../../components/weightModal/WeightModal";
import { CarregarLoteEntrada } from "../../../../services/databaseServices/loteEntrada/CarregarLoteEntrada";
import { CategoriasList, RacasList } from "../../../../settings/constants/properties";
import { AdicionarAnimalLoteEntrada } from "../../../../services/databaseServices/loteEntrada/AdicionarAnimalLoteEntrada";
import AnimaisLoteEntrada from "../../../../components/loteEntrada/AnimaisLoteEntrada";
import { AtualizarPesoAnimalLoteEntrada } from "../../../../services/databaseServices/loteEntrada/AtualizarPesoAnimalLoteEntrada";
import { RemoverAnimalLoteEntrada } from "../../../../services/databaseServices/loteEntrada/RemoverAnimalLoteEntrada";
import { addFieldRequiredError, hasProperties } from "../../../../utils";
import { getStorageValue } from "../../../../services/StorageServices";

const PreencherLoteEntrada: React.FC = (routing: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [present] = useIonAlert();
  const {
    match: {
      params: { id },
    },
  } = routing;
  const balancaValue = useSelector((state: RootStateOrAny) => state.balancaValue);
  const [showPesoModal, setShowPesoModal] = useState<boolean>(false);
  const [pesoManual, setPesoManual] = useState<number>(0);
  const [pesoManualErro, setPesoManualErro] = useState<boolean>(false);
  const [listEntrada, setListEntrada] = useState<LoteEntradaAnimais[]>([]);
  const [entrada, setEntrada] = useState<LoteEntrada>();
  const [loadingAnimals, setLoadingAnimals] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>(null);
  //const [brincoManual, setBrincoManual] = useState<string>("");
  const bastaoValue = useSelector((state: RootStateOrAny) => state.bastaoValue);

  const initState: LoteEntradaAnimais = {
    gco_peso_balanca: 0,
    lote_id: id,
    gco_brinco_bastao: "",
    gco_brinco_manual: "",
  };


  const [formInfo, setFormInfo] = useState<LoteEntradaAnimais>(initState);
  const initialInputFields: InputField[] = [
    {
      name: "gco_marca",
      label: "Marca",
      required: true,
      select: true,
      selectValues: [],
      selectValueLabel: true,
      value: formInfo.gco_marca,
    },
    {
      name: "gco_raca",
      label: "Raça",
      required: true,
      select: true,
      selectValues: RacasList.sort((a, b) => a.label.localeCompare(b.label)),
      selectValueLabel: true,
      value: formInfo.gco_raca,
    },
    {
      name: "gco_tipo",
      label: "Categoria",
      required: true,
      select: true,
      selectValues: CategoriasList.sort((a, b) => a.label.localeCompare(b.label)),
      selectValueLabel: true,
      value: formInfo.gco_tipo,
    },
    {
      name: "gco_con_id_fk",
      label: "Confinamento",
      required: true,
      select: true,
      selectValues: [],
      value: formInfo.gco_con_id_fk,
    },
  ];
  const [inputFields, setInputFields] = useState<InputField[]>(initialInputFields);

  const setFieldValue = async (value: any, field: string) => {
    formInfo[field] = value;
    if (field === "gco_raca") {
      inputFields[inputFields.findIndex((obj) => obj.name === "gco_raca")].value = value;
    }
    if (field === "gco_tipo") {
      inputFields[inputFields.findIndex((obj) => obj.name === "gco_tipo")].value = value;
    }
    if (field === "gco_con_id_fk") {
      inputFields[inputFields.findIndex((obj) => obj.name === "gco_con_id_fk")].value = value;
    }
  };

  const getErrors = () => {
    let mutableErrors: any = {};
    inputFields &&
      inputFields.forEach((field) => {
        if (field.required) addFieldRequiredError(formInfo, mutableErrors, field.name);
      });
    if (formInfo["gcolote_tipo_compra"] === 0) {
      addFieldRequiredError(formInfo, mutableErrors, "gcolote_tipo_compra");
    }
    return mutableErrors;
  };

  useEffect(() => {
    if (bastaoValue !== "") {
      formInfo.gco_brinco_manual = "";
    }
  }, [bastaoValue]);

  useEffect(() => {
    if (pesoManual > 0) {
      formInfo["gco_peso_manual"] = pesoManual;
    }
  }, [pesoManual]);

  useIonViewWillEnter(async () => {
    dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: "" });
    const loteEntrada = await CarregarLoteEntrada(id);
    const confString = await getStorageValue("ListaConfinamentos");
    const confList = JSON.parse(confString ? confString : "[]");
    const marString = await getStorageValue("ListaMarcas");
    const marList = JSON.parse(marString ? marString : "[]");
    initialInputFields[0].selectValues = marList.filter((val) => val.faz_id == loteEntrada[0].gcolote_faz_id_fk).sort((a, b) => a.label.localeCompare(b.label));
    initialInputFields[3].selectValues = confList.filter((val) => val.faz_id_fk == loteEntrada[0].gcolote_faz_id_fk).sort((a, b) => a.label.localeCompare(b.label));
    initialInputFields[1].value = RacasList.filter((val) => val.value == loteEntrada[0].gcolote_raca)[0].label;
    initialInputFields[2].value = CategoriasList.filter((val) => val.value == loteEntrada[0].gcolote_categoria)[0].label;
    initialInputFields[3].value = confList.filter((val) => val.value == loteEntrada[0].gcolote_con_id_fk)[0].value;
    const loteEntradaAnimais = await CarregarAnimaisLoteEntrada(id);
    setListEntrada(loteEntradaAnimais);
    setLoadingAnimals(false);
  }, []);

  const menuRightBtns: RightButtonsLoggedPage[] = [
    {
      text: "Sincronizar Animais",
      rightBtnIcon: syncSharp,
      click: undefined,
    },
    {
      text: "Voltar",
      rightBtnIcon: chevronBackOutline,
      click: () => {
        history.replace("/gadoEntrada");
      },
    },
  ];

  const adicionarLoteEntrada = async () => {
    if (formInfo.gco_brinco_manual === "" && bastaoValue === "") {
      present({
        header: "Erro",
        message: "Você precisa entrar com o brinco do animal",
        buttons: ["OK"],
      });
      return true;
    }
    formInfo.gco_brinco_manual === "" ? (formInfo.gco_brinco_manual = bastaoValue) : (formInfo.gco_brinco_bastao = formInfo.gco_brinco_manual);

    const loginErrors = getErrors();
    if (!hasProperties(loginErrors)) {
      setErrors([]);
      if (parseInt(balancaValue) === 0 && pesoManual === 0) {
        setShowPesoModal(true);
        return true;
      }

      parseInt(balancaValue) === 0 ? (formInfo["gco_peso_balanca"] = formInfo["gco_peso_manual"]!) : (formInfo["gco_peso_manual"] = formInfo["gco_peso_balanca"] = parseInt(balancaValue));

      const brinco = formInfo.gco_brinco_manual !== "" ? formInfo.gco_brinco_manual : bastaoValue;
      const peso = parseInt(balancaValue) !== 0 ? parseInt(balancaValue) : pesoManual;

      await VerificarAnimalLoteEntrada(id, brinco).then(async (response) => {
        if (response) {
          //se existir, perguntar se quer atualizar o peso
          present({
            header: "Erro",
            message: "Já existe um animal com este número de brinco neste lote, deseja atualizar o peso?",
            buttons: [
              "Cancelar",
              {
                text: "Atualizar Peso",
                handler: async (d) => {
                  await AtualizarPesoAnimalLoteEntrada(formInfo).then(async () => {
                    setLoadingAnimals(true);
                    await CarregarAnimaisLoteEntrada(id)
                      .then((data) => {
                        setListEntrada(data);
                        setLoadingAnimals(false);
                        //Reset Form INFO
                        setPesoManual(0);
                        formInfo.gco_brinco_manual = "";
                        dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: "" });
                      })
                      .catch((error) => {
                        dispatch({
                          type: "SET_ERRORMESSAGE",
                          errorMessage: error.message,
                        });
                        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
                        dispatch({ type: "SET_LOADING", showLoading: false });
                      });
                  });
                },
              },
            ],
          });
        } else {
          await AdicionarAnimalLoteEntrada(formInfo).then(async (response) => {
            setListEntrada([...listEntrada, formInfo]);
            //Reset Form INFO
            setPesoManual(0);
            dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: "" });
            setFormInfo({ ...formInfo, gco_brinco_manual: "", gco_brinco_bastao: "" });
          });
        }
      });
    } else {
      dispatch({ type: "SET_LOADING", showLoading: false });
      setErrors(loginErrors);
    }
  };

  const removerLoteSaida = (item: LoteEntradaAnimais) => {
    present({
      header: "Atenção",
      message: "Tem certeza que deseja remover o animal: " + item.gco_brinco_bastao + " do lote de entrada?",
      buttons: [
        "Cancelar",
        {
          text: "Remover",
          handler: async (d) => {
            await RemoverAnimalLoteEntrada(item)
              .then(() => {
                setListEntrada(listEntrada.filter((animal) => animal.gco_brinco_bastao !== item.gco_brinco_bastao));
              })
              .catch((error) => {
                dispatch({
                  type: "SET_ERRORMESSAGE",
                  errorMessage: error.message,
                });
                dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
                dispatch({ type: "SET_LOADING", showLoading: false });
              });
          },
        },
      ],
    });
  };

  return (
    <LoggedPage title="Editar Lote de Entrada" buttons={menuRightBtns}>
      <IonGrid>
        <IonRow>
          <IonGrid>
            <ScaleWeight />
            <StickScaleForm brincoBastao={true} brincoManual={true} brincoManualValue={formInfo.gco_brinco_manual} setFieldValue={setFieldValue} />

            <Form items={inputFields} setFieldValue={setFieldValue} errors={errors} />

            <IonRow className="ion-justify-content-center ion-margin-vertical">
              <IonButton
                expand="full"
                onClick={() => {
                  adicionarLoteEntrada();
                }}
              >
                Inlcuir Animal
              </IonButton>
            </IonRow>
            <IonRow>
              <IonCol size="12" className="ion-text-center">
                <IonText className="font-20-b">Animais incluídos neste lote</IonText>
              </IonCol>
            </IonRow>
            <IonRow className="content-animais-lote-saida">
              <IonCol size="12">
                <AnimaisLoteEntrada listEntrada={listEntrada} loading={loadingAnimals} removeHandle={removerLoteSaida} remove={true} />
              </IonCol>
            </IonRow>
            <IonRow className="font-20-b">Resumo</IonRow>
            <IonRow>
              <IonCol size="3" className="m0p0">
                <IonGrid>
                  <IonRow className="ion-text-center">
                    <IonCol>Qtd</IonCol>
                  </IonRow>
                  <IonRow className="ion-text-center">
                    <IonCol> {listEntrada.length}</IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol size="5" className="m0p0">
                <IonGrid>
                  <IonRow className="ion-text-center">
                    <IonCol>Peso</IonCol>
                  </IonRow>
                  <IonRow className="ion-text-center">
                    <IonCol>
                      {listEntrada
                        .map((item) => item.gco_peso_balanca)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0)
                        .toFixed(2)}
                      Kg
                    </IonCol>
                  </IonRow>
                </IonGrid>{" "}
              </IonCol>
              <IonCol size="4" className="m0p0">
                <IonGrid>
                  <IonRow className="ion-text-center">
                    <IonCol>Média</IonCol>
                  </IonRow>
                  <IonRow className="ion-text-center">
                    <IonCol>
                      {(listEntrada.map((item) => item.gco_peso_balanca).reduce((prev, curr) => Number(prev) + Number(curr), 0) / (listEntrada.length > 0 ? listEntrada.length : 1)).toFixed(2)} Kg
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonButton
                color="secondary"
                onClick={() => {
                  history.replace("/gadoEntrada");
                }}
              >
                Finalizar Edição
              </IonButton>
            </IonRow>
          </IonGrid>
        </IonRow>
      </IonGrid>

      <WeightModal
        showWeightModal={showPesoModal}
        setShowWeightModal={setShowPesoModal}
        weightModalValue={pesoManual}
        setWeightModalValue={setPesoManual}
        weightModalError={pesoManualErro}
        setWeightModalError={setPesoManualErro}
        callbackFunction={adicionarLoteEntrada}
      />
    </LoggedPage>
  );
};

export default PreencherLoteEntrada;
