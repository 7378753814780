import { IonText } from '@ionic/react';
import React from 'react';
import './FormError.css';

interface FormErrorProps {
    errorMessage: JSX.Element;
    rowName: string;
}

const FormError = ({ errorMessage, rowName }: FormErrorProps) => {
    return (
        <div className="form__error">
            <IonText color="danger" className="form__error-element">
                <small>
                    <span role="alert" id={`${rowName}Error`}>
                        {errorMessage}
                    </span>
                </small>
            </IonText>
        </div>
    );
};

export default FormError;
