import { IonButton, IonCol, IonFab, IonFabButton, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonRow, IonText, IonTextarea, useIonViewWillEnter } from "@ionic/react";
import { cameraSharp, closeSharp } from "ionicons/icons";
import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { capSQLiteSet, SQLiteDBConnection } from "@capacitor-community/sqlite";
import { AppDispatch, sqlite } from "../../../App";
import { InformarMorteImagens, InformarMorte } from "../../../models/databaseModels";
import LoggedPage from "../../../components/LoggedPage/LoggedPage";
import "./GadoInformaMorte.css";
import GeneralError, { FormErrorProps } from "../../../components/Forms/GeneralError/GeneralError";
import { hasProperties } from "../../../utils";
import StickScaleForm, { ImageProps, InputField } from "../../../components/Forms/StickScaleForm/StickScaleForm";
import { Network } from "@capacitor/network";
import { syncMorteData } from "../../../services/syncServices/syncMorte/SyncMorte";

const GadoInformaMorte: React.FC = (routing: any) => {
  const initState: InformarMorte = {
    gco_brinco_bastao: "",
    gco_brinco_manual: "",
    motivo: "",
    imagens: [],
  };
  const [formInfo, setFormInfo] = useState<InformarMorte>(initState);
  const inputFields: InputField[] = [
    {
      name: "motivo",
      label: "Motivo",
      type: "textArea",
      rows: 3,
      value: formInfo.motivo,
    },
  ];

  const [errorsState, setErrorsState] = useState<FormErrorProps[]>([]);
  const bastaoValue = useSelector((state: RootStateOrAny) => state.bastaoValue);
  const [photos, setPhotos] = useState<ImageProps[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  useIonViewWillEnter(() => {
    dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: "" });
  }, []);

  const takePicture = async () => {
    const image = await Camera.getPhoto({ width: 1000, quality: 20, allowEditing: false, resultType: CameraResultType.DataUrl, source: CameraSource.Camera })
      .then((response) => {
        const newPhotos: ImageProps = {
          nome: new Date().getTime() + ".jpeg",
          conteudo: response.dataUrl!,
        };
        setPhotos([newPhotos, ...photos]);
      }).catch((error) => {
        dispatch({
          type: "SET_ERRORMESSAGE",
          errorMessage: error.message,
        });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
        dispatch({ type: "SET_LOADING", showLoading: false });
      });
  };

  const removePicture = async (e) => {
    setPhotos(photos.filter((img) => img != e));
  };
  const setFieldValue = (value: any, field: string) => {
    // @ts-ignore
    formInfo[field] = value;
    setFormInfo({
      ...formInfo,
    });
  };

  const handleSubmit = async () => {
    formInfo.imagens = photos;

    const errors: FormErrorProps[] = [];

    //VALIDATE FORM FIELDS
    //Check
    if (formInfo.gco_brinco_bastao === "" && formInfo.gco_brinco_manual === "") {
      const newError: FormErrorProps[] = [
        {
          rowName: "gco_brinco_bastao",
          hasError: true,
          errorMessage: "Você precisa preencher pelo menos um campo com número do brinco.",
        },
        {
          rowName: "gco_brinco_manual",
          hasError: true,
          errorMessage: "Você precisa preencher pelo menos um campo com número do brinco.",
        },
      ];
      errors.push(...newError);
    }
    if (formInfo.motivo === "") {
      const newError: FormErrorProps = {
        rowName: "motivo",
        hasError: true,
        errorMessage: "Você precisa preencher o motivo da morte do animal.",
      };
      errors.push(newError);
    }

    if (formInfo.imagens.length <= 0) {
      const newError: FormErrorProps = {
        rowName: "imagem",
        hasError: true,
        errorMessage: "Você precisa enviar pelo menos uma foto do animal.",
      };
      errors.push(newError);
    }
    setErrorsState(errors);

    if (!hasProperties(errors)) {
      dispatch({ type: "SET_LOADING", showLoading: true });

      // ADD TO DB

      let db: SQLiteDBConnection;
      if ((await sqlite.isConnection("trackBov")).result) {
        db = await sqlite.retrieveConnection("trackBov");
      } else {
        db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
      }

      await db.open().then(async () => {
        const data: Array<capSQLiteSet> = [
          {
            statement: "INSERT INTO InformarMorte (is_synced,server_id,gco_brinco_bastao,gco_brinco_manual,motivo) VALUES (?,?,?,?,?);",
            values: [0, 0, formInfo.gco_brinco_bastao, formInfo.gco_brinco_manual, formInfo.motivo],
          },
        ];

        // Add Data to DataBASE
        var ret: any = await db.executeSet(data).then(async (response) => {
          //Last Saved record ID
          const recordId = response.changes?.lastId;
          const dataImagens: Array<capSQLiteSet> = [];

          formInfo.imagens.forEach((element) => {
            dataImagens.push({
              statement: "INSERT INTO InformarMorteImagens (registro_id,is_synced,server_id,nome,conteudo) VALUES (?,?,?,?,?);",
              values: [recordId, 0, 0, element.nome, element.conteudo],
            });
          });

          // Add Data to DataBASE
          dataImagens.length > 0
            ? await db.executeSet(dataImagens).then(() => {
                db.close();
              })
            : db.close();
        });

        //Clear State and hide Loading
        setPhotos([]);
        dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: "" });
        setFormInfo(initState);

        await Network.getStatus().then(async (network) => {
          if (network.connected) {
            dispatch({ type: "SET_LOADING", showLoading: true });
            await syncMorteData().then(async (response) => {
              dispatch({
                type: "SET_SUCCESSMESSAGE",
                successMessage: "Informação de morte incluída com SUCESSO.",
              });
              dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: true });
              dispatch({ type: "SET_LOADING", showLoading: false });
            });
          } else {
            dispatch({
              type: "SET_ALERTMESSAGE",
              alertMessage: "Informação de morte incluída com sucesso, mas a sincronização com a nuvem falhou! Sincronize seu APP mais tarde.",
            });
            dispatch({ type: "SET_SHOWALERTMESSAGE", showAlert: true });
          }
        });

        dispatch({ type: "SET_LOADING", showLoading: false });
      });
    }
  };

  return (
    <LoggedPage title="Informar Morte" headerSubText="Preencha os campos abaixo para informar a morte do animal">
      <form id="myForm">
        <StickScaleForm
          brincoBastao={true}
          brincoManual={true}
          brincoManualValue={formInfo.gco_brinco_manual}
          capturarImagens={true}
          capturarImagemFunction={takePicture}
          removerImagem={removePicture}
          imagensDisplay={photos}
          items={inputFields}
          errors={errorsState}
          setFieldValue={setFieldValue}
        />
      </form>

      <div className="btnGridWrapper rowVerticalPadding">
        <IonButton className="btnGridFullWidth" onClick={handleSubmit}>
          Enviar
        </IonButton>
      </div>
    </LoggedPage>
  );
};

export default GadoInformaMorte;
