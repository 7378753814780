import { Network } from "@capacitor/network";
import {
  IonGrid,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonRow,
  IonBadge,
  IonCol,
  IonButton,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  useIonViewWillEnter,
  IonContent,
  IonModal,
  IonIcon,
} from "@ionic/react";
import { addSharp, syncSharp } from "ionicons/icons";
import moment from "moment";
import "moment/locale/pt-br";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { AppDispatch } from "../../../App";
import Form, { InputField } from "../../../components/Forms/Form";
import LoggedPage, { RightButtonsLoggedPage } from "../../../components/LoggedPage/LoggedPage";
import { LoteEntrada } from "../../../models/gado/gadoLoteEntrada/gadoLoteEntrada";
import { LoteSaida } from "../../../models/gado/gadoLoteSaida/gadoLoteSaida";
import { CarregarLotesEntrada, CarregarMaisLotesEntrada } from "../../../services/databaseServices/loteEntrada/CarregarLoteEntrada";
import { EncerrarLoteEntrada } from "../../../services/databaseServices/loteEntrada/EncerrarLoteEntrada";
import { getStorageValue } from "../../../services/StorageServices";
import { syncAnimais } from "../../../services/syncServices/syncAnimais/SyncAnimais";
import { SyncLoteEntrada } from "../../../services/syncServices/syncLoteEntrada/SyncLoteEntrada";
import { TipoCompra } from "../../../settings/constants/properties";
import { addFieldRequiredError, hasProperties } from "../../../utils";

const GadoLoteEntrada: React.FC = (routing: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [btnAnimated, setBtnAnimated] = useState<boolean>(false);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [listRecords, setListRecords] = useState<LoteEntrada[]>([]);

  const [showModalEncerrarLote, setShowModalEncerrarLote] = useState<boolean>(false);
  const [modalEncerrarLote, setModalEncerrarLote] = useState<LoteEntrada>({});
  const [errors, setErrors] = useState<any>(null);

  const [propList, setPropList] = useState<any>([]);
  const [confList, setConfList] = useState<any>([]);
  useIonViewWillEnter(async() => {
    await getStorageValue("ListaPropriedades").then((response) => {
      const resp = response ? JSON.parse(response) : [];
      setPropList(resp)
    })
    await getStorageValue("ListaConfinamentos").then((response) => {
      const resp = response ? JSON.parse(response) : [];
      setConfList(resp)
    })
  },[])
  const initialInputFieldsPeso: InputField[] = [
    {
      name: "gcolote_valor_peso_up",
      label: "Preço por Cabeça",
      type: "tel",
      onIonBlur: (e: any) => {
        const value = parseFloat(e.target.value.replace(",", ".").match(/[\d]+/));
        if (!isNaN(value)) {
          const valueLocalized = value.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_valor_peso_up")].value = valueLocalized;
          setInputFields([...inputFields]);
        } else {
          e.target.value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_valor_peso_up")].value = "";
          setInputFields([...inputFields]);
        }
      },
      required: false,
      hidden: true,
    },
    {
      name: "gcolote_peso_gado",
      label: "Peso Médio Por Cabeça (KG)",
      type: "number",
      required: false,
      hidden: true,
    },
    {
      name: "arroba",
      label: "Valor Arroba",
      type: "tel",
      onIonBlur: (e: any) => {
        const value = parseFloat(e.target.value.replace(",", ".").match(/[\d]+/));
        if (!isNaN(value)) {
          const valueKilo = parseFloat((value / 30).toFixed(2));
          const nextValArroba = value.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
          const nextValKilo = valueKilo.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
          const curValArroba = inputFields[inputFields.findIndex((obj) => obj.name === "arroba")].value;

          if (curValArroba !== nextValArroba) {
            inputFields[inputFields.findIndex((obj) => obj.name === "arroba")].value = nextValArroba;
            inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_valor_peso")].value = nextValKilo;
            setInputFields([...inputFields]);
          }
        } else {
          e.target.value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "arroba")].value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_valor_peso")].value = "";
          setInputFields([...inputFields]);
        }
      },
      required: false,
      hidden: true,
    },
    {
      name: "gcolote_valor_peso",
      label: "Valor Kilo",
      type: "tel",
      onIonBlur: (e: any) => {
        const value = parseFloat(e.target.value.replace(",", ".").match(/[\d]+/));
        if (!isNaN(value)) {
          const valueArroba = parseFloat((value * 30).toFixed(2));
          const nextValArroba = valueArroba.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
          const nextValKilo = value.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
          const curValKilo = inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_valor_peso")].value;

          if (curValKilo !== nextValKilo) {
            inputFields[inputFields.findIndex((obj) => obj.name === "arroba")].value = nextValArroba;
            inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_valor_peso")].value = nextValKilo;
            setInputFields([...inputFields]);
          }
        } else {
          e.target.value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "arroba")].value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_valor_peso")].value = "";
          setInputFields([...inputFields]);
        }
      },
      required: false,
      hidden: true,
    },
    {
      name: "gcolote_frete",
      label: "Frete TOTAL",
      type: "tel",
      required: true,
      onIonBlur: (e: any) => {
        const value = parseFloat(e.target.value.replace(",", ".").match(/[\d]+/));
        if (!isNaN(value)) {
          const valueLocalized = value.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_frete")].value = valueLocalized;
          setInputFields([...inputFields]);
        } else {
          e.target.value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_frete")].value = "";
          setInputFields([...inputFields]);
        }
      },
    },
    {
      name: "gcolote_custos_extra",
      label: "Outros Custos",
      type: "tel",
      required: true,
      onIonBlur: (e: any) => {
        const value = parseFloat(e.target.value.replace(",", ".").match(/[\d]+/));
        if (!isNaN(value)) {
          const valueLocalized = value.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_custos_extra")].value = valueLocalized;
          setInputFields([...inputFields]);
        } else {
          e.target.value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_custos_extra")].value = "";
          setInputFields([...inputFields]);
        }
      },
    },
    {
      name: "gcolote_gta",
      label: "Nº GTA",
      type: "text",
    },
    {
      name: "gcolote_nota",
      label: "Nº da Nota",
      type: "text",
    },
    {
      name: "gcolote_picareta",
      label: "Corretor de Gado",
      type: "text",
    },
    {
      name: "gcolote_comissao",
      label: "Comissão (%)",
      type: "tel",
      onIonBlur: (e: any) => {
        const value = parseFloat(e.target.value.replace(",", ".").match(/[\d]+/));
        if (!isNaN(value)) {
          const valueLocalized = (value * 0.01).toLocaleString("pt-BR", { style: "percent", minimumFractionDigits: 2 });
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_comissao")].value = valueLocalized;
          setInputFields([...inputFields]);
        } else {
          e.target.value = "";
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_comissao")].value = "";
          setInputFields([...inputFields]);
        }
      },
      required: true,
    },
  ];

  const [inputFields, setInputFields] = useState<InputField[]>(initialInputFieldsPeso);

  const setFieldValue = (value: any, field: string) => {
    modalEncerrarLote[field] = value;
  };

  const getErrors = () => {
    let mutableErrors: any = {};
    inputFields &&
      inputFields.forEach((field) => {
        if (field.required) addFieldRequiredError(modalEncerrarLote, mutableErrors, field.name);
      });

    return mutableErrors;
  };

  useIonViewWillEnter(async () => {
    await setRecords();
  }, []);

  const setRecords = async () => {
    const records = await CarregarLotesEntrada();
    setListRecords(records);
  };

  const loadMoreRecords = async ($event: CustomEvent<void>) => {
    const records = await CarregarMaisLotesEntrada(listRecords.length);

    if (records.length === 0)
    {
      setDisableInfiniteScroll(true);
    }
    else
    {
      setListRecords([...listRecords, ...records]);
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  };

  const handleContinue = (idLote) => {
    history.push("/preencherLoteEntrada/" + idLote);
  };

  const handlePreEncerrarLote = async (lote: LoteEntrada) => {
    if (lote.gcolote_qtd_cabecas! <= 0) {
      history.push("/preencherLoteEntrada/" + lote.id);
    } else {
      inputFields.map((field) => {
        field.value = lote[field.name];
        if (field.name === "gcolote_valor_peso_up" && lote.gcolote_tipo_compra === 2) {
          field.required = true;
          field.hidden = false;
        }
        if (field.name === "gcolote_valor_peso_up" && lote.gcolote_tipo_compra === 1) {
          field.required = false;
          field.hidden = true;
        }

        if ((field.name === "gcolote_peso_gado" || field.name === "arroba" || field.name === "gcolote_valor_peso") && lote.gcolote_tipo_compra === 1) {
          field.required = true;
          field.hidden = false;
        }
        if ((field.name === "gcolote_peso_gado" || field.name === "arroba" || field.name === "gcolote_valor_peso") && lote.gcolote_tipo_compra === 2) {
          field.required = false;
          field.hidden = true;
        }
      });

      setModalEncerrarLote(lote);
      setShowModalEncerrarLote(true);
    }
  };

  const handleEncerrarLote = async (lote: LoteSaida) => {
    dispatch({ type: "SET_LOADING", showLoading: true });
    setErrors([]);
    const loginErrors = getErrors();

    if (!hasProperties(loginErrors)) {
      await EncerrarLoteEntrada(lote);
      const netStatus = await Network.getStatus();
      if (netStatus.connected) {
        await SyncLoteEntrada();
        await syncAnimais();
      }
      await setRecords();

      dispatch({ type: "SET_LOADING", showLoading: false });
      setShowModalEncerrarLote(false);
    } else {
      dispatch({ type: "SET_LOADING", showLoading: false });
      setErrors(loginErrors);
    }
  };

  const syncAllData = async (e) => {
    const netStatus = await Network.getStatus();

    if (netStatus.connected) {
      const syncEntrada = await SyncLoteEntrada();
      await syncAnimais();
      await setRecords();
      if (syncEntrada) {
        dispatch({
          type: "SET_SUCCESSMESSAGE",
          successMessage: "Lotes de Saída sincronizados com SUCESSO.",
        });
        dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: true });
        setBtnAnimated(false);
        dispatch({ type: "SET_LOADING", showLoading: false });
      }
    } else {
      dispatch({
        type: "SET_ERRORMESSAGE",
        errorMessage: "Não foi possível sincronizar os lotes de saída. Você não possui conexão com a internet. Tente Novamente",
      });
      dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
    }
  };
  const menuRightBtns: RightButtonsLoggedPage[] = [
    {
      text: "Novo Lote",
      rightBtnIcon: addSharp,
      click: () => {
        history.push("/gadoEntradaNovo");
      },
    },
    {
      text: "Sincronizar Lotes",
      rightBtnIcon: syncSharp,
      click: syncAllData,
      rightBtnIconAnimated: btnAnimated,
    },
  ];

  return (
    <LoggedPage title="Lotes de Entrada" headerSubText="Lista de Lotes de Entrada criados pelo APP" buttons={menuRightBtns}>
      <IonGrid>
        <IonRow className="ion-align-items-center">
          <IonCol size="12" className="ion-text-center">
            <IonButton
              onClick={(e) => {
                history.push("/gadoEntradaNovo");
              }}
              color="primary"
            >
            <IonIcon src={addSharp} slot="end" />
              Novo Lote
            </IonButton>
          </IonCol>
        </IonRow>
        {listRecords.length > 0 ? (
          listRecords.map((item, index) => {
            return (
              <IonCard key={index}>
                <IonCardHeader>
                  <IonCardTitle className="display_flex card_animal_header_text">
                    Id do Lote:{" "}
                    {item.server_id === 0 ? (
                      <IonText className="texto_vermelho bold_margin_left">Não Sincronizado</IonText>
                    ) : (
                      <IonText className="texto_verde bold_margin_left">
                        <b>{item.server_id}</b>
                      </IonText>
                    )}
                  </IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  <IonGrid>
                    <IonRow className="card_animal_regular_text">
                      Id APP: <b className="ml-10">{item.id}</b>
                    </IonRow>
                    <IonRow className="card_animal_regular_text">
                      Tipo de Compra: <b className="ml-10">{TipoCompra.filter((val) => val.value === item.gcolote_tipo_compra)[0].label}</b>
                    </IonRow>
                    <IonRow className="card_animal_regular_text">
                      Propriedade: <b className="ml-10">{propList && propList.lenght > 0 && propList.filter((val) => val.value === item.gcolote_faz_id_fk)[0].label}</b>
                    </IonRow>
                    <IonRow className="card_animal_regular_text">
                      Confinamento: <b className="ml-10">{confList && confList.lenght > 0 && confList.filter((val) => val.value === item.gcolote_con_id_fk)[0].label}</b>
                    </IonRow>
                    <IonRow className="card_animal_regular_text">
                      Data Chegada: <b className="ml-10">{moment(item.gcolote_data_chegada).locale("pt-br").format("ll")}</b>
                    </IonRow>
                    <IonRow className="card_animal_regular_text">
                      Qtd de Cabeças: <b className="ml-10">{item.gcolote_qtd_cabecas === null || item.gcolote_qtd_cabecas === 0 ? <>-</> : item.gcolote_qtd_cabecas}</b>
                    </IonRow>

                    {item.is_finished ? (
                      <IonRow className="ion-justify-content-center">
                        <IonBadge color="danger">Lote Encerrado</IonBadge>
                      </IonRow>
                    ) : (
                      <>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="12" className="ion-text-center">
                            <IonButton
                              onClick={(e) => {
                                handleContinue(item.id);
                              }}
                            >
                              Incluir Animais
                            </IonButton>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="12" className="ion-text-center">
                            <IonButton
                              onClick={(e) => {
                                handlePreEncerrarLote(item);
                              }}
                              color="secondary"
                            >
                              Enviar Lote
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </>
                    )}
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            );
          })
        ) : (
          <IonRow>
            <IonCol>
              <IonText color="danger">Nenhum Lote Criado</IonText>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>

      <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => loadMoreRecords(e)}>
        <IonInfiniteScrollContent loadingText="Carregando mais dados"></IonInfiniteScrollContent>
      </IonInfiniteScroll>

      <IonModal
        isOpen={showModalEncerrarLote}
        onDidDismiss={() => {
          setModalEncerrarLote({});
          setShowModalEncerrarLote(false);
          setErrors({});
        }}
      >
        <IonContent>
          <IonGrid className="modal_grid_95 ">
            <IonRow className="card_animal_regular_text">
              <IonText className="ion-text-center">
                <p>Preencha os dados Abaixo para encerrar este lote de entrada:</p>
              </IonText>
            </IonRow>

            <Form items={inputFields} errors={errors} setFieldValue={setFieldValue} />
            <IonRow className="ion-text-center">
              <IonCol>
                <IonButton
                  color="primary"
                  onClick={() => {
                    handleEncerrarLote(modalEncerrarLote);
                  }}
                >
                  Enviar
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol>
                <IonButton
                  color="danger"
                  onClick={() => {
                    setModalEncerrarLote({});
                    setShowModalEncerrarLote(false);
                    setErrors({});
                  }}
                >
                  Cancelar
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </LoggedPage>
  );
};

export default GadoLoteEntrada;
