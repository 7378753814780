import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../../App";
import { LoteEntrada } from "../../../models/gado/gadoLoteEntrada/gadoLoteEntrada";
import { DbConnection } from "../DbConnection";

export const EncerrarLoteEntrada = async (lote: LoteEntrada): Promise<boolean> => {
  let success: boolean = false;
  let db = await DbConnection();
  const gcolote_peso_gado = lote.gcolote_peso_gado;
  const gcolote_valor_peso = lote.gcolote_valor_peso ? Number(lote.gcolote_valor_peso.toString().replace(/[^0-9]+/g, "")) / 100 : Number(lote.gcolote_valor_peso_up?.toString().replace(/[^0-9]+/g, "")) / 100;
  const gcolote_frete = Number(lote.gcolote_frete!.toString().replace(/[^0-9]+/g, "")) / 100;
  const gcolote_custos_extra = Number(lote.gcolote_custos_extra!.toString().replace(/[^0-9]+/g, "")) / 100;
  const gcolote_gta = lote.gcolote_gta ? lote.gcolote_gta : "";
  const gcolote_nota = lote.gcolote_nota ? lote.gcolote_nota : "";
  const gcolote_picareta = lote.gcolote_picareta ? lote.gcolote_picareta : "";
  const gcolote_comissao = Number(lote.gcolote_comissao!.toString().replace(/[^0-9]+/g, "")) / 100;
  await db.open();
  let sqlcmd: string = `UPDATE LoteEntrada SET is_finished = 1, 
  gcolote_peso_gado = ${gcolote_peso_gado}, 
  gcolote_valor_peso = ${gcolote_valor_peso}, 
  gcolote_frete = ${gcolote_frete}, 
  gcolote_custos_extra = ${gcolote_custos_extra}, 
  gcolote_gta = '${gcolote_gta}', 
  gcolote_nota = '${gcolote_nota}', 
  gcolote_picareta = '${gcolote_picareta}', 
  gcolote_comissao = ${gcolote_comissao} 
    WHERE id = ${lote.id};`;
  const resp = await db.execute(sqlcmd, false);
  if (lote.id === resp.changes?.lastId!) {
    success = true;
  }
  db.close();
  return success;
};
