import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle } from "@ionic/react";
import { useLocation } from "react-router-dom";
import { apps, bluetooth, colorWandSharp, scaleSharp, syncSharp } from "ionicons/icons";
import "./Menu.css";
import CambraiaLogo from "../assets/images/cambraia_logo.png";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { ReactComponent as CattleIcon } from "../assets/icons/cattleIcon.svg";
import { IsMobile } from "../utils";
import { useEffect } from "react";

interface AppPage {
  url: string;
  callback?: any;
  iconSvg: boolean;
  svgObject: any;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: "Painel",
    url: "/dashboard",
    iosIcon: apps,
    mdIcon: apps,
    iconSvg: false,
    svgObject: undefined,
  },
  {
    title: "Manejo de Gado",
    url: "/dashboardGado",
    iosIcon: "",
    mdIcon: "",
    iconSvg: true,
    svgObject: <CattleIcon />,
  },
  // {
  //   title: "Lotes de Entrada",
  //   url: "/lotesEntrada",
  //   iosIcon: bagAdd,
  //   mdIcon: bagAdd,
  //   iconSvg: false,
  //   svgObject : undefined
  // },
  // {
  //   title: "Cadastrar Gado",
  //   url: "/cadastrarGado",
  //   iosIcon: bagAdd,
  //   mdIcon: bagAdd,
  //   iconSvg: false,
  //   svgObject : undefined
  // },
  {
    title: "Sincronizar",
    url: "/dashboardManejo",
    iosIcon: syncSharp,
    mdIcon: syncSharp,
    iconSvg: false,
    svgObject: undefined,
  },
];

const Menu: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const bastaoConnected = useSelector((state: RootStateOrAny) => state.bastaoConnected);
  const balancaConnected = useSelector((state: RootStateOrAny) => state.balancaConnected);
  const bastaoName = useSelector((state: RootStateOrAny) => state.bastaoConnectedName);
  const balancaName = useSelector((state: RootStateOrAny) => state.balancaConnectedName);
  const showModal = () => {
    dispatch({ type: "SET_MODAL_MANEJO", modalManejo: true });
  };
  useEffect(() => {
    IsMobile() &&
      appPages.push(
        {
          title: "Lista de Manejos",
          url: "",
          callback: showModal,
          iosIcon: colorWandSharp,
          mdIcon: colorWandSharp,
          iconSvg: false,
          svgObject: undefined,
        },
        {
          title: "Bluetooth",
          url: "/bluetooth",
          iosIcon: bluetooth,
          mdIcon: bluetooth,
          iconSvg: false,
          svgObject: undefined,
        }
      );
  }, []);

  function hideMenu(path: string) {
    if (path === "/" || path === "/login" || path === "/register") return true;
  }

  return (
    <>
      {hideMenu(location.pathname) ? (
        <></>
      ) : (
        <IonMenu contentId="main" type="overlay">
          <IonContent>
            <IonList id="inbox-list" className="menu_list">
              {appPages.map((appPage, index) => {
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      className={location.pathname === appPage.url ? "selected" : ""}
                      routerLink={appPage.url != "" ? appPage.url : undefined}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      {appPage.iconSvg ? (
                        <div className="menuIcon" slot="start">
                          {appPage.svgObject}
                        </div>
                      ) : (
                        <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                      )}

                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              })}
            </IonList>
            {IsMobile() && (
              <IonList id="labels-list">
                <h5 className="menu-margin-text">Dispositivos</h5>

                <IonItem lines="none" key="BastaoItem">
                  <IonIcon slot="start" icon={colorWandSharp} className={bastaoConnected ? "verde" : "vermelho"} />
                  <IonLabel>
                    Bastão {bastaoConnected ? "Conectado" : "Desconectado"}
                    <p>{bastaoName}</p>
                  </IonLabel>
                </IonItem>

                <IonItem lines="none" key="BalancaItem">
                  <IonIcon slot="start" icon={scaleSharp} className={balancaConnected ? "verde" : "vermelho"} />
                  <IonLabel>
                    Balança {balancaConnected ? "Conectada" : "Desconectada"}
                    <p>{balancaName}</p>
                  </IonLabel>
                </IonItem>
              </IonList>
            )}
          </IonContent>

          <IonItem lines="none" className="cambraia_bg">
            <img src={CambraiaLogo} className="cambraia_logo" alt="Cambraia Logo" />
          </IonItem>
        </IonMenu>
      )}
    </>
  );
};

export default Menu;
