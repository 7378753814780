import { capSQLiteSet } from "@capacitor-community/sqlite";
import { IonButton, IonGrid, IonItem, IonLabel, IonList, IonListHeader, IonRadio, IonRadioGroup, IonRow, useIonViewWillEnter } from "@ionic/react";
import { chevronBackSharp } from "ionicons/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { AppDispatch, sqlite } from "../../../../App";
import Form, { InputField } from "../../../../components/Forms/Form";
import FormError from "../../../../components/Forms/FormError/FormError";
import LoggedPage, { RightButtonsLoggedPage } from "../../../../components/LoggedPage/LoggedPage";
import { LoteEntrada } from "../../../../models/gado/gadoLoteEntrada/gadoLoteEntrada";
import { LoteSaida } from "../../../../models/gado/gadoLoteSaida/gadoLoteSaida";
import { AdicionarLoteEntrada } from "../../../../services/databaseServices/loteEntrada/AdicionarLoteEntrada";
import { getStorageValue } from "../../../../services/StorageServices";
import { CategoriasList, RacasList } from "../../../../settings/constants/properties";
import { addFieldRequiredError, hasProperties } from "../../../../utils";

const NovoLoteEntrada: React.FC = (routing: any) => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const initState: LoteEntrada = {
    gcolote_faz_id_fk: 0,
    gcolote_con_id_fk: 0,
    gcolote_origem: "",
    gcolote_data_compra: "",
    gcolote_data_chegada: "",
    gcolote_tipo_compra: 0,
    gcolote_raca: 0,
    gcolote_categoria: 0,
    gcolote_qtd_cabecas: 0,
  };
  const [propList, setPropList] = useState<any>([]);
  useIonViewWillEnter(async() => {
    await getStorageValue("ListaPropriedades").then((response) => {
      const resp = response ? JSON.parse(response) : [];
      setPropList(resp)
      inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_faz_id_fk")].selectValues = resp;
    })
    
    setInputFields([...inputFields]);
  },[])
  const [formInfo, setFormInfo] = useState<LoteEntrada>(initState);
  const initialInputFields: InputField[] = [
    {
      name: "gcolote_faz_id_fk",
      label: "Propriedade",
      required: true,
      select: true,
      selectValues: propList,
      onIonBlur:async (e: any) => {
        const value = e.target.value;
        if(value)
        {
          const confString = await getStorageValue("ListaConfinamentos");
          const confList = JSON.parse(confString? confString : "[]");
          formInfo.gcolote_con_id_fk = undefined;
          inputFields[inputFields.findIndex((obj) => obj.name === "gcolote_con_id_fk")].selectValues = confList.filter((obj) => obj.faz_id_fk === value);
          setInputFields([...inputFields]);
        }
      },
      value: formInfo.gcolote_faz_id_fk,
    },
    {
      name: "gcolote_con_id_fk",
      label: "Confinamento",
      required: true,
      select: true,
      selectValues: [],
      value: formInfo.gcolote_con_id_fk,
    },
    {
      name: "gcolote_raca",
      label: "Raça",
      required: true,
      select: true,
      selectValues: RacasList.sort((a, b) => a.label.localeCompare(b.label)),
      value: formInfo.gcolote_raca,
    },
    {
      name: "gcolote_categoria",
      label: "Categoria",
      required: true,
      select: true,
      selectValues: CategoriasList.sort((a, b) => a.label.localeCompare(b.label)),
      value: formInfo.gcolote_categoria,
    },
    {
      name: "gcolote_origem",
      label: "Origem",
      type: "text",
      required: true,
      value: formInfo.gcolote_origem,
    },
    {
      name: "gcolote_data_compra",
      label: "Data de Compra",
      required: true,
      isDateTimerPicker: true,
      value: formInfo.gcolote_data_compra,
    },
    {
      name: "gcolote_data_chegada",
      label: "Data de Chegada",
      required: true,
      isDateTimerPicker: true,
      value: formInfo.gcolote_data_chegada,
    },
  ];

  const [errors, setErrors] = useState<any>(null);
  const [inputFields, setInputFields] = useState<InputField[]>(initialInputFields);
  const setFieldValue = (value: any, field: string) => {
    formInfo[field] = value;
  };
  const getErrors = () => {
    let mutableErrors: any = {};
    inputFields &&
      inputFields.forEach((field) => {
        if (field.required) addFieldRequiredError(formInfo, mutableErrors, field.name);
      });
    if (formInfo["gcolote_tipo_compra"] === 0) {
      addFieldRequiredError(formInfo, mutableErrors, "gcolote_tipo_compra");
    }
    return mutableErrors;
  };

  const handleSubmit = async () => {
    dispatch({ type: "SET_LOADING", showLoading: true });
    const loginErrors = getErrors();
    if (!hasProperties(loginErrors)) {
      setErrors([]);
      dispatch({ type: "SET_LOADING", showLoading: true });
      // ADD TO DB

      await AdicionarLoteEntrada(formInfo)

      //Clear State and hide Loading
      setFormInfo(initState);
      history.replace("/gadoEntrada");
      dispatch({ type: "SET_LOADING", showLoading: false });
    } else {
      dispatch({ type: "SET_LOADING", showLoading: false });
      setErrors(loginErrors);
    }
  };

  const menuRightBtns: RightButtonsLoggedPage[] = [   
    {
      text: "Voltar",
      leftBtnIcon: chevronBackSharp,
      click:  () => {
        history.replace("/gadoEntrada");
      },
    },
  ];
  return (
    <LoggedPage title="Novo Lote de Entrada" headerSubText="Entre com os dados do Lote de Entrada" buttons={menuRightBtns}>
      <IonItem lines="none">
        <IonGrid>
          <IonRow>
            <IonLabel>Tipo de Compra</IonLabel>
          </IonRow>
          <IonRow>
            {errors && errors["gcolote_tipo_compra"] && errors["gcolote_tipo_compra"].message && (
              <div style={errors["gcolote_tipo_compra"] ? { visibility: "visible", height: "15px" } : { visibility: "hidden", height: "15px" }}>
                {errors["gcolote_tipo_compra"] && <FormError errorMessage={errors["gcolote_tipo_compra"].message} rowName={"gcolote_tipo_compra"} />}
              </div>
            )}
          </IonRow>
          <IonRow>
            <IonRadioGroup
              name="Tipo"
              value={formInfo.gcolote_tipo_compra}
              onIonChange={(e) => {
                //setFormInfo(e.detail.value);
                setFieldValue(e.detail.value, "gcolote_tipo_compra");
              }}
            >
              <IonItem>
                <IonLabel>Compra por Peso</IonLabel>
                <IonRadio slot="start" value="1" />
              </IonItem>

              <IonItem>
                <IonLabel>Compra por Cabeça</IonLabel>
                <IonRadio slot="start" value="2" />
              </IonItem>

            </IonRadioGroup>
          </IonRow>
        </IonGrid>
      </IonItem>
      <Form items={inputFields} errors={errors} setFieldValue={setFieldValue} />
      <IonButton className="btnGridFullWidth" onClick={handleSubmit}>
        Criar Lote de Entrada
      </IonButton>
    </LoggedPage>
  );
};

export default NovoLoteEntrada;
