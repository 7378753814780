import { IonGrid, IonRow, IonSkeletonText } from "@ionic/react";
import axios from "axios";
import { useEffect, useState } from "react";
import HorizontalBars from "../../components/charts/horizontalBars/HorizontalBars";
import VerticalBars from "../../components/charts/verticalBars/VerticalBars";
import { ApiConnection } from "../../settings/constants/apiConnection";

interface GraficosDashboardProps {
    property?: string;
  }

const DashboardGraficos: React.FC<GraficosDashboardProps> = ({property = "1"}) => {
  const [cusConfData, setCusConfData] = useState<any>([]);
  const [cusConfCabData, setCusConfCabData] = useState<any>([]);
  const [prevAbateData, setPrevAbateData] = useState<any>([]);

  useEffect(() => {
    refreshData(property);
  },[property])

  const refreshData = async (prop: string) => {
    setCusConfData([]);
    setCusConfCabData([]);
    setPrevAbateData([]);
    
    axios
      .post(ApiConnection.baseUrl + ApiConnection.dashboardConsumoConfinamento, JSON.stringify({ faz_id: prop }), {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (response) => {
        setCusConfData(response.data);
      })
      .catch((error) => {
        return error;
      });

    axios
      .post(ApiConnection.baseUrl + ApiConnection.dashboardConsumoCabecaConfinamento, JSON.stringify({ faz_id: prop }), {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (response) => {
        setCusConfCabData(response.data);
      })
      .catch((error) => {
        return error;
      });

    axios
      .post(ApiConnection.baseUrl + ApiConnection.dashboardPrevisaoAbate, JSON.stringify({ faz_id: prop, semana: ["1", "2"] }), {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (response) => {
        setPrevAbateData(response.data);
      })
      .catch((error) => {
        return error;
      });

  };
  return (
    <IonGrid>
      <IonRow>
        {cusConfData.length === 0 ? (
          <>
            <IonSkeletonText animated style={{ width: "60%" }} />
            <IonSkeletonText animated />
            <IonSkeletonText animated style={{ width: "88%" }} />
          </>
        ) : (
          <>
            <VerticalBars dados={cusConfData} titulo="Consumo Confinamento" legenda="KG por dia" color="rgba(	97, 171, 64, 0.5)" />
          </>
        )}
      </IonRow>
      <IonRow>
        {cusConfCabData.length === 0 ? (
          <>
            <IonSkeletonText animated style={{ width: "60%" }} />
            <IonSkeletonText animated />
            <IonSkeletonText animated style={{ width: "88%" }} />
          </>
        ) : (
          <>
            <VerticalBars dados={cusConfCabData} titulo="Consumo por Cabeça Confinamento" legenda="KG por Cabeça por dia" />
          </>
        )}
      </IonRow>
      <IonRow>
        {prevAbateData.length === 0 ? (
          <>
            <IonSkeletonText animated style={{ width: "60%" }} />
            <IonSkeletonText animated />
            <IonSkeletonText animated style={{ width: "88%" }} />
          </>
        ) : (
          <>
            <HorizontalBars dados={prevAbateData.length > 0 && prevAbateData[0]} titulo="Previsão de Abate 7 Dias" legenda="Cabeças" />
          </>
        )}
      </IonRow>
      <IonRow>
        {prevAbateData.length === 0 ? (
          <>
            <IonSkeletonText animated style={{ width: "60%" }} />
            <IonSkeletonText animated />
            <IonSkeletonText animated style={{ width: "88%" }} />
          </>
        ) : (
          <>
            <HorizontalBars dados={prevAbateData.length > 0 && prevAbateData[1]} titulo="Previsão de Abate 14 Dias" legenda="Cabeças" />
          </>
        )}
      </IonRow>
    </IonGrid>
  );
};

export default DashboardGraficos;
