import { IonButton, IonIcon, IonText, useIonViewWillEnter } from "@ionic/react";
import { useHistory } from "react-router";
import LoggedPage, { RightButtonsLoggedPage } from "../../components/LoggedPage/LoggedPage";
import { ReactComponent as CattleSkull } from "../../assets/icons/cattleSkull.svg";
import { cloudDoneSharp, cloudDownloadSharp, enterSharp, exitSharp, medkitSharp, syncSharp } from "ionicons/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { syncAllData } from "../../services/syncServices/checkSync";
import { checkSync } from "../../services/syncServices/checkSyncNeeded";
import { syncAnimais } from "../../services/syncServices/syncAnimais/SyncAnimais";
import { Network } from "@capacitor/network";
import { SyncLoteEntrada } from "../../services/syncServices/syncLoteEntrada/SyncLoteEntrada";
import { SyncLoteSaida } from "../../services/syncServices/syncLoteSaida/SyncLoteSaida";
import { PropertyData } from "../../services/syncServices/fetchDataFromServer/PropertyData";

const DashboardSincronizar: React.FC = (routing: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [btnAnimated, setBtnAnimated] = useState<boolean>(false);
  const [syncNeeded, setSyncNeeded] = useState<boolean>(false);

  useIonViewWillEnter(async () => {
    dispatch({ type: "SET_LOADING", showLoading: true });
    //CHECK SYNC NEED

    await checkSync()
      .then((response) => {
        response ? setSyncNeeded(true) : setSyncNeeded(false);
        dispatch({ type: "SET_LOADING", showLoading: false });
      })
      .catch((error) => {
        dispatch({
          type: "SET_ERRORMESSAGE",
          errorMessage: error.message,
        });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
        dispatch({ type: "SET_LOADING", showLoading: false });
      });
  }, []);

  const refreshAnimais = async (e) => {
    await Network.getStatus().then(async (network) => {
      if (network.connected) {
        dispatch({ type: "SET_LOADING", showLoading: true });
        await syncAnimais()
          .then((response) => {
            dispatch({
              type: "SET_SUCCESSMESSAGE",
              successMessage: "Animais sincronizados com sucesso.",
            });
            dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: true });
            dispatch({ type: "SET_LOADING", showLoading: false });
          })
          .catch((error) => {
            dispatch({
              type: "SET_ERRORMESSAGE",
              errorMessage: error.message,
            });
            dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
            dispatch({ type: "SET_LOADING", showLoading: false });
          });
      } else {
        dispatch({
          type: "SET_ERRORMESSAGE",
          errorMessage: "Não foi possível sincronizar os animais. Você não possui conexão com a internet. Tente Novamente",
        });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
      }
    });
  };

  const refreshEntrada = async (e) => {
    await Network.getStatus().then(async (network) => {
      if (network.connected) {
        dispatch({ type: "SET_LOADING", showLoading: true });
        await SyncLoteEntrada()
          .then((response) => {
            dispatch({
              type: "SET_SUCCESSMESSAGE",
              successMessage: "Lotes de Entrada sincronizados com sucesso.",
            });
            dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: true });
            dispatch({ type: "SET_LOADING", showLoading: false });
          })
          .catch((error) => {
            dispatch({
              type: "SET_ERRORMESSAGE",
              errorMessage: error.message,
            });
            dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
            dispatch({ type: "SET_LOADING", showLoading: false });
          });
      } else {
        dispatch({
          type: "SET_ERRORMESSAGE",
          errorMessage: "Não foi possível sincronizar os Lotes de Entrada. Você não possui conexão com a internet. Tente Novamente",
        });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
      }
    });
  };

  const refreshSaida = async (e) => {
    await Network.getStatus().then(async (network) => {
      if (network.connected) {
        dispatch({ type: "SET_LOADING", showLoading: true });
        await SyncLoteSaida()
          .then((response) => {
            dispatch({
              type: "SET_SUCCESSMESSAGE",
              successMessage: "Lotes de Saida sincronizados com sucesso.",
            });
            dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: true });
            dispatch({ type: "SET_LOADING", showLoading: false });
          })
          .catch((error) => {
            dispatch({
              type: "SET_ERRORMESSAGE",
              errorMessage: error.message,
            });
            dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
            dispatch({ type: "SET_LOADING", showLoading: false });
          });
      } else {
        dispatch({
          type: "SET_ERRORMESSAGE",
          errorMessage: "Não foi possível sincronizar os Lotes de Saida. Você não possui conexão com a internet. Tente Novamente",
        });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
      }
    });
  };

  const refreshAll = async (e) => {
    await Network.getStatus().then(async (network) => {
      if (network.connected) {
        setBtnAnimated(true);
        dispatch({ type: "SET_LOADING", showLoading: true });
        await syncAllData()
          .then(async () => {
            await checkSync().then((response) => {
              response ? setSyncNeeded(true) : setSyncNeeded(false);
              dispatch({ type: "SET_LOADING", showLoading: false });
            });
            dispatch({
              type: "SET_SUCCESSMESSAGE",
              successMessage: "Dados Sincronizados com SUCESSO.",
            });
            dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: true });
            setBtnAnimated(false);
          })
          .catch((error) => {
            dispatch({
              type: "SET_ERRORMESSAGE",
              errorMessage: error.message,
            });
            dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
            dispatch({ type: "SET_LOADING", showLoading: false });
            setBtnAnimated(false);
          });
      } else {
        dispatch({
          type: "SET_ERRORMESSAGE",
          errorMessage: "Não foi possível sincronizar os dados. Você não possui conexão com a internet. Tente Novamente",
        });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
        setBtnAnimated(false);
      }
    });
  };
  const refreshApp = async (e) => {
    await Network.getStatus().then(async (network) => {
      if (network.connected) {
        setBtnAnimated(true);
        dispatch({ type: "SET_LOADING", showLoading: true });
        await PropertyData()         
          .catch((error) => {
            dispatch({
              type: "SET_ERRORMESSAGE",
              errorMessage: error.message,
            });
            dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
            dispatch({ type: "SET_LOADING", showLoading: false });
            setBtnAnimated(false);
          });

          dispatch({ type: "SET_SUCCESSMESSAGE", successMessage: "Propriedades Sincronizadas com Sucesso" });
          dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: true });
        dispatch({ type: "SET_LOADING", showLoading: false });
      } else {
        dispatch({
          type: "SET_ERRORMESSAGE",
          errorMessage: "Não foi possível sincronizar os dados. Você não possui conexão com a internet. Tente Novamente",
        });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
        setBtnAnimated(false);
      }
    });
  };


  const menuRightBtns: RightButtonsLoggedPage[] = [
    {
      text: "SYNC",
      rightBtnIcon: syncSharp,
      click: refreshAll,
      rightBtnIconAnimated: btnAnimated,
    },
    // {
    //   text: "Lista de Manejos",
    // },
  ];
  return (
    <LoggedPage
      title="Sincronizar Dados"
      headerSubText={`Manejos realizados através do APP.\n Clique no evento desejado para visualizar, ou clique no botão SYNC acima para sincronizar todos dados`}
      buttons={menuRightBtns}
    >
      <div className="btnGridWrapper">
        {syncNeeded ? (
          <IonText color="danger">
            <h5>ATENÇÃO!!! Você tem dados não sincronizados com a nuvem. Clique no botão 'SYNC' para sincronizar agora.</h5>
          </IonText>
        ) : (
          <></>
        )}

        <IonButton className="btnGridFullWidth" color="light" onClick={refreshApp}>
          <div className="btnGridIconContainer">
            <IonIcon className="btnGridIconSize" icon={cloudDownloadSharp} />
          </div>
          <span className="ion-text-left">Sincronizar Propriedades</span>
        </IonButton>
        <IonButton className="btnGridFullWidth" color="light" onClick={refreshAll}>
          <div className="btnGridIconContainer">
            <IonIcon className="btnGridIconSize" icon={cloudDoneSharp} />
          </div>
          <span className="ion-text-left">Sincronizar Todos os Dados</span>
        </IonButton>
        <IonButton className="btnGridFullWidth" color="light" onClick={refreshAnimais}>
          <div className="btnGridIconContainer">
            <IonIcon className="btnGridIconSize" icon={syncSharp} />
          </div>
          <span className="ion-text-left">Sincronizar Animais</span>
        </IonButton>
        <IonButton className="btnGridFullWidth" color="light" onClick={refreshEntrada}>
          <div className="btnGridIconContainer">
            <IonIcon className="btnGridIconSize" icon={enterSharp} />
          </div>
          <span className="ion-text-left">Sincronizar Lotes de Entrada</span>
        </IonButton>
        <IonButton className="btnGridFullWidth" color="light" onClick={refreshSaida}>
          <div className="btnGridIconContainer">
            <IonIcon className="btnGridIconSize" icon={exitSharp} />
          </div>
          <span className="ion-text-left">Sincronizar Lotes de Saída</span>
        </IonButton>
        <IonButton
          className="btnGridFullWidth"
          color="light"
          onClick={() => {
            history.push("/manejosInformaMorte");
          }}
        >
          <div className="btnGridIconContainer">
            <CattleSkull />
          </div>
          <span className="ion-text-left">Informar Morte</span>
        </IonButton>

        <IonButton
          className="btnGridFullWidth"
          color="light"
          onClick={() => {
            history.push("/manejosSanitarios");
          }}
        >
          <div className="btnGridIconContainer">
            <IonIcon className="btnGridIconSize" icon={medkitSharp} />
          </div>
          <span className="ion-text-left">Manejos Sanitários</span>
        </IonButton>
      </div>
    </LoggedPage>
  );
};

export default DashboardSincronizar;
