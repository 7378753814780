import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../../App";
import { LoteSaidaAnimais } from "../../../models/gado/gadoLoteSaida/gadoLoteSaida";

export const VerificarAnimalLoteSaída = async (lote: number, brinco: string): Promise<boolean> => {
  let exist = false;
  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }
  await db.open().then(async () => {
    await db
      .query(`SELECT * FROM LoteSaidaAnimais WHERE lote_id=${lote} and gco_brinco_bastao="${brinco}";`)
      .then((response) => {
        const responseSelect: LoteSaidaAnimais[] = response.values ? response.values : [];
        if (responseSelect.length > 0) {
          exist = true;
        }
      })
      .then(() => {
        db.close();
      });
  });
  return exist;
};
