import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../../App";
import { LoteSaida } from "../../../models/gado/gadoLoteSaida/gadoLoteSaida";
import { DbConnection } from "../DbConnection";


export const CarregarLoteSaida = async (lote: number): Promise<LoteSaida> => {
  let db = await DbConnection();
  await db.open();
  const records = await db.query(`SELECT * FROM LoteSaida WHERE id = '${lote}';`);
  let responseSelect: any = records.values ? records.values : [];
  return responseSelect;
};

export const CarregarLotesSaida = async (): Promise<LoteSaida[]> => {
  let responseSelect: LoteSaida[] = [];
  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }
  await db.open().then(async () => {
    await db.query(`SELECT * FROM LoteSaida order by id desc limit 20;`).then(async (response) => {
      responseSelect = response.values ? response.values : [];
    });
  });
  return responseSelect;
};

export const CarregarMaisLotesSaida = async (quantidade: number): Promise<LoteSaida[]> => {
  let responseSelect: LoteSaida[] = [];
  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }
  await db.open().then(async () => {
    await db.query(`SELECT * FROM LoteSaida order by id desc LIMIT 20 OFFSET ${quantidade};`).then(async (response) => {
      responseSelect = response.values ? response.values : [];
    });
  });
  return responseSelect;
};
