import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonGrid, IonIcon, IonImg, IonRow, IonText, useIonViewWillEnter } from "@ionic/react";
import axios from "axios";
import { useHistory } from "react-router";
import LoggedPage from "../../components/LoggedPage/LoggedPage";
import { ApiConnection } from "../../settings/constants/apiConnection";
import { ReactComponent as CattleSkull } from "../../assets/icons/cattleSkull.svg";
import "./DashboardGado.css";
import { enterSharp, exitSharp, leafSharp, medkitSharp, searchSharp } from "ionicons/icons";

const DashboardGado: React.FC = (routing: any) => {
  const history = useHistory();

  useIonViewWillEnter(async () => {}, []);
  return (
    <LoggedPage title="Manejo de Gado" headerSubText="Escolha a opção desejada">
      
      <div className="btnGridWrapper">
        <IonButton className="btnGridFullWidth" color="light" onClick={() => { history.push('/gadoPesquisar')}}>
          <div className="btnGridIconContainer">
          <IonIcon className="btnGridIconSize" icon={searchSharp} />
          </div>
          <span className="ion-text-left">
          Pesquisar Animal</span>          
        </IonButton>
        <IonButton className="btnGridFullWidth" color="light" onClick={() => { history.push('/gadoEntrada')}}>
          <div className="btnGridIconContainer">
          <IonIcon className="btnGridIconSize" icon={enterSharp} />
          </div>
          <span className="ion-text-left">
          Lote de Entrada</span>          
        </IonButton>
        <IonButton className="btnGridFullWidth" color="light" onClick={() => { history.push('/gadoSaida')}}>
          <div className="btnGridIconContainer">
          <IonIcon className="btnGridIconSize" icon={exitSharp} />
          </div>
          <span className="ion-text-left">
          Lote de Saída</span>          
        </IonButton>
        <IonButton className="btnGridFullWidth" color="light" onClick={() => { history.push('/GadoConsumoConfinamento')}}>
          <div className="btnGridIconContainer">
          <IonIcon className="btnGridIconSize" icon={leafSharp} />
          </div>
          <span className="ion-text-left">
          Consumo do Confinamento</span>          
        </IonButton>


        
        <IonButton className="btnGridFullWidth" color="light" onClick={() => { history.push('/gadoManejoSanitario')}}>
          <div className="btnGridIconContainer">
          <IonIcon className="btnGridIconSize" icon={medkitSharp} />
          </div>
          <span className="ion-text-left">
          Manejo Sanitário</span>          
        </IonButton>
        <IonButton className="btnGridFullWidth" color="light" onClick={() => { history.push('/gadoInformaMorte')}}>
          <div className="btnGridIconContainer">
          <CattleSkull />
          </div>
          <span className="ion-text-left">
          Informar Morte</span>          
        </IonButton>
      </div>
    </LoggedPage>
  );
};

export default DashboardGado;
