import { capSQLiteSet, SQLiteDBConnection } from "@capacitor-community/sqlite";
import axios from "axios";
import { sqlite } from "../../../App";
import { ManejoSanitario } from "../../../models/databaseModels";
import { ApiConnection } from "../../../settings/constants/apiConnection";

export async function SyncSanitario(): Promise<void> {
  let responseSanitario: ManejoSanitario[] = [];
  const data: Array<capSQLiteSet> = [];
  const dataInsert: Array<capSQLiteSet> = [];
  const uri = ApiConnection.baseUrl + ApiConnection.syncManejo;

  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }
  await db.open().then(async () => {
    await db.query("SELECT * FROM ManejoSanitario WHERE is_synced = 0").then((response) => {
      responseSanitario = response.values ? response.values : [];
    });
    if (responseSanitario.length > 0) {
      await axios.post(uri, responseSanitario).then(async (response) => {
        if (response.status === 200) {
          const responseData = response.data.dados.map(async (element, index) => {
            data.push({
              statement: "UPDATE ManejoSanitario set is_synced = 1, server_id = ? where id = ?;",
              values: [element.gco_id, element.id],
            });

            dataInsert.push({
              statement: "INSERT INTO CadGadConfinamentoManejoSanitario (gco_id,pront_data_procedimento,pront_data_registro,pront_id,pront_texto,user_fullname,user_user) VALUES (?,?,?,?,?,?,?);",
              values: [element.gco_id, element.pront_data_procedimento, element.pront_data_registro, element.pront_id, element.pront_texto, element.user_fullname, element.user_user],
            });
          });
          return Promise.all(responseData).then(async () => {
            //Update Data on DataBASE
            await db.executeSet(data).then(async () => {
              db.executeSet(dataInsert).then(() => {
                db.close();
              });
            });
          });
        }
      });
    }
  });
}
