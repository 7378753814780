import axios from "axios";
import { ApiConnection } from "../settings/constants/apiConnection";
import {
  removeStorageValue,
  getStorageValue,
} from "../services/StorageServices";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

const Interceptors = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  axios.interceptors.request.use(
    async function (config) {
      //console.log('[AXIOS INTERCEPT]'+JSON.stringify(config))
      if (config.url && !isOpenRoute(config.url)) {
        const token = await getStorageValue("AppAccessToken").catch(error => {return Promise.reject(error);});
        config.headers.common["Authorization"] = `Bearer ${token}`;
        //config.headers.common["App-Version"] = "1.0";
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (error.response && error.response.status === 400) {
        dispatch({ type: "SET_ERRORMESSAGE", errorMessage: error.response.data.message });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
        dispatch({ type: "SET_LOADING", showLoading: false });
      } else if (error.response && error.response.status === 401) {
        dispatch({ type: "SET_ERRORMESSAGE", errorMessage: error.response.data.message });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
        dispatch({ type: "SET_LOADING", showLoading: false });
        await removeStorageValue("AppAccessToken");
        await removeStorageValue("AppNomeUsuario");
        await removeStorageValue("AppFazenda");
        await removeStorageValue("AppPropriedadeSelecionada");        
        await removeStorageValue("AppPerfil");
        history.replace('/login');
      }else if (error.response && error.response.status === 500) {
        dispatch({ type: "SET_ERRORMESSAGE", errorMessage: error.response.data.message });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
        dispatch({ type: "SET_LOADING", showLoading: false });
      } else if (error.response && error.response.status === 403) {
        history.push("/inactive-subscription");
      } else if (error.response && error.response.status === 503) {
        history.push("/maintenance");
      } else if (error.response && error.response.status === 426) {
        history.push("/upgrade-required");
      } else {
        // actions.setShowLoading(false);
        return Promise.reject(error);
      }
    }
  );
  return null;
};

export default Interceptors;

function isOpenRoute(url: string) {
  return url.includes(ApiConnection.getTokenRoute);
}
