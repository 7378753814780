import { IonModal, IonContent, IonGrid, IonRow, IonText, IonCol, IonItem, IonLabel, IonInput, IonButton } from "@ionic/react";

interface WeightModalProps {
  showWeightModal: boolean;
  setShowWeightModal: any;
  weightModalValue: number;
  setWeightModalValue: any;
  weightModalError: boolean;
  setWeightModalError: any;
  callbackFunction?: any;
}
export const WeightModal: React.FC<WeightModalProps> = ({ showWeightModal, setShowWeightModal, weightModalValue, setWeightModalValue, weightModalError, setWeightModalError, callbackFunction }) => {
  return (
    <IonModal
      isOpen={showWeightModal}
      cssClass="bluetooth-modal"
      onDidDismiss={() => {
        setShowWeightModal(false);
        setWeightModalValue(0);
      }}
    >
      <IonContent>
        <IonGrid className="modal_grid_95">
          <IonRow className="ion-justify-content-center ion-align-items-center card_animal_regular_text">
            <IonText className="ion-text-center">
              <p>O peso da balança está zerado.</p>
              <p>
                Entre com o peso do animal em <b>kilogramas</b>:
              </p>
            </IonText>
          </IonRow>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="stacked">Peso em KG</IonLabel>
                  <IonInput type="number" onIonChange={(e) => setWeightModalValue(parseInt(e.detail.value!, 10))} />
                </IonItem>
              </IonCol>
            </IonRow>
            {weightModalError && (
              <IonRow>
                <IonCol>
                  <IonText color="danger">O peso deve ser um número maior que zero</IonText>
                </IonCol>
              </IonRow>
            )}
            <IonRow>
              <IonCol>
                <IonButton
                  expand="full"
                  onClick={() => {
                    if (!isNaN(+weightModalValue) && weightModalValue > 0) {
                      setShowWeightModal(false);
                      callbackFunction();
                      setWeightModalError(false);
                    } else {
                      setWeightModalError(true);
                    }
                  }}
                >
                  Inlcuir Peso
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};
