import { Network } from "@capacitor/network";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonText,
  useIonViewWillEnter,
} from "@ionic/react";
import { addSharp, syncSharp } from "ionicons/icons";
import "moment/locale/pt-br";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { AppDispatch } from "../../../App";
import LoggedPage, { RightButtonsLoggedPage } from "../../../components/LoggedPage/LoggedPage";
import { CarregarConsumos, CarregarMaisConsumos } from "../../../services/databaseServices/consumoConfinamento/CarregarConsumoConfinamento";
import { getStorageValue } from "../../../services/StorageServices";
import { syncConsumo } from "../../../services/syncServices/syncConsumoConfinamento/SyncConsumoConfinamento";
import { FormConsumoConfinamento } from "./novoRegistro/NovoRegistroConsumoConfinamento";

const GadoConsumoConfinamento: React.FC = (routing: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [listRecords, setListRecords] = useState<FormConsumoConfinamento[]>([]);
  const [confList, setConfList] = useState<any>([]);

  useIonViewWillEnter(async () => {
    await setRecords();
    await getStorageValue("ListaConfinamentos").then((response) => {
      setConfList(response ? JSON.parse(response) : []);
    });
  }, []);

  const setRecords = async () => {
    await CarregarConsumos().then((response) => {
      setListRecords([...response]);
    });
  };

  const loadMoreRecords = async ($event: CustomEvent<void>) => {
    await CarregarMaisConsumos(listRecords.length).then((response) => {
      if (response.length === 0) {
        setDisableInfiniteScroll(true);
      } else {
        setListRecords([...listRecords, ...response]);
      }
    });
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  };

  const syncAllData = async (e) => {
    await Network.getStatus().then(async (network) => {
      if (network.connected) {
        await syncConsumo().then(async () => {
          await setRecords();
        });
      } else {
        dispatch({
          type: "SET_ERRORMESSAGE",
          errorMessage: "Não foi possível sincronizar os registros de consumo de Confinamento. Você não possui conexão com a internet. Tente Novamente",
        });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
      }
    });
  };

  const menuRightBtns: RightButtonsLoggedPage[] = [
    {
      text: "Novo Registro",
      rightBtnIcon: addSharp,
      click: () => {
        history.push("/novoRegistroConsumoConfinamento");
      },
    },
    {
      text: "Sincronizar Registros",
      rightBtnIcon: syncSharp,
      click: syncAllData,
    },
  ];

  return (
    <LoggedPage title="Consumo do Confinamento" headerSubText="Lista de Registrod do Consumo do Confinamento criados pelo APP" buttons={menuRightBtns}>
      <IonGrid>
        <IonRow className="ion-align-items-center">
          <IonCol size="12" className="ion-text-center">
            <IonButton
              onClick={(e) => {
                history.push("/novoRegistroConsumoConfinamento");
              }}
              color="primary"
            >
              <IonIcon src={addSharp} slot="end" />
              Novo Registro
            </IonButton>
          </IonCol>
        </IonRow>
        {listRecords.length > 0 ? (
          listRecords.map((item, index) => {
            return (
              <IonCard key={index}>
                <IonCardHeader>
                  <IonCardTitle className="display_flex card_animal_header_text">
                    Id do Lote:{" "}
                    {item.server_id === 0 ? (
                      <IonText className="texto_vermelho bold_margin_left">Não Sincronizado</IonText>
                    ) : (
                      <IonText className="texto_verde bold_margin_left">
                        <b>{item.server_id}</b>
                      </IonText>
                    )}
                  </IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  <IonGrid>
                    <IonRow className="card_animal_regular_text">
                    Confinamento: <b className="ml-10">{confList && confList.length > 0 && confList.filter(val => val.value === item.con_id)[0].label}</b>
                    </IonRow>
                    <IonRow className="card_animal_regular_text">
                    Quantidade: <b className="ml-10">{item.quantidade} KG</b>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            );
          })
        ) : (
          <IonRow>
            <IonCol>
              <IonText color="danger">Nenhum Registro</IonText>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>

      <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => loadMoreRecords(e)}>
        <IonInfiniteScrollContent loadingText="Carregando mais dados"></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </LoggedPage>
  );
};

export default GadoConsumoConfinamento;
