import {
  IonPage,
  IonContent,
  IonIcon,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonText,
  IonButton,
  IonPopover,
  IonLabel,
  IonItem,
  IonList,
  IonModal,
  IonCol,
} from "@ionic/react";
import { closeSharp, colorWandOutline, ellipsisHorizontalSharp, eyeSharp, stopCircleOutline } from "ionicons/icons";
import React, { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../App";
import { ManejosApp } from "../../models/databaseModels";
import "./LoggedPage.css";

interface SimplePageProps {
  title?: string;
  subtitle?: string;
  headerText?: string;
  headerSubText?: string;
  children: any;
  icon?: any;
  showLogo?: boolean;
  ref?: any;
  buttons?: RightButtonsLoggedPage[];
}

export interface RightButtonsLoggedPage {
  text?: any;
  rightBtn?: any;
  rightBtnIconAnimated?: boolean;
  rightBtnIcon?: any;
  leftBtnIcon?: any;
  click?: any;
}

const LoggedPage: React.FC<SimplePageProps> = ({ title, subtitle, children, ref, showLogo, icon, headerText, headerSubText, buttons }) => {

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const hideBg = useSelector((state: RootStateOrAny) => state.hideBg);
  const showModal = useSelector((state: RootStateOrAny) => state.modalManejo);

  const dispatch = useDispatch();

  const stopScan = async () => {
    dispatch({ type: "SET_HIDEBG", hideBg: false });
    //const result = await BarcodeScanner.stopScan();
  };

  const [showModalManejo, setShowModalManejo] = useState<boolean>(false);
  const [listManejos, setListManejos] = useState<ManejosApp[]>([]);
  const [manejo, setManejo] = useState<ManejosApp[]>([]);

  const loadModalManejos = async () => {
    let db: SQLiteDBConnection;
    if ((await sqlite.isConnection("trackBov")).result) {
      db = await sqlite.retrieveConnection("trackBov");
    } else {
      db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
    }
    
    await db
      .open()
      .then(async () => {
        await db.query(`SELECT * FROM ControleManejosApp group by data;`).then(async (response) => {
          const responseSelect: ManejosApp[] = response.values ? response.values : [];
          setListManejos([...responseSelect]);
        });
      })
      .then(() => {
        db.close();
      });
  };

  const loadManejo = async (date) => {
    let db: SQLiteDBConnection;
    if ((await sqlite.isConnection("trackBov")).result) {
      db = await sqlite.retrieveConnection("trackBov");
    } else {
      db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
    }
    
    await db
      .open()
      .then(async () => {
        await db.query(`SELECT * FROM ControleManejosApp WHERE data = '${date}';`).then(async (response) => {
          const responseSelect: ManejosApp[] = response.values ? response.values : [];
          setManejo([...responseSelect]);
        });
      })
      .then(() => {
        db.close();
      });
  };
  return (
    <IonPage>
      <IonButton color="danger" className={hideBg ? "buttonStopScanQr" : "buttonStopScanQr forceHide"} size="small" onClick={stopScan}>
        <IonIcon icon={stopCircleOutline} slot="start" />
        Parar
      </IonButton>
      <div className={hideBg ? "scan-box" : "scan-box forceHide"} />
      <IonHeader className={hideBg ? "forceHide" : ""}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          {buttons && (
            <>
              {buttons.length > 1 ? (
                <>
                  <IonButtons slot="end" className="toolbarRightBtn">
                    <IonIcon
                      src={popoverState.showPopover ? closeSharp : ellipsisHorizontalSharp}
                      onClick={(e: any) => {
                        setShowPopover({ showPopover: true, event: e });
                      }}
                    />
                  </IonButtons>

                  <IonPopover
                    event={popoverState.event}
                    isOpen={popoverState.showPopover}
                    onWillDismiss={async () => {
                      setShowPopover({ showPopover: false, event: undefined });
                    }}
                  >
                    <IonList>
                      {buttons.map((value, index) => {
                        return (
                          <IonItem
                            key={index}
                            onClick={(e) => {
                              value.click(e);
                              setShowPopover({ showPopover: false, event: undefined });
                            }}
                          >
                            <IonIcon src={value.rightBtnIcon} className={` ${value.rightBtnIconAnimated ? "toolbarRightBtnSpin bold_margin_right" : "bold_margin_right"}`} />
                            <IonLabel>{value.text}</IonLabel>
                          </IonItem>
                        );
                      })}
                      {/* <IonItem
                        onClick={() => {
                          setShowPopover({ showPopover: false, event: undefined });
                          dispatch({ type: "SET_MODAL_MANEJO", modalManejo: true });
                        }}
                      >
                        <IonIcon src={colorWandOutline} className="bold_margin_right" />
                        <IonLabel>Lista de Leituras</IonLabel>
                      </IonItem> */}
                    </IonList>
                  </IonPopover>
                </>
              ) : (
                <>
                  <IonButtons slot="end" className="toolbarRightBtn" onClick={buttons[0].click} >
                    {buttons[0].leftBtnIcon && <IonIcon src={buttons[0].leftBtnIcon} className={`toolbarRightBtnIcon ${buttons[0].rightBtnIconAnimated ? "toolbarRightBtnSpin" : ""}`} />}
                    {buttons[0].text}
                    {buttons[0].rightBtnIcon && <IonIcon src={buttons[0].rightBtnIcon} className={`toolbarRightBtnIcon ${buttons[0].rightBtnIconAnimated ? "toolbarRightBtnSpin" : ""}`} />}
                  </IonButtons>
                </>
              )}
            </>
          )}
          <IonTitle className="ion-text-center">{title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className={hideBg ? "hideBg" : ""} fullscreen>
        {headerText ||
          (headerSubText && (
            <IonGrid>
              <IonRow className="ion-justify-content-start text-row">
                {headerText ? (
                  <IonText>
                    <h2>{headerText}</h2>
                  </IonText>
                ) : (
                  <></>
                )}
                {headerSubText ? (
                  <IonText className="text-wrap">
                    {headerSubText.split("\n").map((ele, index) => (
                      <p key={`p${index}`}>{ele}</p>
                    ))}
                  </IonText>
                ) : (
                  <></>
                )}
              </IonRow>
            </IonGrid>
          ))}
        {children}
      </IonContent>

      <IonModal
        isOpen={showModal}
        onDidPresent={loadModalManejos}
        onDidDismiss={() => {
          dispatch({ type: "SET_MODAL_MANEJO", modalManejo: false });
        }}
      >
        <IonContent>
          <IonToolbar>
            <IonButtons slot="end">
              <IonIcon className="toolbarRightBtnIcon" src={closeSharp} onClick={() => dispatch({ type: "SET_MODAL_MANEJO", modalManejo: false })} />
            </IonButtons>
            <IonTitle>Lista de Leituras Bastão</IonTitle>
          </IonToolbar>
          <IonGrid className="modal_grid_95">
            <IonList>
              {listManejos.length > 0 ? (
                <>
                  {listManejos.map((value, index) => {
                    return (
                      <IonItem key={index}>
                        <IonLabel> {value.data} </IonLabel>
                        <IonIcon
                          slot="end"
                          src={eyeSharp}
                          onClick={() => {
                            loadManejo(value.data);
                            setShowModalManejo(true);
                          }}
                        ></IonIcon>
                      </IonItem>
                    );
                  })}
                </>
              ) : (
                <>
                  <IonText>Nenhum Registro Disponível</IonText>
                </>
              )}
            </IonList>
          </IonGrid>
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={showModalManejo}
        onDidDismiss={() => {
          setShowModalManejo(false);
        }}
      >
        <IonContent>
          <IonToolbar>
            <IonButtons slot="end">
              <IonIcon className="toolbarRightBtnIcon" src={closeSharp} onClick={() => setShowModalManejo(false)} />
            </IonButtons>
            <IonTitle>Leituras do Dia: {manejo[0] && manejo[0].data}</IonTitle>
          </IonToolbar>
          <IonGrid className="modal_grid_95">
            <IonList>
              {manejo.length > 0 && (
                <>
                  {manejo.map((value, index) => {
                    return (
                      <IonItem key={index}>
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                        <IonLabel> Brinco: {value.brinco} </IonLabel>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                        <IonLabel> Peso: {value.peso} Kg</IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonItem>
                    );
                  })}
                </>
              )}
            </IonList>
          </IonGrid>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default LoggedPage;
