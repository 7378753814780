import axios from "axios";
import { ApiConnection } from "../settings/constants/apiConnection";
import { setStorageValue } from "./StorageServices";

export async function checkAccessToken(): Promise<void> {
  const uri = ApiConnection.baseUrl + ApiConnection.checkTokenRoute;
  await axios.get(uri);
}

export async function getAccessToken(email: string, password: string): Promise<any> {
  let state = {
    user: email,
    password: password,
  };
  const uri = ApiConnection.baseUrl + ApiConnection.getTokenRoute;
  return await axios
    .post(uri, JSON.stringify(state), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (response) => {
      if (response && response.status === 200) {
        await setStorageValue("AppAccessToken", response.data.token);
        await setStorageValue("AppNomeUsuario", response.data.nome.split(" ")[0]);
        await setStorageValue("AppFazenda", response.data.faz);
        await setStorageValue("AppPropriedadeSelecionada", response.data.faz);        
        await setStorageValue("AppPerfil", response.data.perfil);
        return response;
      } else {
        return response;
      }
    })
    .catch((error) => {
      return error;
    });
}
