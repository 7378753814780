import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonModal, IonRow, useIonViewWillEnter } from "@ionic/react";
import LoggedPage, { RightButtonsLoggedPage } from "../../../components/LoggedPage/LoggedPage";
import "./SincronizarInformaMorte.css";
import { useState } from "react";
import { cloudDownloadSharp, cloudUploadSharp, eyeOutline, syncSharp } from "ionicons/icons";
import { SQLiteDBConnection } from "@capacitor-community/sqlite";
import { sqlite } from "../../../App";
import { InformarMorte, InformarMorteImagens } from "../../../models/databaseModels";
import { useDispatch } from "react-redux";
import { syncMorteData } from "../../../services/syncServices/syncMorte/SyncMorte";
import { Network } from "@capacitor/network";

const ManejosInformaMorte: React.FC = () => {
  const dispatch = useDispatch();
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [btnAnimated, setBtnAnimated] = useState<boolean>(false);
  const [listRecords, setListRecords] = useState<InformarMorte[]>([]);
  const [modalRecord, setModalRecord] = useState<InformarMorte>();
  const [modalRecordImages, setModalRecordImages] = useState<InformarMorteImagens[]>();
  const [showModal, setShowModal] = useState(false);

  const refreshAll = async (e) => {
    await Network.getStatus().then(async (network) => {
      if (network.connected) {
        setBtnAnimated(true);
        dispatch({ type: "SET_LOADING", showLoading: true });
        await syncMorteData().then(async (response) => {
          await setRecords();
          dispatch({
            type: "SET_SUCCESSMESSAGE",
            successMessage: "Relatório de mortes de animais sincronizados com sucesso.",
          });
          dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: true });
          dispatch({ type: "SET_LOADING", showLoading: false });
          setBtnAnimated(false);
        });
      } else {
        dispatch({
          type: "SET_ERRORMESSAGE",
          errorMessage: "Não foi possível sincronizar os animais. Você não possui conexão com a internet. Tente Novamente",
        });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
      }
    });
  };

  const setRecords = async () =>{
    let db: SQLiteDBConnection;
    if ((await sqlite.isConnection("trackBov")).result) {
      db = await sqlite.retrieveConnection("trackBov");
    } else {
      db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
    }
    await db.open().then(async () => {
      await db.query("SELECT * FROM InformarMorte order by id desc limit 20;").then((response) => {
        const responseSelect: InformarMorte[] = response.values ? response.values : [];
        setListRecords([...responseSelect]);
      }).then(() =>{db.close()});
    });
  }

  useIonViewWillEnter(async () => {
    await setRecords();
  }, []);

  const loadMoreRecords = async ($event: CustomEvent<void>) => {
    let db: SQLiteDBConnection;
    if ((await sqlite.isConnection("trackBov")).result) {
      db = await sqlite.retrieveConnection("trackBov");
    } else {
      db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
    }
    await db.open().then(async () => {
      await db.query("SELECT * FROM InformarMorte order by id desc LIMIT 20 OFFSET " + listRecords.length + ";").then((response) => {
        const responseSelect: InformarMorte[] = response.values ? response.values : [];
        if (responseSelect.length === 0)
        {
          setDisableInfiniteScroll(true);
        }
        setListRecords([...listRecords, ...responseSelect]);
      }).then(() =>{db.close()});
    });

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  };

  const loadDataOpenModal = async (index, id) => {
    setModalRecord(listRecords[index]);
    let db: SQLiteDBConnection;
    if ((await sqlite.isConnection("trackBov")).result) {
      db = await sqlite.retrieveConnection("trackBov");
    } else {
      db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
    }
    await db.open().then(async () => {
      await db.query(`SELECT * FROM InformarMorteImagens where registro_id = ${id};`).then((response) => {
        const responseSelect: InformarMorteImagens[] = response.values ? response.values : [];
        setModalRecordImages([...responseSelect]);
      }).then(() =>{db.close()});
    });

    setShowModal(true);
  };

  const menuRightBtns: RightButtonsLoggedPage[] = [
    {
      text: "Sync",
      rightBtnIcon:syncSharp,
      click:refreshAll,
      rightBtnIconAnimated:btnAnimated
    }
  ];
  return (
    <LoggedPage
      title="Informativo de Mortes"
      headerSubText="Eventos criados para informar morte de gado."
      // rightBtn={true}
      // rightBtnText="Sync"
      // rightBtnIconAnimated={btnAnimated}
      // rightBtnIcon={syncSharp}
      // rightBtnClick={refreshAll}
      buttons={menuRightBtns}
    >
      <p>Total de Registros: {listRecords.length}</p>
      <IonList>
        {listRecords.map((item: InformarMorte, i: number) => {
          return (
            <IonItem key={i}>
              <IonLabel>Id: {item.id}</IonLabel>
              {item.is_synced === 0 ? <IonIcon icon={cloudDownloadSharp} color="danger" slot="end" /> : <IonIcon icon={cloudUploadSharp} color="success" slot="end" />}
              <IonIcon
                icon={eyeOutline}
                slot="end"
                onClick={(e) => {
                  loadDataOpenModal(i, item.id);
                }}
              />
            </IonItem>
          );
        })}
      </IonList>

      <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => loadMoreRecords(e)}>
        <IonInfiniteScrollContent loadingText="Carregando mais dados"></IonInfiniteScrollContent>
      </IonInfiniteScroll>

      <IonModal isOpen={showModal} cssClass="my-custom-class">
        <p>Evento: Informar Morte</p>
        <p>Id: {modalRecord?.id}</p>
        <p>Brinco Bastão: {modalRecord?.gco_brinco_bastao}</p>
        <p>Brinco Manual: {modalRecord?.gco_brinco_manual}</p>
        <p>Motivo: {modalRecord?.motivo}</p>

        <IonGrid>
          <IonRow>
            {modalRecordImages?.map((photo, index) => (
              <IonCol size="6" key={index}>
                <IonImg src={photo.conteudo} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>

        <IonButton onClick={() => setShowModal(false)}>Fechar</IonButton>
      </IonModal>
    </LoggedPage>
  );
};

export default ManejosInformaMorte;
