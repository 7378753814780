import { Network } from "@capacitor/network";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonSlide,
  IonSlides,
  IonText,
  useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import LoggedPage from "../../components/LoggedPage/LoggedPage";
import React from "react";
import { getStorageValue, setStorageValue } from "../../services/StorageServices";
import "./Dashboard.css";
import { ApiConnection } from "../../settings/constants/apiConnection";
import axios from "axios";
import VerticalBars from "../../components/charts/verticalBars/VerticalBars";
import HorizontalBars from "../../components/charts/horizontalBars/HorizontalBars";
import DashboardGraficos from "./DashboardGraficos";
export interface registrosObj {
  brinco: string;
  peso: string;
}
interface GraficoBlocos {
  sucesso: boolean;
  total_cabecas: number;
  total_macho: number;
  total_femea: number;
}

const Dashboard: React.FC = (routing: any) => {
  const [nomeUsuario, setNomeUsuario] = useState<string>("");
  const [perfilUsuario, setPerfilUsuario] = useState<string>("");
  const [propList, setPropList] = useState<any>([]);
  const [property, setProperty] = useState<string>("0");
  const [hasConnection, setHasConnection] = useState<boolean>(false);
  const [graficoBlocos, setGraficoBlocos] = useState<GraficoBlocos>();

  useIonViewWillEnter(async () => {
    await getStorageValue("AppNomeUsuario").then((response) => {
      setNomeUsuario(response ? response : "");
    });

    await getStorageValue("AppPerfil").then((response) => {
      setPerfilUsuario(response ? response : "");
    });

    await getStorageValue("ListaPropriedades").then((response) => {
      setPropList(response ? JSON.parse(response) : []);
    });

    await getStorageValue("AppPropriedadeSelecionada").then((response) => {
      setProperty(response ? response : "");
    });

    await Network.getStatus().then(async (network) => {
      if (network.connected) {
        setHasConnection(true);
      }
    });
  }, []);

  useEffect(() => {
    if (property != "0") {
      Network.getStatus().then(async (network) => {
        if (network.connected) {
          setHasConnection(true);

          axios
            .post(ApiConnection.baseUrl + ApiConnection.dashboardGraficoBlocos, JSON.stringify({ faz_id: property }), {
              headers: { "Content-Type": "application/json" },
            })
            .then(async (response) => {
              setGraficoBlocos(response.data);
            })
            .catch((error) => {
              return error;
            });
        }
      });
    }
  }, [property]);

  const slider = useRef<any>();
  const [activeSlide, setActiveSlide] = useState<any>(0);
  const handleOnChangeSegment = async (e) => {
    slider.current.slideTo(e.target.value);
  };
  const onChangeSlide = async (e) => {
    setActiveSlide(await slider.current.getActiveIndex());
  };
  return (
    <LoggedPage title="Painel">
      <IonGrid>
        <IonRow className="ion-justify-content-start text-row">
          <h2>Olá, {nomeUsuario}</h2>
        </IonRow>
        <IonRow>
          <IonCol>
            <div className="form__item">
              <IonItem lines="none">
                <IonLabel position="floating">
                  <IonText>Escolha sua Propriedade</IonText>
                </IonLabel>
                <IonSelect
                  value={property}
                  disabled={parseInt(perfilUsuario) <= 3 ? true : false}
                  onIonChange={async (e) => {
                    const val = e.detail.value;
                    setProperty(val);
                    await setStorageValue("AppPropriedadeSelecionada", val);
                  }}
                >
                  {propList.map((index) => {
                    return (
                      <IonSelectOption key={index.value} value={index.value.toString()}>
                        {index.label}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            </div>
          </IonCol>
        </IonRow>
        {hasConnection ? (
          <>
            <IonSegment scrollable value={`${activeSlide}`} onIonChange={handleOnChangeSegment}>
              <IonSegmentButton value="0">
                <IonLabel>Resumo</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="1">
                <IonLabel>Gráficos</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="2">
                <IonLabel>Lotação</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            <IonSlides pager={false} onIonSlideDidChange={onChangeSlide} ref={slider}>
              <IonSlide>
                {console.log(graficoBlocos)}
                {graficoBlocos ?? graficoBlocos ? (
                  <>
                    <IonCard>
                      <IonCardContent>
                        <IonGrid>
                          <IonRow>
                            <IonCol size="6" className="ion-text-nowrap ion-padding-end">Total:</IonCol>
                            <IonCol size="6" className="ion-text-left">
                              <IonText> {graficoBlocos.total_cabecas}</IonText>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol size="6" className="ion-text-nowrap ion-padding-end">Machos:</IonCol>
                            <IonCol size="6" className="ion-text-left">
                              <IonText> {graficoBlocos.total_macho}</IonText>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol size="6" className="ion-text-nowrap ion-padding-end">Fêmeas:</IonCol>
                            <IonCol size="6" className="ion-text-left">
                              <IonText> {graficoBlocos.total_femea}</IonText>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCardContent>
                    </IonCard>
                  </>
                ) : (
                  <>
                    <IonSkeletonText animated style={{ width: "60%" }} />
                    <IonSkeletonText animated />
                    <IonSkeletonText animated style={{ width: "88%" }} />
                  </>
                )}
              </IonSlide>
              <IonSlide>
                <DashboardGraficos property={property} />
              </IonSlide>
              <IonSlide>
                <h1>Slide 3</h1>
              </IonSlide>
            </IonSlides>
          </>
        ) : (
          <IonLabel color="danger">Sem conexão com a internet</IonLabel>
        )}
      </IonGrid>
    </LoggedPage>
  );
};

export default Dashboard;
