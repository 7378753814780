import { IonButton, IonContent, IonGrid, IonPage, IonRow, IonText, useIonViewDidEnter } from "@ionic/react";
import { useState } from "react";
import { cloneDeep } from "lodash";
import { useHistory } from "react-router";
import Form, { InputField } from "../../../components/Forms/Form";
import { getAccessToken } from "../../../services/TokenServices";
import { addFieldRequiredError, capitalize, hasProperties } from "../../../utils/utils";

import { ReactComponent as TrackBovLogo } from "../../../assets/images/logo.svg";
import "./Login.css";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../App";
import { getStorageValue, setStorageValue } from "../../../services/StorageServices";
import { PropertyData } from "../../../services/syncServices/fetchDataFromServer/PropertyData";
import { Network } from "@capacitor/network";

interface LoginInfo {
  Email: string;
  Password: string;
}

const Login: React.FC = (routing: any) => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const [errors, setErrors] = useState<any>(null);

  const [loginInfo, setLoginInfo] = useState<LoginInfo>({
    Email: "",
    Password: "",
  });

  const initialInputFields: InputField[] = [
    {
      name: "email",
      label: "Usuário",
      type: "email",
      required: true,
      value: "asd"
    },
    {
      name: "password",
      label: "Senha",
      type: "password",
      required: true,
    },
  ];

  const [inputFields, setInputFields] = useState<InputField[]>(initialInputFields);

  const setFieldValue = (value: any, field: string) => {
    loginInfo[capitalize(field)] = value;
    setLoginInfo({ ...loginInfo,
    });
    inputFields[inputFields.findIndex((obj) => obj.name === field)].value = value;
    setInputFields([...inputFields]);

  };

  useIonViewDidEnter(async () => {
    await getStorageValue("Username").then((response) => {
      const resp = response ? response : "";
    inputFields[inputFields.findIndex((obj) => obj.name === "email")].value = resp;
    setInputFields([...inputFields]);
    });
    await getStorageValue("Password").then((response) => {
      const resp = response ? response : "";
      inputFields[inputFields.findIndex((obj) => obj.name === "password")].value = resp;
      setInputFields([...inputFields]);
    });
  }, []);

  //const handleLogin = e => history.replace('/dashboard') ;

  const handleLogin = async () => {
    await Network.getStatus().then(async (network) => {
      if (network.connected) {
        dispatch({ type: "SET_LOADING", showLoading: true });
        const loginErrors = getErrors();
        if (!hasProperties(loginErrors)) {
          setErrors([]);
          getAccessToken(loginInfo.Email, loginInfo.Password)
            .then(async (response) => {
              if (response && response.status == 200) {
                await PropertyData();
                await setStorageValue("Username", loginInfo.Email);
                await setStorageValue("Password", loginInfo.Password);
                setTimeout(() => {
                  history.replace("/dashboard");
                  dispatch({ type: "SET_LOADING", showLoading: false });
                }, 3000);
              }
            })
            .catch((error) => {
              dispatch({
                type: "SET_ERRORMESSAGE",
                errorMessage: error.message,
              });
              dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
              dispatch({ type: "SET_LOADING", showLoading: false });
            });
        } else {
          dispatch({ type: "SET_LOADING", showLoading: false });
          setErrors(loginErrors);
        }
      } else {
        dispatch({
          type: "SET_ALERTMESSAGE",
          alertMessage: "Sem conexão com a internet.",
        });
        dispatch({ type: "SET_SHOWALERTMESSAGE", showAlert: true });
      }
    });
  };

  const getErrors = () => {
    let mutableErrors: any = {};
    inputFields &&
      inputFields.forEach((field) => {
        if (field.required) addFieldRequiredError(loginInfo, mutableErrors, field.name);
      });

    return mutableErrors;
  };

  const togglePassword = () => {
    const mutableInputFields = cloneDeep(inputFields);
    mutableInputFields.forEach((input) => {
      if (input.label === "Senha") {
        input.type = input.type === "password" ? "text" : "password";
      }
    });
    setInputFields(mutableInputFields);
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid className="ion-align-items-center login-imgage-grid">
          <IonRow className="ion-justify-content-center login-header">
            <IonText>TrackBOV</IonText>
          </IonRow>
          <IonRow className="ion-text-center loginLogo">
            <TrackBovLogo />
          </IonRow>
          <IonRow className="ion-justify-content-center login-sub-header">
            <IonText>Pecuária de Precisão</IonText>
          </IonRow>
        </IonGrid>

        <div className="login-container ion-text-center">
          <h2>Entrar</h2>
          <Form items={inputFields} errors={errors} setFieldValue={setFieldValue} />
          <IonText slot="end" color="secondary" className="login__forgot-password ion-justify-content-center"></IonText>
          <div className="login__enter">
            <IonButton className="login__enter-button" onClick={handleLogin}>
              Entrar
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
