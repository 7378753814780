import { capSQLiteSet } from "@capacitor-community/sqlite";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../../App";
import { LoteEntradaAnimais } from "../../../models/gado/gadoLoteEntrada/gadoLoteEntrada";
import { LoteSaidaAnimais } from "../../../models/gado/gadoLoteSaida/gadoLoteSaida";
import { AtualizarCabecasLoteEntrada } from "./AtualizarCabecasLoteEntrada";

export const AdicionarAnimalLoteEntrada = async (animal: LoteEntradaAnimais): Promise<number> => {
  let lastId = 0;
  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }
  await db.open().then(async () => {
    const data: Array<capSQLiteSet> = [
      {
        statement: "INSERT INTO LoteEntradaAnimais ( lote_id, gco_brinco_bastao, gco_brinco_manual, gco_peso_balanca, gco_peso_manual, gco_marca, gco_raca, gco_tipo, gco_con_id_fk) VALUES (?,?,?,?,?,?,?,?,?);",
        values: [animal.lote_id, animal.gco_brinco_bastao, animal.gco_brinco_manual, animal.gco_peso_balanca, animal.gco_peso_manual, animal.gco_marca, animal.gco_raca, animal.gco_tipo, animal.gco_con_id_fk],
      },
    ];

    // Add Data to DataBASE
    await db.executeSet(data).then((object) => {
      db.close();
      lastId = object.changes?.lastId!;
    });
  });
  await AtualizarCabecasLoteEntrada(animal.lote_id!)
  return lastId;
};
