import { capSQLiteSet, SQLiteDBConnection } from "@capacitor-community/sqlite";
import axios from "axios";
import { sqlite } from "../../../App";
import { InformarMorte, InformarMorteImagens } from "../../../models/databaseModels";
import { ApiConnection } from "../../../settings/constants/apiConnection";

export async function syncMorteData(): Promise<void> {
  let responseMorte: InformarMorte[] = [];

  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }
  await db.open().then(async () => {
    await db.query("SELECT * FROM InformarMorte WHERE is_synced = 0").then((response) => {
      responseMorte = response.values ? response.values : [];
    });

    const requests = responseMorte.map(async (element, index) => {
      await db.query(`SELECT * FROM InformarMorteImagens where registro_id = ${element.id};`).then((response) => {
        let responseMorteImagens: InformarMorteImagens[] = response.values ? response.values : [];
        element.imagens = [];
        element.imagens.push(...responseMorteImagens);
      });
    });

    // Wait for all requests, and then setState
    return Promise.all(requests).then(async () => {
      const uri = ApiConnection.baseUrl + ApiConnection.syncMorte;
      const data: Array<capSQLiteSet> = [];
      if (responseMorte.length > 0) {
        await axios.post(uri, responseMorte).then(async (response) => {
          if (response.status === 200) {
            const responseData = response.data.retorno.map(async (element, index) => {
              const reqId = element.id_requisicao;
              const gco_id = element.gco_id;
              data.push({
                statement: "UPDATE InformarMorte set is_synced = 1, server_id = ? where id = ?;",
                values: [gco_id, reqId],
              });
              data.push({
                statement: "UPDATE InformarMorteImagens set is_synced = 1 where registro_id = ?;",
                values: [reqId],
              });
            });
            return Promise.all(responseData).then(async () => {
              // Update Data on DataBASE
              await db.executeSet(data).then(() =>{db.close()});
            });
          }
        });
      }
    });
  });
}
