import { Storage } from "@capacitor/storage";

export async function setStorageValue(key: string, value: string): Promise<void> {
  await Storage.remove({ key });
  await Storage.set({ key, value });
}

export async function removeStorageValue(key: string): Promise<void> {
  await Storage.remove({ key });
}

export async function getStorageValue(key: string): Promise<string | null> {
  return await (await Storage.get({ key })).value;
}
