
import { PropertyData } from "./fetchDataFromServer/PropertyData";
import { syncAnimais } from "./syncAnimais/SyncAnimais";
import { SyncLoteEntrada } from "./syncLoteEntrada/SyncLoteEntrada";
import { SyncLoteSaida } from "./syncLoteSaida/SyncLoteSaida";
import { syncMorteData } from "./syncMorte/SyncMorte";
import { SyncSanitario } from "./syncSanitario/SyncSanitario";

export async function syncAllData(): Promise<void> {
    await PropertyData();
    await syncAnimais();
    await SyncLoteEntrada();
    await SyncLoteSaida();
    await syncMorteData();
    await SyncSanitario();
}
