import { capSQLiteSet } from "@capacitor-community/sqlite";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../../App";
import { LoteEntrada, LoteEntradaAnimais } from "../../../models/gado/gadoLoteEntrada/gadoLoteEntrada";
import { LoteSaidaAnimais } from "../../../models/gado/gadoLoteSaida/gadoLoteSaida";
import { DbConnection } from "../DbConnection";
import { AtualizarCabecasLoteEntrada } from "./AtualizarCabecasLoteEntrada";

export const AdicionarLoteEntrada = async (lote: LoteEntrada): Promise<void> => {
  let db = await DbConnection();

  await db.open().then(async () => {
    const data: Array<capSQLiteSet> = [
      {
        statement:
          "INSERT INTO LoteEntrada ( is_finished, is_synced, server_id, gcolote_categoria, gcolote_raca, gcolote_data_compra, gcolote_data_chegada, gcolote_origem, gcolote_con_id_fk, gcolote_faz_id_fk, gcolote_tipo_compra) VALUES (?,?,?,?,?,?,?,?,?,?,?);",
        values: [
          0,
          0,
          0,
          lote.gcolote_categoria,
          lote.gcolote_raca,
          lote.gcolote_data_compra,
          lote.gcolote_data_chegada,
          lote.gcolote_origem,
          lote.gcolote_con_id_fk,
          lote.gcolote_faz_id_fk,
          lote.gcolote_tipo_compra,
        ],
      },
    ];

    // Add Data to DataBASE
    await db.executeSet(data).then((object) => {
      db.close();
    });
  });
};
