import { LoteEntrada } from "../../../models/gado/gadoLoteEntrada/gadoLoteEntrada";
import { FormConsumoConfinamento } from "../../../pages/gado/gadoConsumoConfinamento/novoRegistro/NovoRegistroConsumoConfinamento";
import { DbConnection } from "../DbConnection";

export const CarregarConsumo = async (registro: number): Promise<FormConsumoConfinamento> => {  
  let db = await DbConnection();
  await db.open();
  const records = await db.query(`SELECT * FROM CustoConfinamentos WHERE id = '${registro}';`);
  let responseSelect: any = records.values ? records.values : [];
  return responseSelect;
};

export const CarregarConsumos = async (): Promise<FormConsumoConfinamento[]> => {
  let db = await DbConnection();
  await db.open();
  const records = await db.query("SELECT * FROM CustoConfinamentos order by id desc limit 20;");
  db.close();
  return records.values ? records.values : [];
};

export const CarregarMaisConsumos = async (offset: number): Promise<FormConsumoConfinamento[]> => {
  let db = await DbConnection();
  await db.open();
  const records = await db.query("SELECT * FROM CustoConfinamentos order by id desc LIMIT 20 OFFSET " + offset + ";");
  db.close();
  return records.values ? records.values : [];
};
