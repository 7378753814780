export const ApiConnection: any = {
    baseUrl: 'https://www.cambraiadev.com.br/Api/',
    //baseUrl: 'https://www.trackbov.com.br/Api/',
    getTokenRoute: 'Auth/GetToken',
    checkTokenRoute: 'Auth/CheckToken',

    appSyncFazendas: 'Propriedade/ListarPropriedades',
    appSyncConfinamentos: 'Propriedade/ListarConfinamentos',
    appSyncMarcas: 'Propriedade/ListarMarcas',
    appSyncCategorias: 'Gado/ListarCategorias',
    appSyncRacas: 'Gado/ListarRacas',
    appSyncTipoCompra: 'Lote/ListarTipoCompra',
    appSyncRacoes: '/Produto/listarRacao',
    
    dashboardConsumoConfinamento : 'Dashboard/GraficoConsumoConfinamento',
    dashboardConsumoCabecaConfinamento : 'Dashboard/GraficoConsumoCabecaConfinamento',
    dashboardPrevisaoAbate : 'Dashboard/GraficoPrevisaoAbate',
    dashboardGraficoBlocos : 'Dashboard/GraficoBlocos',

    

    syncAnimais: 'Gado/ListarGadoAtivo',
    syncMorte: 'Gado/InformaMorte',
    syncManejo: 'Gado/GeraProntuario',
    syncLoteEntrada: 'Lote/EnviarLote',
    syncLoteSaida: 'Lote/EnviarLoteSaida',
    syncPesagem: 'Gado/Pesagem',
    syncCusConfinamento : 'cusConfinamento/insereConsumo'
};
