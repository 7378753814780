import { IonItem, IonLabel, IonInput, IonIcon, IonTextarea, IonText, IonSelect, IonSelectOption, IonDatetime } from "@ionic/react";
import React from "react";
import FormError from "./FormError/FormError";
import "./Form.css";
import { eye, eyeOff } from "ionicons/icons";
import { Keyboard } from '@capacitor/keyboard';

interface FormProps {
  items: InputField[];
  errors?: any[];
  setFieldValue?: Function;
  onBlurFunc?: Function;
  togglePassword?: any;
}
export interface selectValues {
  value: any;
  label: string;
}

export interface InputField {
  name: string;
  rows?: number;
  label: string;
  type?: "email" | "password" | "text" | "number" | "textArea" | "tel";
  hidden?: boolean;
  value?: any;
  select?: boolean;
  selectValues?: selectValues[];
  selectValueLabel?: boolean;
  disabled?: boolean;
  debounce?: number;
  onIonBlur?: any;
  required?: boolean;
  isDateTimerPicker?: boolean;
}

const Form: React.FC<FormProps> = ({ items, errors, setFieldValue, onBlurFunc, togglePassword }) => {
  return (
    <>
      {items.map((item) => {
        const hasErrors = errors && errors[item.name] && errors[item.name].message;
        const inputProperties = {};
        if (!!item.value) {
          inputProperties["value"] = item.value;
        }
        if (!!item.disabled) {
          inputProperties["disabled"] = item.disabled;
        }

        if (!!item.debounce) {
          inputProperties["debounce"] = item.debounce;
        }

        if (!!item.onIonBlur) {
          inputProperties["onIonBlur"] = item.onIonBlur;
        }

        return (
           !!!item.hidden && 
          <div key={item.label} className="form__item">
            <IonItem lines="none" className={`${hasErrors ? "has-errors" : ""}`}>
              <IonLabel position="floating">
                <IonText id={item.label}>{item.label}</IonText>
              </IonLabel>
              {item.isDateTimerPicker ? (
                <IonDatetime
                  monthNames={["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]}
                  monthShortNames={["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]}
                  cancelText="Cancelar"
                  doneText="Selecionar"
                  displayFormat="DD MMM YYYY"
                  onIonChange={(e) => {
                    setFieldValue && setFieldValue(e.detail.value, item.name);
                  }}
                  onIonBlur={(e) => {
                    onBlurFunc && onBlurFunc(e, item.name);
                  }}
                  {...inputProperties}
                ></IonDatetime>
              ) : 
              item.select ? (
                <IonSelect
                  onIonChange={(e) => {
                    setFieldValue && setFieldValue(e.detail.value!, item.name);
                  }}
                  onIonBlur={(e) => {
                    onBlurFunc && onBlurFunc(e, item.name);
                  }}
                  cancelText="Cancelar"
                  {...inputProperties}
                >
                  {item.selectValues?.length === 0 && (
                      <IonSelectOption disabled={true} value="0">
                        Selecione a opção anterior
                      </IonSelectOption>
                  )}
                  {item.selectValues?.map((index) => {
                    return (
                      <IonSelectOption key={index.value} value={item.selectValueLabel ? index.label : index.value}>
                        {index.label}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              ) : item.type === "textArea" ? (
                <IonTextarea
                  clearOnEdit
                  autofocus
                  onIonChange={(e) => {
                    setFieldValue && setFieldValue(e.detail.value!, item.name);
                  }}
                  onIonBlur={(e) => {
                    onBlurFunc && onBlurFunc(e, item.name);
                  }}
                  rows={item?.rows || 5}
                  {...inputProperties}
                  onKeyPress ={(e) => {
                    if (e.key === "Enter") {
                      e.currentTarget.setBlur();
                      Keyboard.hide();                      
                    }
                  }}                  
                  enterkeyhint="enter"
                />
              ) : (
                
                <IonInput
                  type={item.type}
                  onIonChange={(e) => {
                    setFieldValue && setFieldValue(e.detail.value!, item.name);
                  }}
                  onIonBlur={(e) => {
                    onBlurFunc && onBlurFunc(e, item.name);
                  }}
                  {...inputProperties}
                  onKeyPress ={(e) => {
                    if (e.key === "Enter") {
                      e.currentTarget.setBlur();
                      Keyboard.hide();                      
                    }
                  }}                  
                  enterkeyhint="enter"
                />
              )
              }
              {}
              {item.label === "Senha" && togglePassword && <IonIcon icon={item.type === "password" ? eyeOff : eye} slot="end" onClick={togglePassword} />}
            </IonItem>
            <div style={hasErrors ? { visibility: "visible", height: "15px" } : { visibility: "hidden", height: "15px" }}>
              {hasErrors && <FormError errorMessage={errors[item.name].message} rowName={item.name} />}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Form;
