import { IonText } from "@ionic/react";
import React from "react";
import "./GeneralError.css";

export interface FormErrorProps {
  hasError: boolean;
  errorMessage: string;
  rowName: string;
}

const GeneralError = ({ hasError, errorMessage, rowName }: FormErrorProps) => {
  return (
    <IonText color="danger">
      <small>{errorMessage}</small>
    </IonText>
  );
};

export default GeneralError;
