import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  IonButton,
  IonGrid,
  IonRow,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonModal,
} from "@ionic/react";
import "./bluetooth.css";
import { useEffect, useState } from "react";
import { IsMobile } from "../../utils";
import bluetoothSerial from "cordova-plugin-bluetooth-serial";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { bluetoothSharp, colorWandOutline, scaleOutline, settingsSharp } from "ionicons/icons";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../App";
import moment from "moment";
import 'moment/locale/pt-br'
import { ManejosApp } from "../../models/databaseModels";

interface BluetoothDevice {
  name: string;
  id: string;
  address: string;
  class: number;
}

const BluetoothConfig: React.FC = () => {
  const initial_data = [];
  const dispatch = useDispatch();

  const [balancaConnectedAux, setBalancaConnectedAux] = useState<boolean>(false);
  const [listPaired, setListPaired] = useState(initial_data);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSelectTypeError, setShowSelectTypeError] = useState<boolean>(false);
  const [selectedDeviceType, setSelectedDeviceType] = useState<string>();
  const [selectedDeviceAddress, setSelectedDeviceAddress] = useState<string>();
  const [selectedDeviceName, setSelectedDeviceName] = useState<string>();
  const bastaoConnected = useSelector((state: RootStateOrAny) => state.bastaoConnected);
  const bastaoConnectedAddress = useSelector((state: RootStateOrAny) => state.bastaoConnectedAddress);
  const balancaConnected = useSelector((state: RootStateOrAny) => state.balancaConnected);
  const balancaConnectedAddress = useSelector((state: RootStateOrAny) => state.balancaConnectedAddress);
  const balancaValue = useSelector((state: RootStateOrAny) => state.balancaValue);

  const populateList = () => {
    if (IsMobile()) {
      bluetoothSerial.list(
        (response) => {
          setListPaired(response);
        },
        (error) => {
          alert(error);
        }
      );
    }
  };

  useEffect(() => {
    populateList();
  }, []);

  const refreshList = () => {
    dispatch({ type: "SET_LOADING", showLoading: true });
    populateList();
    dispatch({ type: "SET_LOADING", showLoading: false });
  };

  const openBluetooth = () => {
    bluetoothSerial.showBluetoothSettings(
      () => {},
      (failure) => {
        alert(failure);
      }
    );
  };

  const checkThenConnect = () => {
    if (!bastaoConnected || !balancaConnected) connectBluetooth();
  };

  const connectBluetooth = () => {
    setShowModal(false);
    dispatch({ type: "SET_LOADING", showLoading: true });

    if (!selectedDeviceAddress || !selectedDeviceType) {
      dispatch({ type: "SET_ERRORMESSAGE", errorMessage: "Erro ao conectar dispositivo. Tente novamente" });
      dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
      dispatch({ type: "SET_LOADING", showLoading: false });
    } else {
      if (selectedDeviceType === "stick") {
        bluetoothSerial.connect(
          selectedDeviceAddress,
          () => {
            dispatch({ type: "SET_BASTAO_STATE", bastaoConnected: true });
            dispatch({ type: "SET_BASTAO_ADDRESS", bastaoConnectedAddress: selectedDeviceAddress });
            dispatch({ type: "SET_BASTAO_NAME", bastaoConnectedName: selectedDeviceName });
            dispatch({ type: "SET_SUCCESSMESSAGE", successMessage: "Bastão Conectado com Sucesso." });
            dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: true });
            dispatch({ type: "SET_LOADING", showLoading: false });
          },
          () => {
            dispatch({ type: "SET_BASTAO_STATE", bastaoConnected: false });
            dispatch({ type: "SET_BASTAO_ADDRESS", bastaoConnectedAddress: "" });
            dispatch({ type: "SET_BASTAO_NAME", bastaoConnectedName: "" });
            dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: "" });
            dispatch({ type: "SET_LOADING", showLoading: false });
            dispatch({ type: "SET_ERRORMESSAGE", errorMessage: "Bastão de leitura foi Desconectado" });
            dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
            return false;
          }
        );

        bluetoothSerial.subscribe(
          "\n",
          async (success) => {
            const stickValue = success.replace("\r\n", "").replace(/\s/g, "");
            dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: stickValue });

            let db: SQLiteDBConnection;
            if ((await sqlite.isConnection("trackBov")).result) {
              db = await sqlite.retrieveConnection("trackBov");
            } else {
              db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
            }
            const data = moment(new Date()).locale("pt-br").format("l");
            await db.open().then(async () => {
              await db.query(`SELECT * FROM ControleManejosApp WHERE brinco = '${stickValue}' and data = '${data}';`).then(async (response) => {
                const responseSelect: ManejosApp[] = response.values ? response.values : [];
                if (responseSelect.length > 0) {
                  if(balancaValue != 0 && balancaValue != "")
                  {
                    let sqlcmd: string = `UPDATE ControleManejosApp SET peso = '${balancaValue}' WHERE brinco = '${stickValue}' and data = '${data}';`;
                    await db.execute(sqlcmd, false);
                  }
                }
                else{
                  let sqlcmd: string = `INSERT INTO ControleManejosApp (brinco,peso,data) VALUES ('${stickValue}','${balancaValue}','${data}');`;
                  await db.execute(sqlcmd, false);
                }
              }).then(() => {
                db.close();
              });;
            })
          },
          (error) => {
            dispatch({ type: "SET_ERRORMESSAGE", errorMessage: "Falha de Comunicação com o Bastão de Leitura" });
            dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
          },
          selectedDeviceAddress
        );
      }
      if (selectedDeviceType === "scale") {
        bluetoothSerial.connect(
          selectedDeviceAddress,
          () => {
            setBalancaConnectedAux(true);
            dispatch({ type: "SET_BALANCA_STATE", balancaConnected: true });
            dispatch({ type: "SET_BALANCA_ADDRESS", balancaConnectedAddress: selectedDeviceAddress });
            dispatch({ type: "SET_BALANCA_NAME", balancaConnectedName: selectedDeviceName });
            dispatch({ type: "SET_LOADING", showLoading: false });
            dispatch({ type: "SET_SUCCESSMESSAGE", successMessage: "Balança Conectado com Sucesso." });
            dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: true });
            writeToScale(selectedDeviceAddress);
          },
          () => {
            setBalancaConnectedAux(false);
            dispatch({ type: "SET_BALANCA_ADDRESS", balancaConnectedAddress: "" });
            dispatch({ type: "SET_BALANCA_NAME", balancaConnectedName: "" });
            dispatch({ type: "SET_BALANCA_STATE", balancaConnected: false });
            dispatch({ type: "SET_BALANCA_VALUE", balancaValue: 0 });
            dispatch({ type: "SET_BALANCA_SIGNAL", balancaSignal: "" });
            dispatch({ type: "SET_BALANCA_STABLE", balancaStable: "" });
            dispatch({ type: "SET_ERRORMESSAGE", errorMessage: "Erro ao conectar dispositivo. Tente novamente" });
            dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
            dispatch({ type: "SET_LOADING", showLoading: false });
          }
        );

        bluetoothSerial.subscribe(
          "\n",
          (success) => {
            //+0048.0;Z;
            if (success !== "erro") {
              const value = success.replace(/\D/g, "") / 10;
              const signal = success.slice(0, 1);
              const stable = success.split(";")[1];
              dispatch({ type: "SET_BALANCA_VALUE", balancaValue: value });
              dispatch({ type: "SET_BALANCA_STABLE", balancaStable: stable });
              dispatch({ type: "SET_BALANCA_SIGNAL", balancaSignal: signal });
            }
          },
          (error) => {
            dispatch({ type: "SET_ERRORMESSAGE", errorMessage: "Falha de Comunicação com a Balança." });
            dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
          },
          selectedDeviceAddress
        );
      }

      setSelectedDeviceAddress("");
      setSelectedDeviceType("");
    }
  };

  const writeToScale = (mac) => {
    var data = new Uint8Array(6);
    data[0] = 0x3b;
    data[1] = 0x70;
    data[2] = 0x65;
    data[3] = 0x73;
    data[4] = 0x6f;
    data[5] = 0x0d;
    bluetoothSerial.write(
      data,
      () => {},
      () => {},
      mac
    );
    setTimeout(function () {
      writeToScale(mac);
    }, 500);
  };

  const disconnectDevices = () => {
    bluetoothSerial.unsubscribe(
      () => {},
      () => {},
      bastaoConnectedAddress
    );
    bluetoothSerial.unsubscribe(
      () => {},
      () => {},
      balancaConnectedAddress
    );
    bluetoothSerial.disconnect(
      () => {},
      () => {},
      bastaoConnectedAddress
    );
    bluetoothSerial.disconnect(
      () => {},
      () => {},
      balancaConnectedAddress
    );
    dispatch({ type: "SET_BASTAO_ADDRESS", bastaoConnectedAddress: "" });
    dispatch({ type: "SET_BASTAO_STATE", bastaoConnected: false });
    dispatch({ type: "SET_BASTAO_NAME", bastaoConnectedName: "" });
    dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: "" });
    dispatch({ type: "SET_BALANCA_ADDRESS", balancaConnectedAddress: "" });
    dispatch({ type: "SET_BALANCA_STATE", balancaConnected: false });
    dispatch({ type: "SET_BALANCA_VALUE", balancaValue: 0 });
    dispatch({ type: "SET_BALANCA_SIGNAL", balancaSignal: "" });
    dispatch({ type: "SET_BALANCA_STABLE", balancaStable: "" });
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Bluetooth</IonTitle>
          <IonButtons slot="end" onClick={openBluetooth}>
            <IonIcon icon={bluetoothSharp} />
            <IonIcon icon={settingsSharp} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonModal isOpen={showModal} cssClass="bluetooth-modal" backdropDismiss={false}>
        <IonRow className="ion-justify-content-center ion-align-items-center bluetooth-row__padding-top">
          <IonText className="ion-text-center">
            <h3>Conectar {selectedDeviceName}</h3>
            <h5>Qual o tipo deste dispositivo?</h5>
          </IonText>
        </IonRow>
        <IonSegment
          onIonChange={(e) => {
            setSelectedDeviceType(e.detail.value);
            setShowSelectTypeError(false);
          }}
        >
          <IonSegmentButton disabled={bastaoConnected} value="stick">
            <IonIcon icon={colorWandOutline} />
            <IonLabel>Bastão</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton disabled={balancaConnected} value="scale">
            <IonIcon icon={scaleOutline} />
            <IonLabel>Balança</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <IonRow className={showSelectTypeError ? "show ion-text-center" : "hide ion-text-center"}>
          <IonText color="danger">Selecione uma opção para prosseguir</IonText>
        </IonRow>
        <IonRow className="ion-justify-content-center ion-align-items-center bluetooth-row__padding-bottom">
          <IonButton
            onClick={() => {
              if (!selectedDeviceType) {
                setShowSelectTypeError(true);
              } else {
                setShowSelectTypeError(false);
                checkThenConnect();
              }
            }}
          >
            Confirmar
          </IonButton>
          <IonButton
            color="danger"
            onClick={() => {
              setShowModal(false);
              setSelectedDeviceType("");
            }}
          >
            Cancelar
          </IonButton>
        </IonRow>
      </IonModal>

      <IonContent>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonButton size="small" onClick={refreshList}>
              Atualizar Lista
            </IonButton>
            <IonButton size="small" color="danger" onClick={disconnectDevices}>
              Desconectar TODOS
            </IonButton>
          </IonRow>
          <IonRow>
            <IonText className="form-text">Lista de dispositivos pareados</IonText>

            <IonText className="form-text">
              <small>Se o dispositivo desejado não estiver na lista, clique na engrenagem acima para parear o dispositivo.</small>
            </IonText>
          </IonRow>
        </IonGrid>

        <IonList>
          {listPaired.map((devices: BluetoothDevice) => (
            <IonItem
              key={devices.name}
              onClick={() => {
                setSelectedDeviceAddress(devices.address);
                setSelectedDeviceName(devices.name);
                setShowModal(true);
              }}
            >
              <IonLabel>
                <h3>{devices.name}</h3>
                {/* <p>
                  {devices.name.indexOf("AT") !== -1 ? "Balança" : "Bastão"}
                </p> */}
              </IonLabel>
              <IonButton color="light" disabled={balancaConnectedAddress == devices.address || bastaoConnectedAddress == devices.address}>
                <IonIcon className={balancaConnectedAddress == devices.address || bastaoConnectedAddress == devices.address ? "verde" : ""} icon={bluetoothSharp} slot="end" />
                {balancaConnectedAddress == devices.address || bastaoConnectedAddress == devices.address ? "Conectado" : "Conectar"}
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default BluetoothConfig;
