import { capSQLiteSet } from "@capacitor-community/sqlite";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../../App";
import { LoteEntradaAnimais } from "../../../models/gado/gadoLoteEntrada/gadoLoteEntrada";
import { LoteSaidaAnimais } from "../../../models/gado/gadoLoteSaida/gadoLoteSaida";
import { AtualizarCabecasLoteEntrada } from "./AtualizarCabecasLoteEntrada";

export const RemoverAnimalLoteEntrada = async (animal: LoteEntradaAnimais): Promise<any> => {
  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }
  await db.open().then(async () => {
    let sqlcmd: string = `DELETE FROM LoteEntradaAnimais WHERE gco_brinco_bastao = '${animal.gco_brinco_bastao}' and lote_id = '${animal.lote_id}';`;
    await db.execute(sqlcmd, false).then(() => {
      db.close();
    });
  });

  await AtualizarCabecasLoteEntrada(animal.lote_id!)
};
