import { IonCard, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonRow, IonText, useIonViewWillEnter } from "@ionic/react";
import LoggedPage, { RightButtonsLoggedPage } from "../../../components/LoggedPage/LoggedPage";
import { useState } from "react";
import { cloudDownloadSharp, cloudUploadSharp, syncSharp } from "ionicons/icons";
import { SQLiteDBConnection } from "@capacitor-community/sqlite";
import { sqlite } from "../../../App";
import { ManejoSanitario } from "../../../models/databaseModels";
import { useDispatch } from "react-redux";
import { Network } from "@capacitor/network";
import { SyncSanitario } from "../../../services/syncServices/syncSanitario/SyncSanitario";
import moment from "moment";
import 'moment/locale/pt-br'

const SincronizarManejoSanitario: React.FC = () => {
  const dispatch = useDispatch();
  const [btnAnimated, setBtnAnimated] = useState<boolean>(false);
  const [listRecords, setListRecords] = useState<ManejoSanitario[]>([
    // {
    //   id: 1,
    //   is_synced: 0,
    //   server_id: 0,
    //   gco_brinco_bastao: "123",
    //   gco_brinco_manual: "qwe",
    //   prontuario_texto: "Exame Pé",
    //   pront_data_procedimento: "2022-11-02",
    // },
  ]);

  const refreshAll = async (e) => {
    await Network.getStatus().then(async (network) => {
      if (network.connected) {
        setBtnAnimated(true);
        dispatch({ type: "SET_LOADING", showLoading: true });
        await SyncSanitario().then(async (response) => {
          await setRecords();
          dispatch({ type: "SET_SUCCESSMESSAGE", successMessage: "Relatório de manejos sanitários sincronizados com sucesso." });
          dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: true });
          dispatch({ type: "SET_LOADING", showLoading: false });
          setBtnAnimated(false);
        });
      } else {
        dispatch({ type: "SET_ERRORMESSAGE", errorMessage: "Não foi possível sincronizar os dados. Você não possui conexão com a internet. Tente Novamente" });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
      }
    });
  };

  useIonViewWillEnter(async () => {
    await setRecords();
  }, []);

  const setRecords = async () => {
    let db: SQLiteDBConnection;
    if ((await sqlite.isConnection("trackBov")).result) {
      db = await sqlite.retrieveConnection("trackBov");
    } else {
      db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
    }
    await db.open().then(async () => {
      await db.query("SELECT * FROM ManejoSanitario order by id desc limit 20;").then((response) => {
        const responseSelect: ManejoSanitario[] = response.values ? response.values : [];
        setListRecords([...responseSelect]);
      }).then(() =>{db.close()});
    });
  };

  const loadMoreRecords = async ($event: CustomEvent<void>) => {
    let db: SQLiteDBConnection;
    if ((await sqlite.isConnection("trackBov")).result) {
      db = await sqlite.retrieveConnection("trackBov");
    } else {
      db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
    }
    await db.open().then(async () => {
      await db.query("SELECT * FROM ManejoSanitario order by id desc LIMIT 20 OFFSET " + listRecords.length + ";").then((response) => {
        const responseSelect: ManejoSanitario[] = response.values ? response.values : [];
        setListRecords([...listRecords, ...responseSelect]);
      }).then(() =>{db.close()});
    });

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }; 

  const menuRightBtns: RightButtonsLoggedPage[] = [
    {
      text: "Sync",
      rightBtnIcon:syncSharp,
      click:refreshAll,
      rightBtnIconAnimated:btnAnimated
    }
  ];

  return (
    <LoggedPage
      title="Manejos Sanitários"
      headerSubText="Eventos criados para manejos sanitários."
      // rightBtn={true}
      // rightBtnText="Sync"
      // rightBtnIconAnimated={btnAnimated}
      // rightBtnIcon={syncSharp}
      // rightBtnClick={refreshAll}
      buttons={menuRightBtns}
    >
      <p>Total de Registros: {listRecords.length}</p>
      <IonList>
        {listRecords.map((item: ManejoSanitario, i: number) => {
          return (
            <IonCard key={i} className="modal_grid_padding_sm">
              <IonRow>
                Data: <IonText className="card_animal_bold bold_margin_left line_margin_bottom_sm">{moment(new Date(item.pront_data_procedimento)).locale("pt-br").format("ll")}</IonText>
              </IonRow>
              <IonRow>
                Sincronizado:{" "}
                <IonText className="card_animal_bold bold_margin_left line_margin_bottom_sm">
                  {item.is_synced === 0 ? <IonIcon icon={cloudDownloadSharp} color="danger" /> : <IonIcon icon={cloudUploadSharp} color="success" />}
                </IonText>
              </IonRow>
              <IonRow>
                Brinco: <IonText className="card_animal_bold bold_margin_left line_margin_bottom_sm">{item.gco_brinco_bastao === "" ? item.gco_brinco_manual : item.gco_brinco_bastao}</IonText>
              </IonRow>
              <IonRow>
                Procedimento: <IonText className="card_animal_bold bold_margin_left line_margin_bottom_sm">{item.prontuario_texto}</IonText>
              </IonRow>
            </IonCard>
          );
        })}
      </IonList>

      <IonInfiniteScroll threshold="100px" onIonInfinite={(e: CustomEvent<void>) => loadMoreRecords(e)}>
        <IonInfiniteScrollContent loadingText="Carregando mais dados"></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </LoggedPage>
  );
};

export default SincronizarManejoSanitario;
