import { Network } from "@capacitor/network";
import { IonBadge, IonRow, useIonViewWillEnter } from "@ionic/react";
import { syncSharp } from "ionicons/icons";
import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import LoggedPage, { RightButtonsLoggedPage } from "../../../components/LoggedPage/LoggedPage";
import { SearchAnimal } from "../../../components/searchAnimal/SearchAnimal";
import { getStorageValue } from "../../../services/StorageServices";
import { syncAnimais } from "../../../services/syncServices/syncAnimais/SyncAnimais";
import "./GadoPesquisar.css";

const GadoPesquisar: React.FC = (routing: any) => {
  const dispatch = useDispatch();
  const [btnAnimated, setBtnAnimated] = useState<boolean>(false);
  const [lastAnimalSyncData, setLastAnimalSyncData] = useState<string | null>("");
  const balancaValue = useSelector((state: RootStateOrAny) => state.balancaValue);
  const balancaStable = useSelector((state: RootStateOrAny) => state.balancaStable);

  useIonViewWillEnter(async () => {
    dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: "" });
    await getStorageValue("lastAnimalSync").then(async (data) => {
      setLastAnimalSyncData(data);
    });
  }, []);

  const refreshAll = async (e) => {
    await Network.getStatus().then(async (network) => {
      if (network.connected) {
        setBtnAnimated(true);
        dispatch({ type: "SET_LOADING", showLoading: true });
        await syncAnimais().then(async (response) => {
          await getStorageValue("lastAnimalSync").then(async (data) => {
            setLastAnimalSyncData(data);
          });
          setBtnAnimated(false);
          dispatch({ type: "SET_LOADING", showLoading: false });
        }).catch((error) => {
          dispatch({
            type: "SET_ERRORMESSAGE",
            errorMessage: error.message,
          });
          dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
          dispatch({ type: "SET_LOADING", showLoading: false });
          setBtnAnimated(false);
        });;
      } else {
        dispatch({
          type: "SET_ERRORMESSAGE",
          errorMessage: "Não foi possível sincronizar os animais. Você não possui conexão com a internet. Tente Novamente",
        });
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
        setBtnAnimated(false);
      }
    });
  };

  const menuRightBtns: RightButtonsLoggedPage[] = [
    {
      text: "Sync",
      rightBtnIcon: syncSharp,
      click: refreshAll,
      rightBtnIconAnimated: btnAnimated,
    },
  ];
  return (
    <LoggedPage title="Pesquisar Animal" headerSubText="Entre com o número do Brinco" buttons={menuRightBtns}>
      <IonRow className="ion-justify-content-start text-row extra_margin_row">
        Peso Balança: <b className="ml-10 mr-10">{balancaValue}</b> KG
        {balancaStable === "E" ? (
          <IonBadge className="weightBadge" color="success">
            E
          </IonBadge>
        ) : (
          <IonBadge className="weightBadge" color="warning">
            !
          </IonBadge>
        )}
      </IonRow>
      <IonRow className="ion-justify-content-start text-row">
        <small>Última Sincronização dos Animais: {lastAnimalSyncData}</small>
      </IonRow>
      <SearchAnimal searchBar={true} loteSaida={false} />
    </LoggedPage>
  );
};

export default GadoPesquisar;
