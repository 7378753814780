import { capSQLiteSet } from "@capacitor-community/sqlite";
import axios from "axios";
import { sqlite } from "../../../App";
import { LoteEntrada, LoteEntradaAnimais } from "../../../models/gado/gadoLoteEntrada/gadoLoteEntrada";
import { ApiConnection } from "../../../settings/constants/apiConnection";
import { DbConnection } from "../../databaseServices/DbConnection";

export const SyncLoteEntrada = async (): Promise<boolean> => {
  let success: boolean = false;
  let responseLotes: LoteEntrada[] = [];
  try {
    let db = await DbConnection();

    await db.open();
    
    let res: any = await db.query("SELECT * FROM LoteEntrada WHERE is_synced = 0 and is_finished = 1");
    responseLotes = res.values ? res.values : [];

    const requests = responseLotes.map(async (element) => {
      let resAnimais: any = await db.query(`SELECT * FROM LoteEntradaAnimais where lote_id = ${element.id};`);
      let responseAnimais: LoteEntradaAnimais[] = resAnimais.values ? resAnimais.values : [];
      element.gcolote_vendedor = element.gcolote_origem;
      element.animaisLote = [];
      element.animaisLote.push(...responseAnimais);
    });

    const mapRes = await Promise.all(requests).then(() => {
      return responseLotes;
    });

    if (mapRes.length > 0) {
      const uri = ApiConnection.baseUrl + ApiConnection.syncLoteEntrada;
      await axios.post(uri, responseLotes).then(async (response) => {
        if (response.status === 200) {
          const data: Array<capSQLiteSet> = [];
          const responseData = response.data.retorno.map(async (element) => {
            if (element.sucesso) {
              const loteCliente = element.cliente_id;
              const loteServidor = element.gcolote_id;
              data.push({
                statement: "UPDATE LoteEntrada SET is_synced = 1, server_id = ? WHERE id = ?;",
                values: [loteServidor, loteCliente],
              });
            }
          });

          const updateDbData = await Promise.all(responseData).then(() => {
            return data;
          });
          if (updateDbData.length > 0) {
            await db.executeSet(updateDbData);
            await db.close();
            await sqlite.closeConnection("trackBov");
          }
        } else {
          success = false;
        }
      });
    } else {
      await db.close();
      await sqlite.closeConnection("trackBov");
    }
  } catch (err) {
    success = false;
  }

  return success;
};
