import axios from "axios";
import { ApiConnection } from "../../../settings/constants/apiConnection";
import { setStorageValue } from "../../StorageServices";

export const PropertyData = async (): Promise<void> => {
  const properties = ApiConnection.baseUrl + ApiConnection.appSyncFazendas;
  await axios.get(properties).then(async (resp) => {
    const data = resp.data ? resp.data : [];
    await setStorageValue("ListaPropriedades", JSON.stringify(data));
  });

  const confinamentos = ApiConnection.baseUrl + ApiConnection.appSyncConfinamentos;
  await axios.get(confinamentos).then(async (resp) => {
    const data = resp.data ? resp.data : [];
    await setStorageValue("ListaConfinamentos", JSON.stringify(data));
  });

  const marcas = ApiConnection.baseUrl + ApiConnection.appSyncMarcas;
  await axios.get(marcas).then(async (resp) => {
    const data = resp.data ? resp.data : [];
    await setStorageValue("ListaMarcas", JSON.stringify(data));
  });

  const categorias = ApiConnection.baseUrl + ApiConnection.appSyncCategorias;
  await axios.get(categorias).then(async (resp) => {
    const data = resp.data ? resp.data : [];
    await setStorageValue("ListaCategorias", JSON.stringify(data));
  });

  const racas = ApiConnection.baseUrl + ApiConnection.appSyncRacas;
  await axios.get(racas).then(async (resp) => {
    const data = resp.data ? resp.data : [];
    await setStorageValue("ListaRacas", JSON.stringify(data));
  });

  const tipoCompra = ApiConnection.baseUrl + ApiConnection.appSyncTipoCompra;
  await axios.get(tipoCompra).then(async (resp) => {
    const data = resp.data ? resp.data : [];
    await setStorageValue("ListaTipoCompra", JSON.stringify(data));
  });

  const listRacoes = ApiConnection.baseUrl + ApiConnection.appSyncRacoes;
  await axios.get(listRacoes).then(async (resp) => {
    const data = resp.data ? resp.data : [];
    await setStorageValue("ListaProdutosRacoes", JSON.stringify(data));
  });
};
