import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../../App";
import { LoteSaidaAnimais } from "../../../models/gado/gadoLoteSaida/gadoLoteSaida";

export const AtualizarCabecasLoteEntrada = async (lote: number): Promise<void> => {
  let responseSelect: LoteSaidaAnimais[] = [];
  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }

  await db.open().then(async () => {
    await db.query(`SELECT * FROM LoteEntradaAnimais WHERE lote_id = '${lote}';`).then(async (response) => {
      responseSelect = response.values ? response.values : [];
      const total = responseSelect.length;

      let sqlcmd: string = `UPDATE LoteEntrada SET gcolote_qtd_cabecas = '${total}' WHERE id = '${lote}';`;
      await db.execute(sqlcmd, false).then(() => {
        db.close();
      });
    });
  });
};
