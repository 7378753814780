import { SQLiteDBConnection } from "@capacitor-community/sqlite";
import { sqlite } from "../../App";
import { InformarMorte, InformarMorteImagens, ManejoSanitario } from "../../models/databaseModels";
import { LoteEntrada } from "../../models/gado/gadoLoteEntrada/gadoLoteEntrada";
import { LoteSaida } from "../../models/gado/gadoLoteSaida/gadoLoteSaida";

export async function checkSync(): Promise<boolean> {
  let responseMorte: InformarMorte[] = [];
  let responseEntrada: LoteEntrada[] = [];
  let responseSaida: LoteSaida[] = [];
  let responseMorteImagens: InformarMorteImagens[] = [];
  let responseSanitario: ManejoSanitario[] = [];
  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }
  await db
    .open()
    .then(async () => {
      await db.query("SELECT * FROM InformarMorte WHERE is_synced = 0").then((response) => {
        responseMorte = response.values ? response.values : [];
      });
      await db.query("SELECT * FROM LoteEntrada WHERE is_synced = 0 and is_finished = 1").then((response) => {
        responseEntrada = response.values ? response.values : [];
      });
      await db.query("SELECT * FROM LoteSaida WHERE is_synced = 0 and is_finished = 1").then((response) => {
        responseSaida = response.values ? response.values : [];
      });
      await db.query("SELECT * FROM InformarMorteImagens WHERE is_synced = 0").then((response) => {
        responseMorteImagens = response.values ? response.values : [];
      });
      await db.query("SELECT * FROM ManejoSanitario WHERE is_synced = 0").then((response) => {
        responseSanitario = response.values ? response.values : [];
      });
    })
    .then(() => {
      db.close();
    });
  let needSync = false;
  if (responseMorte.length > 0 || responseEntrada.length > 0 || responseSaida.length > 0 || responseMorteImagens.length > 0 || responseSanitario.length > 0) {
    needSync = true;
  }

  return needSync;
}
