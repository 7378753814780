import { IonRow, IonCol, IonButton, IonSearchbar, IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CadGadConfinamento } from "../../models/databaseModels";
import { SearchAnimalByTag } from "../../services/databaseServices/SearchAnimalByTag";
import StickScaleForm from "../Forms/StickScaleForm/StickScaleForm";
import CardAnimal from "./CardAnimal";

interface SearchAnimalProps {
  searchBar?: boolean;
  loteSaida?: boolean;
  loteSaidaHandle?: any;
  resetForm?: number;
}
export const SearchAnimal: React.FC<SearchAnimalProps> = ({ searchBar, loteSaida, loteSaidaHandle, resetForm }) => {
  const dispatch = useDispatch();
  const [brincoManual, setBrincoManual] = useState<string>("");
  const [listRecords, setListRecords] = useState<CadGadConfinamento[]>([]);
  const [listRecordsShow, setListRecordsShow] = useState<CadGadConfinamento[]>([]);
  const [animalExato, setAnimalExato] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  
  useEffect(() => {
    handleResetClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm]);

  const setFieldValue = async (value: any, field: string) => {
    if (value && value !== "" && field === "gco_brinco_bastao") {
      dispatch({ type: "SET_LOADING", showLoading: true });
      setBrincoManual("");
      searchResults(value);
    } else if (value && value !== "" && field === "gco_brinco_manual") {
      setBrincoManual(value);
    }
  };

  const handleButtonClick = () => {
    dispatch({ type: "SET_LOADING", showLoading: true });
    if (brincoManual && brincoManual !== "") {
      dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: "" });
      searchResults(brincoManual);
    } else {
      dispatch({
        type: "SET_ERRORMESSAGE",
        errorMessage: "Entre com um número de brinco, ou faça a leitura com o bastão",
      });
      dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
      dispatch({ type: "SET_LOADING", showLoading: false });
    }
  };
  const handleResetClick = () => {
    dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: "" });
    setListRecords([]);
    setListRecordsShow([]);
    setBrincoManual("");
    setSearchText("");
  };

  const changeSearchBar = (value) => {
    setSearchText(value);
    if (value !== "") {
      setListRecordsShow(
        listRecords.filter(function (el) {
          return (
            (el.con_nome && el.con_nome.includes(value)) ||
            (el.faz_nome && el.faz_nome.includes(value)) ||
            (el.gco_brinco_bastao && el.gco_brinco_bastao.includes(value)) ||
            (el.gco_marca && el.gco_marca.includes(value))
          );
        })
      );
    } else {
      setListRecordsShow(listRecords);
    }
  };

  const searchResults = async (value: string) => {
    dispatch({ type: "SET_LOADING", showLoading: true });
    await SearchAnimalByTag(value).then((data) => {
      if (data.length === 1 && data[0].gco_brinco_bastao === value) {
        setListRecords([data[0]]);
        setListRecordsShow([data[0]]);
        setAnimalExato(true);
      } else {
        setListRecords([...data]);
        setListRecordsShow([...data]);
        setAnimalExato(false);
      }
      dispatch({ type: "SET_LOADING", showLoading: false });
    }).catch((error) => {
      dispatch({
        type: "SET_ERRORMESSAGE",
        errorMessage: error.message,
      });
      dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
      dispatch({ type: "SET_LOADING", showLoading: false });
    });
  };

  return (
    <>
      <IonRow className="ion-justify-content-start text-row">Registros Encontrados: {listRecords.length}</IonRow>
      {searchBar && (
        <>
          <IonRow className="ion-justify-content-start text-row">
            <IonSearchbar value={searchText} onIonChange={(e) => changeSearchBar(e.detail.value!)} animated placeholder="Filtrar Resultados"></IonSearchbar>
          </IonRow>
          {searchText !== "" ? (
            <IonText>
              Exibindo {listRecordsShow.length} registro(s) de {listRecords.length}
            </IonText>
          ) : (
            <></>
          )}
        </>
      )}
      <IonRow>
        <StickScaleForm brincoBastao={true} brincoManual={true} brincoManualValue={brincoManual} capturarImagens={false} setFieldValue={setFieldValue} />
      </IonRow>

      <IonRow className="ion-justify-content-start ion-align-items-center ion-text-center text-row">
        <IonCol size="6">
          <IonButton onClick={handleButtonClick}>Pesquisar</IonButton>
        </IonCol>
        <IonCol size="6">
          <IonButton size="small" color="danger" onClick={handleResetClick}>
            Limpar
          </IonButton>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          {listRecordsShow.map((item, index) => {
            return <CardAnimal animalExato={animalExato} key={index} item={item} loteSaida={loteSaida} loteSaidaHandle={() => loteSaidaHandle(item)} />;
          })}
        </IonCol>
      </IonRow>
    </>
  );
};
