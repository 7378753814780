import { IonLoading, IonToast } from "@ionic/react";
import React from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import './AlertMessage.css'

const AlertMessage = () => {
  const dispatch = useDispatch();

  const showAlert = useSelector((state: RootStateOrAny) => state.showAlert);
  const alertMessage = useSelector((state: RootStateOrAny) => state.alertMessage);

  return (
    <IonToast
      isOpen={showAlert}
      message={alertMessage}
      color="warning"
      duration={3000}
      animated={true}
      onDidDismiss={() =>
        dispatch({ type: "SET_SHOWALERTMESSAGE", showAlert: false })
      }
      buttons={[
        {
          text: '✖',
          role: 'cancel',
          cssClass: "alert-toast"
        }
      ]}
    />
  );
};

export default AlertMessage;
