import { IonRow, IonBadge } from "@ionic/react";
import { RootStateOrAny, useSelector } from "react-redux";

export const ScaleWeight: React.FC = () => {
    const balancaValue = useSelector((state: RootStateOrAny) => state.balancaValue);
    const balancaStable = useSelector((state: RootStateOrAny) => state.balancaStable);
    
  return (
    <IonRow className="ion-justify-content-start text-row extra_margin_row">
      Peso Balança: <b className="ml-10 mr-10">{balancaValue}</b> KG
      {balancaStable === "E" ? (
        <IonBadge className="weightBadge" color="success">
          E
        </IonBadge>
      ) : (
        <IonBadge className="weightBadge" color="warning">
          !
        </IonBadge>
      )}
    </IonRow>
  );
};
