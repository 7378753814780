import { IonButton, IonCol, IonContent, IonDatetime, IonGrid, IonInput, IonItem, IonLabel, IonList, IonModal, IonRow, IonSelect, IonSelectOption, IonText, useIonViewWillEnter } from "@ionic/react";
import { chevronBackSharp } from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router";
import LoggedPage, { RightButtonsLoggedPage } from "../../../../components/LoggedPage/LoggedPage";
import { AdicionarConsumoConfinamento } from "../../../../services/databaseServices/consumoConfinamento/AdicionarConsumoConfinamento";
import { getStorageValue, setStorageValue } from "../../../../services/StorageServices";

export interface FormConsumoConfinamento {
  id?: number;
  server_id?: number;
  con_id: string;
  data?: string;
  prod_id_fk?: string;
  leitura_cocho?: string;
  quantidade?: string;
  erros?: string;
}

const NovoRegistroConsumoConfinamento: React.FC = (routing: any) => {
  const history = useHistory();
  const [showModalEncerrarRegistro, setShowModalEncerrarRegistro] = useState<boolean>(false);
  const [perfilUsuario, setPerfilUsuario] = useState<string>("");
  const [propList, setPropList] = useState<any>([]);
  const [property, setProperty] = useState<string>("0");
  const [confList, setConfList] = useState<any>([]);
  const [racaoList, setRacaoList] = useState<any>([]);

  useIonViewWillEnter(async () => {
    setConfList([]);
    setFormInfo([]);
    await getStorageValue("AppPerfil").then((response) => {
      setPerfilUsuario(response ? response : "");
    });

    await getStorageValue("ListaConfinamentos").then((response) => {
      setConfList(response ? JSON.parse(response) : []);
    });

    await getStorageValue("ListaPropriedades").then((response) => {
      const resp = response ? JSON.parse(response) : [];
      setPropList(resp);
    });

    await getStorageValue("AppPropriedadeSelecionada").then((response) => {
      setProperty(response ? response : "");
    });

    await getStorageValue("ListaProdutosRacoes").then((response) => {
      const resp = response ? JSON.parse(response) : [];
      setRacaoList(resp ? resp : "");
    });
  }, []);
  const [formInfo, setFormInfo] = useState<FormConsumoConfinamento[]>([]);

  const handleSubmit = async () => {
    let erros = false;
    formInfo.forEach((field) => {
      field.erros = "";
      if ((field.data && !field.quantidade) || (field.prod_id_fk && !field.quantidade)) {
        field.erros += "Preencha a quantidade. ";
        erros = true;
      }
      if ((field.quantidade && !field.data) || (field.prod_id_fk && !field.data)) {
        field.erros += "Preencha a data. ";
        erros = true;
      }
      if ((field.quantidade && !field.prod_id_fk) || (field.data && !field.prod_id_fk)) {
        field.erros += "Escolha a ração. ";
        erros = true;
      }
    });
    setFormInfo([...formInfo]);
    if (!erros && formInfo.length > 0) {
      setShowModalEncerrarRegistro(true);
    }
  };

  const handleEnviarRegistro = async () => {
    await AdicionarConsumoConfinamento(formInfo);
    history.replace("/gadoConsumoConfinamento");
  };

  const menuRightBtns: RightButtonsLoggedPage[] = [
    {
      text: "Voltar",
      leftBtnIcon: chevronBackSharp,
      click: () => {
        history.replace("/gadoConsumoConfinamento");
      },
    },
  ];
  return (
    <LoggedPage title="Novo Registro de Consumo" headerSubText="Entre com os dados do consumo do confinamento" buttons={menuRightBtns}>
      <IonRow>
        <IonCol>
          <div className="form__item">
            <IonItem lines="none">
              <IonLabel position="floating">
                <IonText>Escolha sua Propriedade</IonText>
              </IonLabel>
              <IonSelect
                value={property}
                disabled={parseInt(perfilUsuario) <= 3 ? true : false}
                onIonChange={async (e) => {
                  const val = e.detail.value;
                  setProperty(val);
                  await setStorageValue("AppPropriedadeSelecionada", val);
                }}
              >
                {propList.map((index) => {
                  return (
                    <IonSelectOption key={index.value} value={index.value.toString()}>
                      {index.label}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonItem>
            <IonGrid>
              {property !== "0" &&
                confList
                  .filter((val) => val.faz_id_fk === parseInt(property) && val.tipo === "confinamento")
                  .map((item) => {
                    return (
                      <IonRow key={item.value}>
                        <IonGrid>
                          <IonRow>
                            <IonLabel>
                              <h1>{item.label}</h1>
                            </IonLabel>
                          </IonRow>

                          {formInfo.some((val) => val.con_id === item.value) && (
                            <IonRow>
                              <div style={{ height: "15px" }}>
                                <div className="form__error">
                                  <IonText color="danger" className="form__error-element">
                                    <small>
                                      <span role="alert">{formInfo[formInfo.findIndex((val) => val.con_id === item.value)].erros!}</span>
                                    </small>
                                  </IonText>
                                </div>
                              </div>
                            </IonRow>
                          )}

                          <IonRow>
                            <IonCol size="6">
                              <IonItem>
                                <IonLabel position="floating">Data</IonLabel>
                                <IonDatetime
                                  monthNames={["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]}
                                  monthShortNames={["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]}
                                  cancelText="Cancelar"
                                  doneText="Selecionar"
                                  displayFormat="DD MMM YYYY"
                                  onIonChange={(e) => {
                                    formInfo.some((val) => val.con_id === item.value)
                                      ? (formInfo[formInfo.findIndex((val) => val.con_id === item.value)].data = e.detail.value!)
                                      : formInfo.push({ con_id: item.value, data: e.detail.value! });
                                  }}
                                ></IonDatetime>
                              </IonItem>
                            </IonCol>
                            <IonCol size="6">
                              <IonItem>
                                <IonLabel position="floating">Produto</IonLabel>
                                <IonSelect
                                  onIonChange={(e) => {
                                    formInfo.some((val) => val.con_id === item.value)
                                      ? (formInfo[formInfo.findIndex((val) => val.con_id === item.value)].prod_id_fk = e.detail.value!)
                                      : formInfo.push({ con_id: item.value, prod_id_fk: e.detail.value! });
                                  }}
                                >
                                  {racaoList &&
                                    racaoList.length > 0 &&
                                    racaoList.map((index) => {
                                      return (
                                        <IonSelectOption key={index.value} value={index.value.toString()}>
                                          {index.label}
                                        </IonSelectOption>
                                      );
                                    })}
                                </IonSelect>
                              </IonItem>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol size="6">
                              <IonItem>
                                <IonLabel position="floating">Leitura de Cocho</IonLabel>
                                <IonInput
                                  type="number"
                                  onIonChange={(e) => {
                                    formInfo.some((val) => val.con_id === item.value)
                                      ? (formInfo[formInfo.findIndex((val) => val.con_id === item.value)].leitura_cocho = e.detail.value!)
                                      : formInfo.push({ con_id: item.value, leitura_cocho: e.detail.value! });
                                  }}

                                  // onIonBlur={(e: any) => {
                                  //   const value = parseFloat(e.target.value.replace(",", ".").match(/[\d]+/));
                                  //   const valueLocalized = (value * 0.01).toLocaleString("pt-BR", { style: "percent", minimumFractionDigits: 2 });
                                  //   console.log(valueLocalized)
                                  //   // if (!isNaN(value)) {
                                  //   //   inputFields[inputFields.findIndex((obj) => obj.name === "gcolotesaida_rendimento")].value = valueLocalized;
                                  //   //   setInputFields([...inputFields]);
                                  //   // } else {
                                  //   //   e.target.value = "";
                                  //   //   inputFields[inputFields.findIndex((obj) => obj.name === "gcolotesaida_rendimento")].value = "";
                                  //   //   setInputFields([...inputFields]);
                                  //   // }
                                  // }}
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol size="6">
                              <IonItem>
                                <IonLabel position="floating">Quantidade</IonLabel>
                                <IonInput
                                  type="number"
                                  onIonChange={(e) => {
                                    formInfo.some((val) => val.con_id === item.value)
                                      ? (formInfo[formInfo.findIndex((val) => val.con_id === item.value)].quantidade = e.detail.value!)
                                      : formInfo.push({ con_id: item.value, quantidade: e.detail.value! });
                                  }}
                                />
                              </IonItem>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonRow>
                    );
                  })}
            </IonGrid>
          </div>
        </IonCol>
      </IonRow>
      {formInfo.some((val) => val.erros) && (
        <IonRow className="ion-margin-top ion-margin-bottom ion-justify-content-center">
          <IonText color="danger" className="form__error-element">
            Preencha todos os campos para prosseguir
          </IonText>
        </IonRow>
      )}

      <IonRow>
        <IonButton
          className="btnGridFullWidth"
          onClick={() => {
            handleSubmit();
          }}
        >
          Criar Registro
        </IonButton>
      </IonRow>

      <IonModal
        isOpen={showModalEncerrarRegistro}
        onDidDismiss={() => {
          setShowModalEncerrarRegistro(false);
        }}
      >
        <IonContent>
          <IonGrid className="modal_grid_95 ">
            <IonRow className="card_animal_regular_text">
              <IonText className="ion-text-center">
                <p>Confirme os dados:</p>
              </IonText>
            </IonRow>

              <IonList lines="full">
              <IonItem>
                      <IonGrid>
                        <IonRow>
                          <IonCol size="6">
                            <IonLabel>Confinamento</IonLabel>
                          </IonCol>
                          <IonCol size="2">
                            <IonLabel>Qtd: (KG)</IonLabel>
                          </IonCol>
                          <IonCol size="4">
                            <IonLabel>Leitura de Cocho (%)</IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>

                {formInfo &&
                  formInfo.length > 0 &&
                  formInfo.map((item, index) => {
                    return(<IonItem key={index}>
                      <IonGrid>
                        <IonRow>
                          <IonCol size="6">
                            <IonLabel>{confList.filter(val => val.value === item.con_id)[0].label}</IonLabel>
                          </IonCol>
                          <IonCol size="2" className="ion-text-center">
                            <IonLabel>{item.quantidade} KG</IonLabel>
                          </IonCol>
                          <IonCol size="4" className="ion-text-center">
                            <IonLabel>{item.leitura_cocho} %</IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>)
                  })}
              </IonList>
            <IonRow className="ion-text-center">
              <IonCol>
                <IonButton
                  color="primary"
                  onClick={() => {
                    handleEnviarRegistro();
                  }}
                >
                  Enviar Registro
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol>
                <IonButton
                  color="danger"
                  onClick={() => {
                    setShowModalEncerrarRegistro(false);
                  }}
                >
                  Cancelar
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </LoggedPage>
  );
};

export default NovoRegistroConsumoConfinamento;
