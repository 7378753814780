import { IonLoading } from "@ionic/react";
import React from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

const Loading = () => {
  const dispatch = useDispatch();

  const showLoading = useSelector((state: RootStateOrAny) => state.showLoading);

  return (
    <IonLoading
      isOpen={showLoading}
      onDidDismiss={() => dispatch({ type: "SET_LOADING", showLoading: false })}
      message="Carregando..."
    />
  );
};

export default Loading;
