import { capSQLiteSet } from "@capacitor-community/sqlite";
import { IonButton, useIonViewWillEnter } from "@ionic/react";
import { chevronBackSharp } from "ionicons/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { SQLiteDBConnection } from "react-sqlite-hook";
import { AppDispatch, sqlite } from "../../../../App";
import Form, { InputField } from "../../../../components/Forms/Form";
import LoggedPage, { RightButtonsLoggedPage } from "../../../../components/LoggedPage/LoggedPage";
import { LoteSaida } from "../../../../models/gado/gadoLoteSaida/gadoLoteSaida";
import { getStorageValue } from "../../../../services/StorageServices";
import { addFieldRequiredError, hasProperties } from "../../../../utils";

const NovoLoteSaida: React.FC = (routing: any) => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const initState: LoteSaida = {
    gcolotesaida_faz_id_fk: 0,
    gcolotesaida_comprador: "",
    gcolotesaida_valKilo: 0,
    gcolotesaida_rendimento: 0,
    gcolotesaida_frete: 0,
    gcolotesaida_datasaida: "",
  };
  const [propList, setPropList] = useState<any>([]);
  useIonViewWillEnter(async() => {
    await getStorageValue("ListaPropriedades").then((response) => {
      const resp = response ? JSON.parse(response) : [];
      setPropList(resp)
      inputFields[0].selectValues = resp;
      setInputFields([...inputFields]);
    })
  },[])
  const [formInfo, setFormInfo] = useState<LoteSaida>(initState);
  const initialInputFields: InputField[] = [
    {
      name: "gcolotesaida_faz_id_fk",
      label: "Propriedade",
      required: true,
      select: true,
      selectValues: propList
    },
    {
      name: "gcolotesaida_comprador",
      label: "Comprador",
      type: "text",
      required: true,
      value: formInfo.gcolotesaida_comprador,
    },
    {
      name: "gcolotesaida_datasaida",
      label: "Data de Saída",
      required: true,
      isDateTimerPicker: true,
      value: formInfo.gcolotesaida_datasaida,
    },
  ];

  const [errors, setErrors] = useState<any>(null);
  const [inputFields, setInputFields] = useState<InputField[]>(initialInputFields);
  const setFieldValue = (value: any, field: string) => {
    formInfo[field] = value;
  };
  const getErrors = () => {
    let mutableErrors: any = {};
    inputFields &&
      inputFields.forEach((field) => {
        if (field.required) addFieldRequiredError(formInfo, mutableErrors, field.name);
      });

    return mutableErrors;
  };
  const handleSubmit = async () => {
    dispatch({ type: "SET_LOADING", showLoading: true });
    const loginErrors = getErrors();

    if (!hasProperties(loginErrors)) {
      setErrors([]);
      dispatch({ type: "SET_LOADING", showLoading: true });
      // ADD TO DB

      let db: SQLiteDBConnection;
      if ((await sqlite.isConnection("trackBov")).result) {
        db = await sqlite.retrieveConnection("trackBov");
      } else {
        db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
      }

      await db.open().then(async () => {
        const data: Array<capSQLiteSet> = [
          {
            statement:
              "INSERT INTO LoteSaida ( is_finished, is_synced, server_id, gcolotesaida_valKilo, gcolotesaida_comprador, gcolotesaida_datasaida, gcolotesaida_rendimento, gcolotesaida_faz_id_fk, gcolotesaida_qtdcabeca, gcolotesaida_frete) VALUES (?,?,?,?,?,?,?,?,?,?);",
            values: [
              0,
              0,
              0,
              formInfo.gcolotesaida_valKilo,
              formInfo.gcolotesaida_comprador,
              formInfo.gcolotesaida_datasaida,
              formInfo.gcolotesaida_rendimento,
              formInfo.gcolotesaida_faz_id_fk,
              0,
              formInfo.gcolotesaida_frete,
            ],
          },
        ];

        // Add Data to DataBASE
        await db.executeSet(data).then(() => {
          db.close();
        });
      });

      //Clear State and hide Loading
      setFormInfo(initState);
      history.replace("/gadoSaida");
      dispatch({ type: "SET_LOADING", showLoading: false });
    } else {
      dispatch({ type: "SET_LOADING", showLoading: false });
      setErrors(loginErrors);
    }
  };

  const menuRightBtns: RightButtonsLoggedPage[] = [   
    {
      text: "Voltar",
      leftBtnIcon: chevronBackSharp,
      click:  () => {
        history.replace("/gadoSaida");
      },
    },
  ];
  return (
    <LoggedPage title="Novo Lote de Saída" headerSubText="Entre com os dados do Lote de Saída" buttons={menuRightBtns}>
      <Form items={inputFields} errors={errors} setFieldValue={setFieldValue} />
      <IonButton className="btnGridFullWidth" onClick={handleSubmit}>
        Criar Lote
      </IonButton>
    </LoggedPage>
  );
};

export default NovoLoteSaida;
