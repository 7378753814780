import { capSQLiteSet } from "@capacitor-community/sqlite";
import moment from "moment";
import { sqlite } from "../../../App";
import { FormConsumoConfinamento } from "../../../pages/gado/gadoConsumoConfinamento/novoRegistro/NovoRegistroConsumoConfinamento";
import { DbConnection } from "../DbConnection";

export const AdicionarConsumoConfinamento = async (consumo: FormConsumoConfinamento[]) => {
  let db = await DbConnection();
  try {
    await db.open().then(async () => {
      const data: Array<capSQLiteSet> = [];
      const responseData = consumo.map(async (element) => {
        data.push({
          statement: "INSERT into CustoConfinamentos (is_synced ,server_id, con_id, data, prod_id_fk, quantidade, leitura_cocho) VALUES (?,?,?,?,?,?,?);",
          values: [0, 0, element.con_id, moment(element.data).format("YYYY-MM-DD"), parseInt(element.prod_id_fk!), parseInt(element.quantidade!), parseFloat(element.leitura_cocho!)],
        });
      });

      const updateDbData = await Promise.all(responseData).then(() => {
        return data;
      });
      console.log(updateDbData);
      if (updateDbData.length > 0) {
        await db.executeSet(updateDbData);
        await db.close();
        await sqlite.closeConnection("trackBov");
      }
    });
  } catch (err) {
  }
};
