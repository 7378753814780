import { IonButton, useIonViewWillEnter } from "@ionic/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { capSQLiteSet, SQLiteDBConnection } from "@capacitor-community/sqlite";
import { AppDispatch, sqlite } from "../../../App";
import { ManejoSanitario } from "../../../models/databaseModels";
import LoggedPage from "../../../components/LoggedPage/LoggedPage";
import "./GadoManejoSanitario.css";
import { FormErrorProps } from "../../../components/Forms/GeneralError/GeneralError";
import { hasProperties } from "../../../utils";
import StickScaleForm, { InputField } from "../../../components/Forms/StickScaleForm/StickScaleForm";
import { Network } from "@capacitor/network";
import { SyncSanitario } from "../../../services/syncServices/syncSanitario/SyncSanitario";

const GadoManejoSanitario: React.FC = (routing: any) => {
  const initState: ManejoSanitario = {
    gco_brinco_bastao: "",
    gco_brinco_manual: "",
    prontuario_texto: "",
    pront_data_procedimento: ""
  };
  const [formInfo, setFormInfo] = useState<ManejoSanitario>(initState);
  const inputFields: InputField[] = [
    {
      name: "pront_data_procedimento",
      label: "Data do Procedimento",
      value: formInfo.pront_data_procedimento,
      isDateTimerPicker: true
    },
    {
      name: "prontuario_texto",
      label: "Procedimento Realizado",
      type: "textArea",
      rows: 3,
      value: formInfo.prontuario_texto,
    },
  ];

  const [errorsState, setErrorsState] = useState<FormErrorProps[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  useIonViewWillEnter(() => {
    dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: "" });
  }, []);

  const setFieldValue = (value: any, field: string) => {
    // @ts-ignore
    formInfo[field] = value;
    setFormInfo({
      ...formInfo,
    });
  };

  const handleSubmit = async () => {
    const errors: FormErrorProps[] = [];

    //VALIDATE FORM FIELDS
    //Check
    if (formInfo.gco_brinco_bastao === "" && formInfo.gco_brinco_manual === "") {
      const newError: FormErrorProps[] = [
        {
          rowName: "gco_brinco_bastao",
          hasError: true,
          errorMessage: "Você precisa preencher pelo menos um campo com número do brinco.",
        },
        {
          rowName: "gco_brinco_manual",
          hasError: true,
          errorMessage: "Você precisa preencher pelo menos um campo com número do brinco.",
        },
      ];
      errors.push(...newError);
    }
    if (formInfo.prontuario_texto === "") {
      const newError: FormErrorProps = {
        rowName: "prontuario_texto",
        hasError: true,
        errorMessage: "Você precisa preencher o procedimento realizado no animal.",
      };
      errors.push(newError);
    }

    if (formInfo.pront_data_procedimento === "") {
      const newError: FormErrorProps = {
        rowName: "pront_data_procedimento",
        hasError: true,
        errorMessage: "Você precisa preencher a data do procedimento.",
      };
      errors.push(newError);
    }
    
    setErrorsState(errors);

    if (!hasProperties(errors)) {
      setErrorsState([]);
      dispatch({ type: "SET_LOADING", showLoading: true });
      // ADD TO DB

      let db: SQLiteDBConnection;
      if ((await sqlite.isConnection("trackBov")).result) {
        db = await sqlite.retrieveConnection("trackBov");
      } else {
        db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
      }

      await db.open().then(async () => {
        const data: Array<capSQLiteSet> = [
          {
            statement: "INSERT INTO ManejoSanitario (is_synced,server_id,gco_brinco_bastao,gco_brinco_manual,prontuario_texto,pront_data_procedimento) VALUES (?,?,?,?,?,?);",
            values: [0, 0, formInfo.gco_brinco_bastao, formInfo.gco_brinco_manual, formInfo.prontuario_texto, formInfo.pront_data_procedimento],
          },
        ];

        // Add Data to DataBASE
        await db.executeSet(data).then(() =>{db.close()});
      });

      //Clear State and hide Loading
      dispatch({ type: "SET_BASTAO_VALUE", bastaoValue: "" });
      setFormInfo(initState);

      await Network.getStatus().then(async (network) => {
        if (network.connected) {
          dispatch({ type: "SET_LOADING", showLoading: true });
          await SyncSanitario().then(async () => {
            dispatch({ type: "SET_SUCCESSMESSAGE", successMessage: "Manejo Sanitário incluído incluído com SUCESSO." });
            dispatch({ type: "SET_SHOWSUCCESSMESSAGE", showSuccess: true });
            dispatch({ type: "SET_LOADING", showLoading: false });
          });
        } else {
          dispatch({ type: "SET_ALERTMESSAGE", alertMessage: "Manejo Sanitário incluído com sucesso, mas a sincronização com a nuvem falhou! Sincronize seu APP mais tarde." });
          dispatch({ type: "SET_SHOWALERTMESSAGE", showAlert: true });
        }
      });
      dispatch({ type: "SET_LOADING", showLoading: false });
    }
  };

  return (
    <LoggedPage title="Manejo Sanitário" headerSubText="Preencha os campos abaixo para informar a morte do animal">
      <form id="myForm">
        <StickScaleForm
          brincoBastao={true}
          brincoManual={true}
          brincoManualValue={formInfo.gco_brinco_manual}
          capturarImagens={false}
          items={inputFields}
          errors={errorsState}
          setFieldValue={setFieldValue}
        />
      </form>

      <div className="btnGridWrapper rowVerticalPadding">
        <IonButton className="btnGridFullWidth" onClick={handleSubmit}>
          Enviar
        </IonButton>
      </div>
    </LoggedPage>
  );
};

export default GadoManejoSanitario;
