import { IonApp, IonRouterOutlet, IonSplitPane, useIonViewWillEnter } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { Provider } from "react-redux";
import store from "./store/store";
import Bluetooth from "./pages/bluetooth/bluetooth";
import Loading from "./components/Loading";
import ErrorMessage from "./components/errorMessage/ErrorMessage";
import SuccessMessage from "./components/successMessage/SuccessMessage";

import { useSQLite } from "react-sqlite-hook/dist";
import { useRef, useState } from "react";
import Landing from "./pages/landing/Landing";
import Login from "./pages/account/login/Login";
import Register from "./pages/account/Register/Register";
import Interceptors from "./settings/interceptors";
import Dashboard from "./pages/dashboard/Dashboard";
import DashboardGado from "./pages/gado/DashboardGado";
import DashboardOffline from "./pages/dashboardOffline/DashboardOffline";
import GadoInformaMorte from "./pages/gado/gadoInformaMorte/GadoInformaMorte";
import DashboardSincronizar from "./pages/sincronizarDados/DashboardSincronizar";
import ManejosInformaMorte from "./pages/sincronizarDados/sincronizarMortes/SincronizarInformaMorte";
import GadoPesquisar from "./pages/gado/gadoPesquisar/GadoPesquisar";
import AlertMessage from "./components/alertMessage/AlertMessage";
import GadoManejoSanitario from "./pages/gado/gadoManejoSanitario/GadoManejoSanitario";
import { KeepAwake } from "@capacitor-community/keep-awake";
import { IsMobile } from "./utils";
import SincronizarManejoSanitario from "./pages/sincronizarDados/sincronizarSanitario/SincronizarManejoSanitario";
import GadoLoteEntrada from "./pages/gado/gadoLoteEntrada/GadoLoteEntrada";
import GadoLoteSaida from "./pages/gado/gadoLoteSaida/GadoLoteSaida";
import NovoLoteSaida from "./pages/gado/gadoLoteSaida/novoLoteSaida/NovoLoteSaida";
import PreencherLoteSaida from "./pages/gado/gadoLoteSaida/preencherLoteSaida/PreencherLoteSaida";
import NovoLoteEntrada from "./pages/gado/gadoLoteEntrada/novoLoteEntrada/NovoLoteEntrada";
import PreencherLoteEntrada from "./pages/gado/gadoLoteEntrada/preencherLoteEntrada/PreencherLoteEntrada";
import GadoConsumoConfinamento from "./pages/gado/gadoConsumoConfinamento/GadoConsumoConfinamento";
import NovoRegistroConsumoConfinamento from "./pages/gado/gadoConsumoConfinamento/novoRegistro/NovoRegistroConsumoConfinamento";
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Singleton SQLite Hook
export let sqlite: any;
// Existing Connections Store
export let existingConn: any;
// Is Json Listeners used
export let isJsonListeners: any;

const App: React.FC = () => {
  const [existConn, setExistConn] = useState(false);
  existingConn = { existConn: existConn, setExistConn: setExistConn };
  const [jsonListeners, setJsonListeners] = useState(false);
  isJsonListeners = {
    jsonListeners: jsonListeners,
    setJsonListeners: setJsonListeners,
  };
  const [isModal, setIsModal] = useState(false);
  const message = useRef("");
  const onProgressImport = async (progress: string) => {
    if (isJsonListeners.jsonListeners) {
      if (!isModal) setIsModal(true);
      message.current = message.current.concat(`${progress}\n`);
    }
  };
  const onProgressExport = async (progress: string) => {
    if (isJsonListeners.jsonListeners) {
      if (!isModal) setIsModal(true);
      message.current = message.current.concat(`${progress}\n`);
    }
  };
  const {
    echo,
    getPlatform,
    createConnection,
    closeConnection,
    retrieveConnection,
    retrieveAllConnections,
    closeAllConnections,
    isConnection,
    addUpgradeStatement,
    importFromJson,
    isJsonValid,
    isDatabase,
    getDatabaseList,
    addSQLiteSuffix,
    deleteOldDatabases,
    copyFromAssets,
    checkConnectionsConsistency,
    isAvailable,
  } = useSQLite({
    onProgressImport,
    onProgressExport,
  });
  sqlite = {
    echo: echo,
    getPlatform: getPlatform,
    createConnection: createConnection,
    closeConnection: closeConnection,
    retrieveConnection: retrieveConnection,
    retrieveAllConnections: retrieveAllConnections,
    closeAllConnections: closeAllConnections,
    isConnection: isConnection,
    isDatabase: isDatabase,
    getDatabaseList: getDatabaseList,
    addSQLiteSuffix: addSQLiteSuffix,
    deleteOldDatabases: deleteOldDatabases,
    addUpgradeStatement: addUpgradeStatement,
    importFromJson: importFromJson,
    isJsonValid: isJsonValid,
    copyFromAssets: copyFromAssets,
    checkConnectionsConsistency: checkConnectionsConsistency,
    isAvailable: isAvailable,
  };
  useIonViewWillEnter(async () => {
    if (IsMobile())
    {
      await KeepAwake.keepAwake();
    }
  });
  return (
    <Provider store={store}>
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route path="/" exact={true} component={Landing} />
              <Route path="/login" exact={true} component={Login} />
              <Route path="/register" exact={true} component={Register} />
              <Route path="/bluetooth" exact={true} component={Bluetooth} />
              <Route path="/dashboard" exact={true} component={Dashboard} />
              <Route path="/dashboardOffline" exact={true} component={DashboardOffline} />

              {/* GADO ROUTES */}
              <Route path="/dashboardGado" exact={true} component={DashboardGado} />

              <Route path="/gadoPesquisar" exact={true} component={GadoPesquisar} />

              <Route path="/gadoInformaMorte" exact={true} component={GadoInformaMorte} />

              <Route path="/gadoManejoSanitario" exact={true} component={GadoManejoSanitario} />     

              <Route path="/gadoEntrada" exact={true} component={GadoLoteEntrada} />      
              <Route path="/gadoEntradaNovo" exact={true} component={NovoLoteEntrada} />
              <Route path="/preencherLoteEntrada/:id" exact={true} component={PreencherLoteEntrada} />
              


              <Route path="/gadoSaida" exact={true} component={GadoLoteSaida} />   
              <Route path="/gadoSaidaNovo" exact={true} component={NovoLoteSaida} />
              <Route path="/preencherLoteSaida/:id" exact={true} component={PreencherLoteSaida} />

              <Route path="/gadoConsumoConfinamento" exact={true} component={GadoConsumoConfinamento} />      
              <Route path="/novoRegistroConsumoConfinamento" exact={true} component={NovoRegistroConsumoConfinamento} />      
              
              


              {/* MANEJOS ROUTES */}
              <Route path="/dashboardManejo" exact={true} component={DashboardSincronizar} />
              <Route path="/manejosInformaMorte" exact={true} component={ManejosInformaMorte} />
              <Route path="/manejosSanitarios" exact={true} component={SincronizarManejoSanitario} />

            </IonRouterOutlet>
          </IonSplitPane>
          <ErrorMessage />
          <SuccessMessage />
          <AlertMessage />
          <Interceptors />
          <Loading />
        </IonReactRouter>
      </IonApp>
    </Provider>
  );
};

export default App;
