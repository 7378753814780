import { Network } from "@capacitor/network";
import { IonGrid, IonPage, IonRow, useIonViewDidEnter } from "@ionic/react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ReactComponent as TrackBovLogo } from "../../assets/images/logo.svg";
import { getStorageValue } from "../../services/StorageServices";
import { syncAllData } from "../../services/syncServices/checkSync";
import "./Landing.css";

const Landing: React.FC = (routing: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useIonViewDidEnter(async () => {
    await getStorageValue("AppAccessToken").then(async (response) => {
      if (response) {
        await Network.getStatus().then(async (network) => {
          if (network.connected) {
            dispatch({ type: "SET_LOADING", showLoading: true });
            await syncAllData()
              .catch((error) => {
                dispatch({
                  type: "SET_ERRORMESSAGE",
                  errorMessage: error.message,
                });
                dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
                dispatch({ type: "SET_LOADING", showLoading: false });
              });
          } else {
            dispatch({
              type: "SET_ERRORMESSAGE",
              errorMessage: "Não foi possível sincronizar os dados. Você não possui conexão com a internet. Tente Novamente",
            });
            dispatch({ type: "SET_SHOWERRORMESSAGE", showError: true });
          }
        });
        
        dispatch({ type: "SET_LOADING", showLoading: false });
        history.replace("/dashboard");
      } else {
        history.replace("/login");
      }
    });
  }, []);

  return (
    <IonPage className="page_bg_white">
      <IonGrid className="ion-align-items-center verticalCenter">
        <IonRow className="ion-justify-content-center logo-animate-flicker">
          <div className="landingLogo">
            <TrackBovLogo />
          </div>
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default Landing;
