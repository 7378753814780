import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../../App";
import { LoteEntradaAnimais } from "../../../models/gado/gadoLoteEntrada/gadoLoteEntrada";

export const CarregarAnimaisLoteEntrada = async (lote: number): Promise<LoteEntradaAnimais[]> => {
  let responseSelect: LoteEntradaAnimais[] = [];
  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }

  await db.open().then(async () => {
    await db.query(`SELECT * FROM LoteEntradaAnimais WHERE lote_id = '${lote}';`).then(async (response) => {
      responseSelect = response.values ? response.values : [];
    });
  });
  return responseSelect;
};
