
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  
  import { Bar } from "react-chartjs-2";
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  interface CardAnimalProps {
    dados: any;
    titulo?: string;
    legenda?: string;
    color?: string;
  }
  
  const VerticalBars: React.FC<CardAnimalProps> = ({ dados, titulo, legenda, color = "rgba(255, 99, 132, 0.5)" }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: titulo,
      },
    },
  };

  const labels = dados.labels;

  const data = {
    labels,
    datasets: [
      {
        label: legenda,
        data:  dados.data && dados.data.quantity ,
        backgroundColor: color,
      }
    ],
  };
  return (
    dados.lenght === 0 ? <div>Carregando...</div> : <Bar data={data} options={options} />
  );
};

export default VerticalBars;
