import { IonContent, IonList, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonIcon, IonSkeletonText, IonModal, IonText, useIonViewWillEnter } from "@ionic/react";
import { closeCircleSharp, informationCircleOutline } from "ionicons/icons";
import { useState } from "react";
import { LoteEntradaAnimais } from "../../models/gado/gadoLoteEntrada/gadoLoteEntrada";
import { LoteSaidaAnimais } from "../../models/gado/gadoLoteSaida/gadoLoteSaida";
import { getStorageValue } from "../../services/StorageServices";

interface CardAnimalProps {
  listEntrada: LoteEntradaAnimais[];
  remove?: boolean;
  removeHandle?: any;
  loading?: boolean;
}

const AnimaisLoteEntrada: React.FC<CardAnimalProps> = ({ listEntrada, remove, removeHandle, loading }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [animalModal, setAnimalModal] = useState<LoteEntradaAnimais>();

  const [confList, setConfList] = useState<any>([]);
  useIonViewWillEnter(async() => {
    await getStorageValue("ListaConfinamentos").then((response) => {
      const resp = response ? JSON.parse(response) : [];
      setConfList(resp)
    })
  },[])
  
  return (
    <>
      <IonContent>
        <IonList lines="full">
          {loading ? (
            <>
              <div className="ion-padding ">
                <IonSkeletonText animated style={{ width: "60%" }} />
                <IonSkeletonText animated />
                <IonSkeletonText animated style={{ width: "88%" }} />
                <IonSkeletonText animated style={{ width: "70%" }} />
                <IonSkeletonText animated style={{ width: "60%" }} />
              </div>
            </>
          ) : listEntrada.length === 0 ? (
            <IonItem>
              <IonLabel>Sem Animais Cadastrados</IonLabel>
            </IonItem>
          ) : (
            listEntrada
              .slice(0)
              .reverse()
              .map((item, index) => {
                return (
                  <IonItem key={index} className="ion-item-no-padding">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="1" className="text-small">
                          {listEntrada.length - index}
                        </IonCol>
                        <IonCol size="8">
                          <IonRow><b>{item.gco_brinco_bastao}</b></IonRow>

                          <IonRow>
                            <IonCol size="6">
                              {item.gco_marca}
                            </IonCol>
                            <IonCol size="6">
                            {item.gco_peso_balanca} Kg
                            </IonCol>
                          </IonRow>
                        </IonCol>
                        {remove && (
                          <>
                            <IonCol size="2" className="ion-text-center">
                              <IonIcon
                                className="icon-medium"
                                src={informationCircleOutline}
                                onClick={() => {
                                  setShowModal(true);
                                  setAnimalModal(item);
                                }}
                              />
                            </IonCol>
                            <IonCol size="1">
                              <IonIcon
                                color="danger"
                                className="icon-medium"
                                src={closeCircleSharp}
                                onClick={() => {
                                  removeHandle(item);
                                }}
                              />
                            </IonCol>
                          </>
                        )}
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                );
              })
          )}
        </IonList>
      </IonContent>

      <IonModal
        isOpen={showModal}
        cssClass="bluetooth-modal"
        onDidDismiss={() => {
          setShowModal(false);
        }}
      >
        <IonContent>
          <IonGrid className="modal_grid_95">
            <IonRow className="ion-justify-content-center ion-align-items-center card_animal_regular_text">
              <IonText className="ion-text-center">
                <p>Informações do Animal</p>
              </IonText>
            </IonRow>

            <IonGrid>
              <IonRow>{animalModal?.gco_tipo && "- " + animalModal.gco_tipo}</IonRow>
              <IonRow>{animalModal?.gco_raca && "- " + animalModal.gco_raca}</IonRow>
              <IonRow>{animalModal?.gco_con_id_fk && "- " + confList.filter((val) => val.value === animalModal?.gco_con_id_fk)[0].label}</IonRow>
            </IonGrid>
          </IonGrid>
        </IonContent>
      </IonModal>
    </>
  );
};

export default AnimaisLoteEntrada;
