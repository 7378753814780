import { IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import "./DashboardOffline.css";

const DashboardOffline: React.FC = (routing: any) => {
  const history = useHistory();

  return <IonPage>Dashboard OFFLINE</IonPage>;
};

export default DashboardOffline;
