import { IonLoading, IonToast } from "@ionic/react";
import React from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import './ErrorMessage.css'

const ErrorMessage = () => {
  const dispatch = useDispatch();

  const showError = useSelector((state: RootStateOrAny) => state.showError);
  const errorMessage = useSelector((state: RootStateOrAny) => state.errorMessage);

  return (
    <IonToast
      isOpen={showError}
      message={errorMessage}
      color="danger"
      duration={3000}
      animated={true}
      onDidDismiss={() =>
        dispatch({ type: "SET_SHOWERRORMESSAGE", showError: false })
      }
      buttons={[
        {
          text: '✖',
          role: 'cancel',
          cssClass: "error-toast"
        }
      ]}
    />
  );
};

export default ErrorMessage;
