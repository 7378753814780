import { capSQLiteSet, SQLiteDBConnection } from "@capacitor-community/sqlite";
import axios from "axios";
import moment from "moment";
import 'moment/locale/pt-br'
import { setStorageValue } from "../../StorageServices";
import { sqlite } from "../../../App";
import { ApiConnection } from "../../../settings/constants/apiConnection";

export async function syncAnimais(): Promise<any> {
  const data: Array<capSQLiteSet> = [];
  const dataSanitario: Array<capSQLiteSet> = [];
  
  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }
  await db.open().then(async () => {
    const uri = ApiConnection.baseUrl + ApiConnection.syncAnimais;
    await axios.get(uri).then(async (response) => {
      if (response.data.length > 0) {
        let sqlcmd: string = `DELETE FROM CadGadConfinamento;`;
        await db.execute(sqlcmd, false).then(async () => {
          sqlcmd = `DELETE FROM CadGadConfinamentoManejoSanitario;`;
          await db.execute(sqlcmd, false).then(async () => {
           // var todayDate = moment();
            const responseData = response.data.map(async (element, index) => {
              //, gco_data_ent, gcolote_vendedor, gco_peso_balanca, qto_dias_entrada   "2021-04-08"
              // const dateAux = element.gco_data_ent.split("-");
              // var entryDate = moment([dateAux[0], dateAux[1], dateAux[2]]);
              // var numberOfDays = todayDate.diff(entryDate, "days");
              data.push({
                statement:
                  "INSERT INTO CadGadConfinamento (gco_id,con_nome,faz_nome,gco_brinco_bastao,gco_marca, gco_data_ent, gcolote_vendedor, gco_peso_balanca, qtd_dias_entrada,gco_con_id_fk,gco_raca,gco_tipo,gco_tipo_id,macho) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?);",
                values: [
                  element.gco_id,
                  element.con_nome,
                  element.faz_nome,
                  element.gco_brinco_bastao,
                  element.gco_marca,
                  element.gco_data_ent,
                  element.gcolote_vendedor,
                  element.gco_peso_balanca,
                  0,
                  element.gco_con_id_fk,
                  element.gco_raca,
                  element.gco_tipo,
                  element.gco_tipo_id,
                  element.macho === "true" ? 1 : 0
                ],
              });
              if (element.prontuario) {
                element.prontuario.forEach((elementProntuario) => {
                  dataSanitario.push({
                    statement:
                      "INSERT INTO CadGadConfinamentoManejoSanitario (gco_id,pront_data_procedimento,pront_data_registro,pront_id,pront_texto,user_fullname,user_user) VALUES (?,?,?,?,?,?,?);",
                    values: [
                      element.gco_id,
                      elementProntuario.pront_data_procedimento,
                      elementProntuario.pront_data_registro,
                      elementProntuario.pront_id,
                      elementProntuario.pront_texto,
                      elementProntuario.user_fullname,
                      elementProntuario.user_user,
                    ],
                  });
                });
              }
            });

            return Promise.all(responseData).then(async () => {
              // Update Data on DataBASE
              await db.executeSet(data).then(async () => {
                if(dataSanitario.length > 0)
                {
                  await db.executeSet(dataSanitario);
                }
              }).then(async () => {
                await setStorageValue("lastAnimalSync", moment().locale("pt-br").format("lll"));
              });
            });
          }).then(async () => {
            db.close();
          });
        });
      }
    });
  });
}
