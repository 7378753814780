import { SQLiteDBConnection } from "react-sqlite-hook";
import { sqlite } from "../../App";
import { CadGadConfinamento } from "../../models/databaseModels";

export const SearchAnimalByTag = async (tagId: string): Promise<CadGadConfinamento[]> => {
  let responseSelect: CadGadConfinamento[] = [];

  let db: SQLiteDBConnection;
  if ((await sqlite.isConnection("trackBov")).result) {
    db = await sqlite.retrieveConnection("trackBov");
  } else {
    db = await sqlite.createConnection("trackBov", false, "no-encryption", 1);
  }
  await db.open().then(async () => {
    await db.query(`SELECT * FROM CadGadConfinamento WHERE gco_brinco_bastao = '${tagId}';`).then(async (response) => {
      responseSelect = response.values ? response.values : [];
      if (responseSelect.length === 0) {
        const criteria = tagId.length <= 6 ? tagId : Number(tagId.substr(tagId.length - 6)).toString();
        await db.query(`SELECT * FROM CadGadConfinamento WHERE gco_brinco_bastao like '%${criteria}';`).then((responseLike) => {
          responseSelect = responseLike.values ? responseLike.values : [];
        });
      }
    });
  });
  return responseSelect;
};
